import { FC, useEffect, useRef, useState } from "react"

import { useAppSelector } from "../../redux/hooks";
import {
    useConfirmResetPasswordMutation,
    useResetPasswordMutation
} from "../../api/appApi";

import BackHeader from "./BackHeader"
import InputCode from "./InputCode";

type EnterCodeProps = {
    setStep: (step: number) => void;
    email: string;
}

const EnterCode: FC<EnterCodeProps> = ({ setStep, email }) => {
    const [startTime, setStartTime] = useState<number>(new Date().getTime())
    const [isError, setIsError] = useState<boolean>(false)
    const [minutesLeft, setMinutesLeft] = useState<number>(10)
    const [secondsLeft, setSecondsLeft] = useState<number>(0)
    const theme = useAppSelector((state) => state.app.theme)

    const codeBlock = useRef<HTMLDivElement>(null)

    const [confirmCode] = useConfirmResetPasswordMutation()
    const [resetPassword] = useResetPasswordMutation()

    const handleResendCode = () => {
        resetPassword({ email }).then((res) => {
            if ("error" in res) {
                setIsError(true)
            } else {
                setIsError(false)
                setMinutesLeft(10)
                setSecondsLeft(0)
                setStartTime(new Date().getTime())
            }
        })
    }

    const handleConfirmCode = (code: string) => {
        confirmCode(code).then((res) => {
            if ("error" in res) {
                setIsError(true)
            } else {
                setIsError(false)
                setStep(3)
            }
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime()
            const elapsedTime = (currentTime - startTime) / 1000

            if (elapsedTime >= 600) {
                setMinutesLeft(0)
                setSecondsLeft(0)
                clearInterval(interval)
            } else {
                const timeLeft = 600 - elapsedTime
                setMinutesLeft(Math.floor(timeLeft / 60))
                setSecondsLeft(Math.floor(timeLeft % 60))
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [startTime, setMinutesLeft, setSecondsLeft])

    useEffect(() => {
        if (codeBlock.current) {
            const input = codeBlock.current.children[0] as HTMLInputElement

            if (input) {
                input.focus()
            }
        }
    }, [codeBlock])

    return (
        <div className="max-w-[640px] w-full p-6 flex flex-col gap-6 bg-background rounded-[24px] border-solid border-[1px] border-inputBorder shadow-modal min-h-[404px] justify-between mobile:h-full mobile:rounded-none mobile:p-4">
            <div className="flex flex-col gap-6 w-full">
                <BackHeader title="Enter Code" />
                <div className="h-[1px] w-full bg-inputBorder mobile:hidden"></div>
            </div>
            <div className="flex flex-col gap-4 w-full h-full">
                <InputCode
                    length={4}
                    isError={isError}
                    setIsError={setIsError}
                    onComplete={handleConfirmCode}
                />
                <p className="text-[14px] leading-[17px] font-semibold text-text w-full text-center">
                    {minutesLeft}m {secondsLeft}s left
                </p>
                <button onClick={handleResendCode} className="text-[12px] leading-[18px] font-semibold text-button hover:text-buttonHover transition-colors">
                    Resend Code
                </button>
            </div>
            <div className="w-full rounded-xl border-solid border-[1px] border-inputBorder flex flex-col gap-2 p-3">
                <div className="text-[14px] leading-[17px] font-semibold text-text flex items-center gap-2">
                    Code sent to:
                    <span className={`h-[24px] rounded-lg px-2 text-[12px] leading-[18px] flex items-center justify-center w-fit text-button ${theme === 'light' ? 'bg-[#E5F2FF]' : 'bg-[#162330]'}`}>
                        {email}
                    </span>
                </div>
                <p className="text-[12px] leading-[18px] text-text">
                    If you don’t see the code, check your junk folder.
                </p>
            </div>
        </div>
    )
}

export default EnterCode