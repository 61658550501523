import { useNavigate } from "react-router-dom"
import { FC } from "react"

import { useAppSelector } from "../../redux/hooks"

import Back from '../../assets/back.svg'

type BackHeaderProps = {
    title: string
}

const BackHeader: FC<BackHeaderProps> = ({ title }) => {
    const theme = useAppSelector((state) => state.app.theme)

    const navigate = useNavigate()

    const handleNavigateToLogin = () => {
        navigate('/sign-in')
    }

    return (
        <div className="flex items-center justify-center relative mobile:flex-col mobile:gap-[31px]">
            <img
                className={`absolute left-0 top-[50%] translate-y-[-50%] w-[28px] cursor-pointer text-text mobile:hidden ${theme === 'dark' ? 'icon-to-white' : ''}`}
                onClick={handleNavigateToLogin}
                src={Back}
                alt=""
            />
            <div className="mobile:flex w-full justify-start hidden">
                <div onClick={() => navigate('/')} className="w-[44px] h-[44px] rounded-[10px] border-solid border-[1px] border-inputBorder flex items-center justify-center">
                    <img className={`${theme === 'light' ? '' : 'icon-to-white'}`} src={Back} alt="" />
                </div>
            </div>
            <h5 className="font-[Sora] text-[23px] leading-[28px] font-bold tracking-[-0.02em] text-text">
                {title}
            </h5>
        </div>
    )
}

export default BackHeader