import { useParams, useSearchParams } from "react-router-dom"
import { useEffect } from "react"

import {
    useGetImagesForGenerationQuery,
    useStartAdditionalGenerationMutation
} from "../api/appApi"
import { useAlbumContext } from "../context/AlbumContext"
import { useAppSelector } from "../redux/hooks"

import GeneratingNewImages from "../components/Album/GeneratingNewImages"
import PhotoOptionsMobile from "../components/Album/PhotoOptionsMobile"
import PhotosUploaded from "../components/Album/PhotosUploaded"
import GenerateMore from "../components/Album/GenerateMore"
import AlbumPreviewModal from "../modals/AlbumPreviewModal"
import SwitchMobile from "../components/Album/SwitchMobile"
import EmptyFavorites from "../components/EmptyFavorites"
import AlbumImage from "../components/Album/AlbumImage"
import useViewport from "../hooks/useViewport"
import Header from "../components/Header"

import HeartBlackFilled from '../assets/heart-black-filled.svg'
import DownloadWhite from '../assets/download-white.svg'
import DownloadBlack from '../assets/download-black.svg'
import Heart from '../assets/heart.svg'
import Dots from '../assets/dots.svg'

const Album = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const theme = useAppSelector(state => state.app.theme)

    const { id } = useParams()
    const { isMobile } = useViewport()

    const albumCtx = useAlbumContext()

    const { data: imagesForGeneration } = useGetImagesForGenerationQuery(id ? id : '', { skip: !id })
    const [startAdditionalGeneration] = useStartAdditionalGenerationMutation()

    useEffect(() => {
        if (searchParams.get('startGeneration') && id) {
            startAdditionalGeneration(id)
            setSearchParams({})
        }
    }, [searchParams, setSearchParams, startAdditionalGeneration, id])

    useEffect(() => {
        if (!albumCtx?.showAll) {
            albumCtx?.setShowFavorite(false)
        } else {
            if (albumCtx?.album.length === albumCtx?.selectedImages.length && albumCtx?.album.some((el) => el.favorite === false)) {
                albumCtx?.setShowFavorite(true)
            } else if (albumCtx?.album.length === albumCtx?.selectedImages.length && albumCtx?.album.every((el) => el.favorite === true)) {
                albumCtx?.setShowFavorite(false)
            } else if (albumCtx?.selectedImages.every((id) => albumCtx?.favorites.some((image) => image.id === id)) && albumCtx?.selectedImages.length > 0) {
                albumCtx?.setShowFavorite(false)
            } else {
                albumCtx?.setShowFavorite(true)
            }
        }
    }, [albumCtx])

    useEffect(() => {
        if (id) {
            albumCtx?.setId(id)
        }
    }, [id, albumCtx])

    return (
        <div className="flex flex-col w-full min-h-screen">
            <Header type={"album"} />
            <div className="dashboard-background-image min-h-[calc(100vh-68px)] w-full grid grid-cols-[1fr,360px] mobile:flex mobile:flex-col">
                <SwitchMobile />
                <div className={`flex flex-col gap-6 p-12 mobile:p-4 mobile:gap-4 ${albumCtx?.additionalGenerationStatus === 'pending' ? "mobile:h-[calc(100vh-112px)]" : albumCtx?.additionalGenerationStatus === 'completed' ? "mobile:h-[calc(100vh-112px)]" : "mobile:h-[calc(100vh-212px)]"}`}>
                    <div className="flex items-center justify-between gap-4">
                        <div className="flex items-center gap-6 mobile:hidden">
                            {albumCtx?.selectedImages && albumCtx.selectedImages.length > 0 ? (
                                <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text">
                                    {albumCtx?.selectedImages.length} Photo Selected
                                </h5>
                            ) : (
                                <>
                                    <button onClick={() => albumCtx?.setShowAll(true)} className={`font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold transition-colors ${albumCtx?.showAll ? 'text-text' : 'text-placeholder hover:text-[#666666]'}`}>
                                        All Photos
                                    </button>
                                    <button onClick={() => albumCtx?.setShowAll(false)} className={`font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold flex items-center gap-2 transition-colors ${!albumCtx?.showAll ? 'text-text' : 'text-placeholder hover:text-[#666666]'}`}>
                                        Favorites
                                        {albumCtx?.favorites.length ? (
                                            <div className="h-[24px] px-[10px] bg-redButton rounded-[8px] font-[Inter] text-[12px] leading-[18px] text-white flex items-center justify-center">
                                                {albumCtx?.favorites.length}
                                            </div>
                                        ) : ''}
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="hidden mobile:flex font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] font-bold text-text">
                            {albumCtx?.showAll ? 'All Photos' : 'Favorites'}
                        </div>
                        <div className="flex items-center gap-2">
                            <button onClick={albumCtx?.handleSelectAll} className={`text-text h-[44px] px-4 rounded-lg font-[Inter] text-[14px] leading-[17px] font-semibold transition-colors mobile:h-[32px] ${theme === 'dark' ? 'bg-inputBackground hover:bg-inputBorder' : 'bg-inputBackground hover:bg-inputBorder'}`}>
                                {(albumCtx?.showAll && albumCtx?.selectedImages.length === albumCtx?.album.length) || (!albumCtx?.showAll && albumCtx?.favorites.length === albumCtx?.selectedImages.length) ? 'Unselect All' : 'Select All'}
                            </button>
                            <button onClick={() => albumCtx?.setOpenPhotoOptions(true)} className="hidden mobile:flex items-center justify-center h-[32px] w-[32px] rounded-lg bg-background hover:bg-inputBackground transition-colors">
                                <img className={theme === 'light' ? '' : 'icon-to-white'} src={Dots} alt="" />
                            </button>
                            {albumCtx?.selectedImages && albumCtx.selectedImages.length > 0 && !isMobile && (
                                <>
                                    {albumCtx?.showFavorite ? (
                                        <button onClick={albumCtx?.handleFavoriteSelected} className="bg-inputBackground text-text h-[44px] px-4 rounded-lg font-[Inter] text-[14px] leading-[17px] font-semibold flex items-center gap-2 hover:bg-inputBorder transition-colors">
                                            <img className={`${theme === 'light' ? '' : 'icon-to-white'}`} src={Heart} alt="" />
                                            Favorite Selected
                                        </button>
                                    ) : (
                                        <button onClick={albumCtx?.handleUnfavoriteSelected} className="bg-redButton text-background h-[44px] px-4 rounded-lg font-[Inter] text-[14px] leading-[17px] font-semibold flex items-center gap-2 hover:bg-redButtonHover transition-colors">
                                            {theme === 'dark' ? (
                                                <img src={HeartBlackFilled} alt="" />
                                            ) : (
                                                <img className={`icon-to-white`} src={Heart} alt="" />
                                            )}
                                            Unfavorite Selected
                                        </button>
                                    )}
                                </>
                            )}
                            {!isMobile && (
                                <button onClick={albumCtx?.handleDownloadSelected} className="h-[44px] bg-text text-background rounded-lg px-4 flex items-center gap-2 text-[14px] leading-[17px] font-semibold hover:bg-secondaryText transition-colors">
                                    <img src={theme === 'light' ? DownloadWhite : DownloadBlack} alt="" />
                                    Download {albumCtx?.selectedImages && albumCtx?.selectedImages.length > 0 ? 'Selected' : 'All'}
                                </button>
                            )}
                        </div>
                    </div>
                    {!albumCtx?.showAll && albumCtx?.favorites.length === 0 ? (
                        <EmptyFavorites />
                    ) : (
                        <div className="grid grid-cols-[repeat(auto-fill,minmax(180px,1fr))] gap-[10px] max-h-[calc(100vh-232px)] overflow-y-auto custom-scroll mobile:grid-cols-[repeat(auto-fill,minmax(105px,1fr))] mobile:overflow-x-hidden mobile:pe-1">
                            {albumCtx?.showAll ? (
                                <>
                                    {albumCtx?.album.map((image) => (
                                        <AlbumImage
                                            key={image.id}
                                            image={image}
                                        />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {albumCtx?.favorites.map((image) => (
                                        <AlbumImage
                                            key={image.id}
                                            image={image}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex flex-col gap-4 h-[calc(100vh-68px)] overflow-y-auto custom-scroll overflow-x-hidden pt-12 pb-10 mobile:pb-0 mobile:h-auto mobile:gap-0 mobile:pt-0">
                    {id && albumCtx?.additionalGenerationStatus === 'do_not_exist' && (
                        <GenerateMore id={id} />
                    )}
                    {imagesForGeneration && imagesForGeneration.length > 0 && (
                        <PhotosUploaded imagesForGeneration={imagesForGeneration} />
                    )}
                    {albumCtx?.additionalGenerationStatus === 'pending' && (
                        <GeneratingNewImages />
                    )}
                </div>
            </div>
            {
                albumCtx?.previewImage && (
                    <AlbumPreviewModal
                        onClose={() => albumCtx?.setPreviewImage(null)}
                        album={albumCtx?.showAll ? albumCtx?.album : albumCtx?.favorites}
                        previewImage={albumCtx?.previewImage}
                    />
                )
            }
            {
                albumCtx?.openPhotoOptions && (
                    <PhotoOptionsMobile />
                )
            }
        </div >
    )
}

export default Album