import { FC } from "react"

import { calculatePercentageProgress, convertDateToLocaleString } from "../../utils/functions"
import { GenerationItem } from "../../utils/types"

import AlbumItemStatus from "./AlbumItemStatus"

type AlbumItemMobileStatusProps = {
    album: GenerationItem
}

const AlbumItemMobileStatus: FC<AlbumItemMobileStatusProps> = ({ album }) => {
    return (
        <div className="flex w-full items-center justify-between gap-2">
            {(album.completed && album.additional_generation !== 'pending') || album.is_draft ? (
                <span className="text-secondaryText text-[18px] leading-[22px] mobile:text-[12px] mobile:leading-[18px]">
                    {album.is_draft ? 'Draft started' : ''} {convertDateToLocaleString(album.created_at)}
                </span>
            ) : (
                <div className="bg-inputBackground rounded-full h-[8px] w-[300px] mobile:w-full overflow-hidden">
                    <div style={{ width: `${calculatePercentageProgress(album.started_at, album.eta)}%` }} className="bg-button h-full"></div>
                </div>
            )}
            <AlbumItemStatus album={album} />
        </div>
    )
}

export default AlbumItemMobileStatus