import { FC } from "react"

import { useDeleteImagesForGenerationMutation } from "../api/appApi"
import { useCreationContext } from "../context/CreationContext"
import { useAppSelector } from "../redux/hooks"

import Modal from "./Modal"

import TrashBlack from "../assets/creation/trash-black.svg"
import TrashGrey from "../assets/creation/trash-grey.svg"
import Trash from "../assets/creation/trash.svg"

type RemoveUploadedImageModalProps = {
    onClose: () => void
}

const RemoveUploadedImageModal: FC<RemoveUploadedImageModalProps> = ({ onClose }) => {
    const theme = useAppSelector(state => state.app.theme)

    const ctx = useCreationContext()

    const [deleteImages] = useDeleteImagesForGenerationMutation()

    const handleDeleteSelected = () => {
        if (!ctx?.id || !ctx?.selectedUploadedImages || !ctx?.selectedUploadedImages.length) return

        if (ctx?.selectedUploadedImages.length === ctx?.uploadedImages.length) {
            deleteImages({ id: ctx?.id, data: { ids: [], select_all: true } })
        } else {
            const idList: string[] = []

            ctx?.uploadedImages.forEach((image, index) => {
                if (ctx.selectedUploadedImages.includes(index)) {
                    if ('id' in image) {
                        idList.push(image.id)
                    }
                }
            })

            deleteImages({ id: ctx.id, data: { ids: idList, select_all: false } })
        }

        ctx?.setSelectedUploadedImages([])
        onClose()
    }

    return (
        <Modal
            onClose={onClose}
            bodyStyles={{ backgroundColor: 'var(--background-color)', maxWidth: '480px' }}
        >
            <div className="flex flex-col gap-6">
                <div className="flex flex-col items-center gap-2">
                    <img width={44} src={TrashGrey} alt="" />
                    <h5 className="font-[Sora] text-text text-[23px] leading-[28px] tracking-[-0.02em] text-center font-bold mobile:text-[19px] mobile:leading-[23px]">
                        Are you sure you want to remove {ctx?.selectedUploadedImages.length} {ctx?.selectedUploadedImages.length === 1 ? 'image' : 'images'}?
                    </h5>
                </div>
                <div className="flex items-center justify-between gap-2">
                    <button
                        onClick={onClose}
                        className="text-text w-1/2 h-[44px] px-4 rounded-lg border-solid border-[1px] border-inputBorder font-semibold text-base"
                    >
                        Keep
                    </button>
                    <button
                        onClick={handleDeleteSelected}
                        className="bg-redButton h-[44px] px-4 rounded-lg text-background flex items-center justify-center gap-2 w-1/2 font-semibold text-base"
                    >
                        <img src={theme === 'light' ? Trash : TrashBlack} alt="" />
                        Remove
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default RemoveUploadedImageModal