import { useParams } from "react-router-dom"

import { useCreationContext } from "../../context/CreationContext"
import { EYES_COLOR_OPTIONS } from "../../utils/constants"
import { useAppSelector } from "../../redux/hooks"

import CreationForward from '../../assets/creation/forward.svg'
import CreationBack from '../../assets/creation/back.svg'

const EyeColorStep = () => {
    const theme = useAppSelector(state => state.app.theme)

    const ctx = useCreationContext()

    const { id } = useParams()

    const handleSelectEyesColor = (eye_color: string) => {
        ctx?.setState({ ...ctx.state, eye_color })
        ctx?.setStep(4)

        if (!id) return

        ctx?.syncData({
            generation_id: id,
            field: 'eye_color',
            value: eye_color
        })
    }

    return (
        <div className="flex flex-col items-start gap-6 max-w-[480px] h-full w-full mobile:gap-4">
            <div className="flex items-center justify-center w-full relative">
                <div onClick={() => ctx?.setStep(2)} className="w-[36px] h-[36px] rounded-lg bg-inputBackground absolute left-0 flex items-center justify-center hover:bg-inputBorder transition-colors cursor-pointer">
                    <img className={`${theme === 'dark' ? 'icon-to-white' : ''}`} src={CreationBack} alt="" />
                </div>
                <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] text-text font-bold mobile:text-[19px] mobile:leading-[23px]">
                    What’s your eye color?
                </h5>
                {ctx?.state?.eye_color && (
                    <div onClick={() => ctx?.setStep(4)} className="w-[36px] h-[36px] rounded-lg bg-inputBackground absolute right-0 flex items-center justify-center hover:bg-inputBorder transition-colors cursor-pointer">
                        <img className={`${theme === 'dark' ? 'icon-to-white' : ''}`} src={CreationForward} alt="" />
                    </div>
                )}
            </div>
            <div className="flex flex-col rounded-lg h-fit border-solid border-[1px] border-inputBorder w-full">
                {EYES_COLOR_OPTIONS.map((eye_color, index) => (
                    <div
                        key={index}
                        onClick={() => handleSelectEyesColor(eye_color)}
                        className={`w-full p-4 border-solid border-b-[1px] border-inputBorder flex items-center justify-center gap-4 font-semibold text-text text-[18px] leading-[22px] cursor-pointer last:border-none first:rounded-t-lg last:rounded-b-lg mobile:p-3 mobile:text-base ${ctx?.state.eye_color === eye_color ? 'bg-inputTransparentBackground outline outline-2 outline-button outline-offset-[-2px] hover:bg-[#0085FF33]' : 'hover:bg-inputBackground'}`}
                    >
                        {eye_color}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EyeColorStep