import { useParams } from "react-router-dom"
import { useState } from "react"

import { IMAGE_REQUIREMENTS_BAD, IMAGE_REQUIREMENTS_GOOD } from "../../utils/constants"
import { useCreationContext } from "../../context/CreationContext"
import { useAppSelector } from "../../redux/hooks"
import useViewport from "../../hooks/useViewport"

import CreationRequirementsGreenCheck from "../../assets/creation/requirements/green-check.svg"
import CreationRequirementsRedClose from "../../assets/creation/requirements/red-close.svg"
import CreationRequirementsCheck from "../../assets/creation/requirements/check.svg"
import ArrowTop from "../../assets/arrow-top.svg"

const ImageRequirements = () => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const theme = useAppSelector((state) => state.app.theme)

    const { id } = useParams()
    const { isMobile } = useViewport()

    const ctx = useCreationContext()

    const handleAcceptRequirements = (accepted: boolean) => {
        ctx?.setRequirementsAccepted(accepted)

        if (!id) return

        ctx?.syncData({
            generation_id: id,
            field: 'requirements_accepted',
            value: accepted
        })
    }

    const handleExpandRequirements = () => {
        if (isMobile) {
            setExpanded(!expanded)
        }
    }

    return (
        <div className={`border-solid border-s-[1px] border-inputBorder relative flex flex-col h-[calc(100vh-158px)] mobile:sticky mobile:bottom-0 mobile:h-fit mobile:max-h-[calc(100vh-72px)] mobile:z-10 mobile:w-full mobile:border-0 mobile:pb-0 ${expanded ? 'mobile:pt-0' : ''}`}>
            <div className={`flex flex-col gap-6 overflow-y-auto h-[calc(100%-72px)] custom-scroll p-6 mobile:p-0 mobile:gap-4 bg-background upload-top-box-shadow mobile:pt-4 mobile:border-t-[1px] mobile:border-solid mobile:border-inputBorder mobile:rounded-t-xl`}>
                <h5 onClick={handleExpandRequirements} className={`font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] text-text font-semibold flex items-center justify-between mobile:text-base mobile:px-4 ${!expanded && isMobile ? 'pb-4' : ''}`}>
                    Photo Requirements
                    <img
                        className={`hidden mobile:block ${expanded ? 'rotate-180' : ''} ${theme === 'dark' ? 'icon-to-white' : ''}`}
                        src={ArrowTop}
                        alt=""
                    />
                </h5>
                {((isMobile && expanded) || !isMobile) && (
                    <div className="flex flex-col gap-6 mobile:overflow-y-auto mobile:max-h-[60vh] mobile:h-full">
                        <span className="w-full min-h-[1px] h-[1px] bg-inputBorder"></span>
                        <div className="flex flex-col gap-3 w-full mobile:px-4">
                            {IMAGE_REQUIREMENTS_GOOD.map((requirement) => (
                                <div key={requirement.id} className="flex w-full gap-3">
                                    <img
                                        className="w-[64px] h-[64px] mobile:w-[56px] mobile:h-[56px]"
                                        src={requirement.image}
                                        alt=""
                                    />
                                    <div className="flex flex-col gap-[2px] w-full h-full justify-center">
                                        <p className="text-text text-base font-semibold">{requirement.title}</p>
                                        <span className="text-[14px] leading-[17px] text-secondaryText">{requirement.description}</span>
                                    </div>
                                    <img className="min-w-[24px] w-[24px]" src={CreationRequirementsGreenCheck} alt="" />
                                </div>
                            ))}
                        </div>
                        <span className="w-full min-h-[1px] h-[1px] bg-inputBorder"></span>
                        <div className="flex flex-col gap-3 w-full mobile:px-4 mobile:pb-4">
                            {IMAGE_REQUIREMENTS_BAD.map((requirement) => (
                                <div key={requirement.id} className="flex w-full gap-3">
                                    <img
                                        className="w-[64px] h-[64px] mobile:w-[56px] mobile:h-[56px]"
                                        src={requirement.image}
                                        alt=""
                                    />
                                    <div className="flex flex-col gap-[2px] w-full h-full justify-center">
                                        <p className="text-text text-base font-semibold">{requirement.title}</p>
                                        <span className="text-[14px] leading-[17px] text-secondaryText">{requirement.description}</span>
                                    </div>
                                    <img className="min-w-[24px] w-[24px]" src={CreationRequirementsRedClose} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div
                onClick={() => handleAcceptRequirements(!ctx?.requirementsAccepted)}
                className={`px-4 py-3 z-[6] w-full absolute bottom-0 left-0 flex items-center justify-between gap-2 h-[72px] transition-colors cursor-pointer mobile:static ${ctx?.requirementsAccepted ? 'bg-inputTransparentBackground hover:bg-[#0085FF33] mobile:bg-inputTransparentBackgroundNoOpacity' : 'bg-button hover:bg-[#0071D9]'}`}
            >
                <span className={`text-base ${theme === 'dark' ? 'text-white' : ctx?.requirementsAccepted ? 'text-text' : 'text-background'}`}>
                    I acknowledge and and accept all image requirements.
                </span>
                {ctx?.requirementsAccepted ? (
                    <img
                        src={CreationRequirementsCheck}
                        className="cursor-pointer select-none"
                        alt=""
                    />
                ) : (
                    <div
                        className="min-w-[24px] w-[24px] h-[24px] rounded-[4px] border-solid border-[1px] border-white cursor-pointer"
                    ></div>
                )}
            </div>
        </div >
    )
}

export default ImageRequirements