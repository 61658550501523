import { createApi } from "@reduxjs/toolkit/query/react";

import {
    Coupon,
    FavoriteImagesPayload,
    GenerationDeleteImagesPayload,
    GenerationItem,
    GenerationUploadedImageItem,
    GetGenerationResultsBody,
    Invoice,
    InvoiceCreationResponse,
    PayPalPaymentPayload,
    Subscription,
    UpdateGenerationPayload,
    UpdateUserPayload,
    UserSubscriptionsInfoQueryParams
} from "../utils/types";
import { TContactUsData } from "../components/Landing/Footer";
import { baseQuery } from "./config";

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: baseQuery,
    tagTypes: ['Generations', 'Subscription', 'GenerationResults', 'GenerationUploadedImages', 'CurrentGeneration'],
    endpoints: builder => ({
        getUserSubscription: builder.query<Subscription, UserSubscriptionsInfoQueryParams>({
            query: (data) => {
                return {
                    url: `users/me?traffic_source=${data.traffic_source}&traffic_device=${data.traffic_device}&click_id=${data.click_id}`,
                    method: 'GET'
                }
            },
            providesTags: ['Subscription']
        }),
        createGeneration: builder.mutation<GenerationItem, void>({
            query: () => {
                return {
                    url: 'generations/create-generation',
                    method: 'POST',
                    body: {}
                }
            },
            invalidatesTags: ['Generations', 'Subscription']
        }),
        getGenerations: builder.query<GenerationItem[], void>({
            query: () => {
                return {
                    url: 'generations/get-generations',
                    method: 'GET'
                }
            },
            providesTags: ['Generations']
        }),
        validateImage: builder.mutation<GenerationUploadedImageItem[], { formData: FormData, id: string }>({
            query: ({ formData, id }) => {
                return {
                    url: `generations/validate-image/${id}`,
                    method: 'POST',
                    body: formData
                }
            },
            invalidatesTags: ['GenerationUploadedImages']
        }),
        getGenerationResults: builder.query<GetGenerationResultsBody, string>({
            query: (id) => {
                return {
                    url: `generations/get-result-images/${id}`,
                    method: 'GET'
                }
            },
            providesTags: ['GenerationResults']
        }),
        createInvoice: builder.mutation<InvoiceCreationResponse, { price_id: string, coupon_code?: string }>({
            query: ({ price_id, coupon_code }) => {
                return {
                    url: 'payments/stripe/create-invoice',
                    method: 'POST',
                    body: { price_id, coupon_code }
                }
            }
        }),
        updateGeneration: builder.mutation<void, UpdateGenerationPayload>({
            query: (data) => {
                return {
                    url: `generations/update-generation/${data.id}`,
                    method: 'PATCH',
                    body: data.data
                }
            },
            invalidatesTags: ['Generations', 'GenerationResults', 'CurrentGeneration']
        }),
        getGenerationId: builder.query<GenerationItem, string>({
            query: (id) => {
                return {
                    url: `generations/get-generations/${id}`,
                    method: 'GET'
                }
            },
            providesTags: ['CurrentGeneration']
        }),
        getImagesForGeneration: builder.query<GenerationUploadedImageItem[], string>({
            query: (id) => {
                return {
                    url: `generations/images-for-generation/${id}`,
                    method: 'GET'
                }
            },
            providesTags: ['GenerationUploadedImages']
        }),
        deleteImagesForGeneration: builder.mutation<void, { data: GenerationDeleteImagesPayload, id: string }>({
            query: ({ data, id }) => {
                return {
                    url: `generations/images-for-generation/${id}`,
                    method: 'DELETE',
                    body: data
                }
            },
            invalidatesTags: ['GenerationUploadedImages']
        }),
        startGeneration: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url: `generations/start-generation/${id}`,
                    method: 'POST',
                    body: {}
                }
            },
            invalidatesTags: ['Generations', 'Subscription']
        }),
        favoriteImages: builder.mutation<void, { data: FavoriteImagesPayload, id: string }>({
            query: ({ data, id }) => {
                return {
                    url: `generations/favorite-images/${id}`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['GenerationResults']
        }),
        updateUsers: builder.mutation<void, UpdateUserPayload>({
            query: (data) => {
                return {
                    url: 'users/update-user',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Subscription']
        }),
        updateUserImage: builder.mutation<void, FormData | Object>({
            query: (data) => {
                return {
                    url: 'users/update-image',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Subscription']
        }),
        updateUserEmail: builder.mutation<void, string>({
            query: (email) => {
                return {
                    url: 'users/update-email',
                    method: 'POST',
                    body: { email }
                }
            }
        }),
        updateUserPassword: builder.mutation<void, string>({
            query: (password) => {
                return {
                    url: 'users/update-password',
                    method: 'POST',
                    body: { password }
                }
            },
        }),
        deleteUser: builder.mutation<void, void>({
            query: () => {
                return {
                    url: 'users/delete-user',
                    method: 'DELETE'
                }
            }
        }),
        getInvoices: builder.query<Invoice[], void>({
            query: () => {
                return {
                    url: 'payments/invoices',
                    method: 'GET'
                }
            }
        }),
        savePayPalPayment: builder.mutation<void, PayPalPaymentPayload>({
            query: (data) => {
                return {
                    url: 'payments/paypal/create_payment',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Subscription']
        }),
        getPendingGeneration: builder.query<GenerationItem, void>({
            query: () => {
                return {
                    url: 'users/get-pending-generation',
                    method: 'GET'
                }
            }
        }),
        startAdditionalGeneration: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url: `generations/generate-additional/${id}`,
                    method: 'POST',
                    body: {}
                }
            },
            invalidatesTags: ['Generations', 'Subscription', 'GenerationResults']
        }),
        resetPassword: builder.mutation<void, { email: string }>({
            query: ({ email }) => {
                return {
                    url: 'users/forgot-password',
                    method: 'POST',
                    body: { email }
                }
            }
        }),
        confirmResetPassword: builder.mutation<void, string>({
            query: (token) => {
                return {
                    url: 'users/confirm-forgot-password',
                    method: 'POST',
                    body: { token }
                }
            }
        }),
        setNewPassword: builder.mutation<void, { password: string, email: string }>({
            query: ({ password, email }) => {
                return {
                    url: 'users/reset-password',
                    method: 'POST',
                    body: { password, email }
                }
            }
        }),
        sendContactUsEmail: builder.mutation<void, TContactUsData>({
            query: (data) => {
                return {
                    url: 'users/contact-us',
                    method: 'POST',
                    body: data
                }
            }
        }),
        saveStripePayment: builder.mutation<void, string>({
            query: (payment_intent_id) => {
                return {
                    url: 'payments/stripe/create-payment',
                    method: 'POST',
                    body: { payment_intent_id }
                }
            },
            invalidatesTags: ['Subscription']
        }),
        checkCouponStatus: builder.mutation<{ coupon: Coupon | null }, string>({
            query: (coupon) => {
                return {
                    url: `payments/stripe/check-coupon-status?coupon_id=${coupon}`,
                    method: 'GET'
                }
            }
        })
    })
})

export const {
    useGetUserSubscriptionQuery,
    useCreateGenerationMutation,
    useGetGenerationsQuery,
    useValidateImageMutation,
    useGetGenerationResultsQuery,
    useCreateInvoiceMutation,
    useUpdateGenerationMutation,
    useGetGenerationIdQuery,
    useGetImagesForGenerationQuery,
    useDeleteImagesForGenerationMutation,
    useStartGenerationMutation,
    useFavoriteImagesMutation,
    useUpdateUsersMutation,
    useUpdateUserImageMutation,
    useUpdateUserEmailMutation,
    useUpdateUserPasswordMutation,
    useDeleteUserMutation,
    useGetInvoicesQuery,
    useSavePayPalPaymentMutation,
    useGetPendingGenerationQuery,
    useStartAdditionalGenerationMutation,
    useResetPasswordMutation,
    useConfirmResetPasswordMutation,
    useSetNewPasswordMutation,
    useSendContactUsEmailMutation,
    useSaveStripePaymentMutation,
    useCheckCouponStatusMutation
} = appApi