import { useNavigate, useSearchParams } from "react-router-dom"
import { FC } from "react"

import { Plan } from "../../utils/types"

type PlanItemProps = {
    plan: Plan
}

const PlanItem: FC<PlanItemProps> = ({ plan }) => {
    const [searchParams] = useSearchParams()

    const navigate = useNavigate()

    const selectPlan = () => {
        navigate(`/checkout/${plan.stripe_price}/${plan.name}${searchParams.get('continue_generation') ? `?continue_generation=${searchParams.get('continue_generation')}` : ''}`)
    }

    return (
        <div className="flex items-center border-solid justify-between border-b-[1px] border-inputBorder last:border-b-0 p-6 gap-6 mobile:flex-col mobile:p-4 mobile:gap-4 mobile:justify-start">
            <div className="flex items-end gap-4 max-w-[561px] w-max mobile:w-full">
                <div className="flex flex-col gap-1">
                    <h5 className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] text-text font-bold mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold flex items-center gap-2 mobile:justify-between">
                        {plan.name}
                        {plan.tag === 'Most Popular' ? (
                            <div className="h-[24px] rounded-full bg-lightBlueBackground px-2 flex items-center justify-center text-[12px] leading-[18px] text-button font-semibold">
                                Most Popular
                            </div>
                        ) : plan.tag === 'Best Value' ? (
                            <div className="landing-page-best-price-value-tag h-[24px] rounded-full px-2 flex items-center justify-center text-[12px] leading-[18px] font-semibold text-white">
                                Best Value
                            </div>
                        ) : null}
                    </h5>
                    <p className="p-0 text-[18px] leading-[22px] text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                        {plan.description}
                    </p>
                </div>
            </div>
            <div className="h-[86px] w-[1px] bg-inputBorder mobile:hidden"></div>
            <div className="flex items-center gap-6 mobile:w-full">
                <div className="flex items-center gap-[12px]">
                    <span className="text-[36px] font-[Sora] font-bold leading-[47px] tracking-[-0.04em] text-text mobile:text-[28px] mobile:leading-[35px]">
                        ${plan.price}
                    </span>
                    <span className="text-[18px] leading-[22px] text-secondaryText mobile:text-[12px] mobile:leading-[18px] mobile:text-nowrap">
                        / one-time
                    </span>
                </div>
                <button onClick={selectPlan} className="h-[56px] px-6 rounded-lg bg-button text-white text-base font-semibold mobile:h-[44px] mobile:w-full hover:bg-buttonHover transition-colors mobile:rounded-[10px]">
                    Select
                </button>
            </div>
        </div>
    )
}

export default PlanItem