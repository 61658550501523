import { useEffect, useMemo } from "react"

import {
    KEY_TO_BACKGROUND,
    KEY_TO_MAN_OUTFITS,
    KEY_TO_WOMAN_OUTFITS
} from "../../utils/constants"
import { useCreationContext } from "../../context/CreationContext"
import { GenerationUploadedImageItem } from "../../utils/types"

const ReviewStep = () => {
    const ctx = useCreationContext()

    const groupedBackgroundStylesByCategory = useMemo(() => {
        const groupedStyles: Record<string, string[]> = {}

        ctx?.state.backgroundStyles.split(',').forEach((style) => {
            const styleData = KEY_TO_BACKGROUND[style as keyof typeof KEY_TO_BACKGROUND]

            if (!styleData) return

            if (!groupedStyles[styleData.type]) {
                groupedStyles[styleData.type] = [styleData.style]
            } else {
                groupedStyles[styleData.type].push(styleData.style)
            }
        })

        return groupedStyles
    }, [ctx?.state.backgroundStyles])

    const groupedOutfitStylesByCategory = useMemo(() => {
        const groupedStyles: Record<string, string[]> = {}

        ctx?.state.outfitStyles.split(',').forEach((style) => {
            let styleData;

            if (ctx?.state.gender === 'male') {
                styleData = KEY_TO_MAN_OUTFITS[style as keyof typeof KEY_TO_MAN_OUTFITS]
            } else {
                styleData = KEY_TO_WOMAN_OUTFITS[style as keyof typeof KEY_TO_WOMAN_OUTFITS]
            }

            if (!styleData) return

            if (!groupedStyles[styleData.type]) {
                groupedStyles[styleData.type] = [styleData.style]
            } else {
                groupedStyles[styleData.type].push(styleData.style)
            }
        })

        return groupedStyles
    }, [ctx?.state.outfitStyles, ctx?.state.gender])

    const handleEditPart = (step: number) => {
        ctx?.setStep(step)
        ctx?.setIsEdit(true)

        if (step === 1) {
            ctx?.setTempUserInformation({
                gender: ctx?.state.gender,
                age: ctx?.state.age,
                eye_color: ctx?.state.eye_color,
                hair_color: ctx?.state.hair_color,
                ethnicity: ctx?.state.ethnicity,
                outfitStyles: ctx?.state.outfitStyles,
            })
        }
    }

    useEffect(() => {
        if (ctx?.state.outfitStyles === '') {
            ctx?.setStep(8)
        }
    }, [ctx])

    return (
        <div className="flex flex-col items-start gap-8 max-w-[960px] h-full w-full mobile:gap-4 mobile:pt-16">
            <div className="flex items-start justify-between w-full gap-4 mobile:bg-background mobile:fixed mobile:top-[56px] mobile:left-0 mobile:p-4 mobile:border-solid mobile:border-b-[1px] mobile:border-inputBorder z-[2]">
                <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] text-text font-bold mobile:text-[23px] mobile:leading-[28px]">
                    Review Information
                </h5>
            </div>
            <div className="w-full flex flex-col gap-3">
                <div className="flex justify-between w-full mobile:flex-col gap-3">
                    <div className="flex flex-col gap-6 p-6 w-full border-solid border-[1px] border-inputBorder rounded-xl mobile:p-4 mobile:gap-4">
                        <div className="flex items-center justify-between w-full gap-4">
                            <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] text-text font-bold">
                                Your Info
                            </p>
                            <button onClick={() => handleEditPart(1)} className="h-[32px] rounded-lg px-3 bg-inputBackground hover:bg-inputBorder transition-colors text-[14px] leading-[17px] font-semibold text-text">
                                Edit Info
                            </button>
                        </div>
                        <div className="grid grid-cols-2 gap-6 w-full mobile:gap-3">
                            <div className="flex flex-col gap-1">
                                <p className="text-[14px] leading-[17px] text-secondaryText">
                                    Gender
                                </p>
                                <span className="text-base text-text font-semibold capitalize">
                                    {ctx?.state.gender}
                                </span>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-[14px] leading-[17px] text-secondaryText">
                                    Age
                                </p>
                                <span className="text-base text-text font-semibold">
                                    {ctx?.state.age}
                                </span>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-[14px] leading-[17px] text-secondaryText">
                                    Eye Color
                                </p>
                                <span className="text-base text-text font-semibold">
                                    {ctx?.state.eye_color}
                                </span>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-[14px] leading-[17px] text-secondaryText">
                                    Hair Color
                                </p>
                                <span className="text-base text-text font-semibold">
                                    {ctx?.state.hair_color}
                                </span>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-[14px] leading-[17px] text-secondaryText">
                                    Ethnicity
                                </p>
                                <span className="text-base text-text font-semibold">
                                    {ctx?.state.ethnicity}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-6 p-6 w-full border-solid border-[1px] border-inputBorder rounded-xl mobile:p-4 mobile:gap-4">
                        <div className="flex items-center justify-between w-full gap-4">
                            <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] text-text font-bold">
                                Your Styles
                            </p>
                            <button onClick={() => handleEditPart(7)} className="h-[32px] rounded-lg px-3 bg-inputBackground hover:bg-inputBorder transition-colors text-[14px] leading-[17px] font-semibold text-text">
                                Edit Styles
                            </button>
                        </div>
                        <div className="grid grid-cols-2 gap-3 mobile:grid-cols-1 mobile:gap-4">
                            <div className="flex flex-col gap-3 w-full">
                                <p className="text-[14px] leading-[17px] text-secondaryText">
                                    Background Styles
                                </p>
                                {Object.entries(groupedBackgroundStylesByCategory).map(([category, styles]) => (
                                    <div key={category} className="flex flex-col border-solid border-s-[2px] border-button px-3">
                                        <p className="text-base text-text font-semibold">
                                            {category}
                                        </p>
                                        <div className="flex flex-wrap gap-1">
                                            {styles.map((style, index) => (
                                                <div key={index} className="flex items-center gap-2 min-h-[24px] px-2 rounded-[8px] border-solid border-[1px] border-inputBorder w-fit text-[12px] leading-[18px] font-semibold text-secondaryText">
                                                    {style}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="hidden min-h-[1px] bg-inputBorder mobile:flex w-full"></div>
                            <div className="flex flex-col gap-3 w-full">
                                <p className="text-[14px] leading-[17px] text-secondaryText">
                                    Outfit Styles
                                </p>
                                {Object.entries(groupedOutfitStylesByCategory).map(([category, styles]) => (
                                    <div key={category} className="flex flex-col border-solid border-s-[2px] border-button px-3">
                                        <p className="text-base text-text font-semibold">
                                            {category}
                                        </p>
                                        <div className="flex flex-wrap gap-1">
                                            {styles.map((style, index) => (
                                                <div key={index} className="flex items-center gap-2 min-h-[24px] px-2 rounded-[8px] border-solid border-[1px] border-inputBorder w-fit text-[12px] leading-[18px] font-semibold text-secondaryText">
                                                    {style}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-6 p-6 w-full border-solid border-[1px] border-inputBorder rounded-xl mobile:p-4 mobile:gap-4">
                    <div className="flex items-center justify-between w-full gap-4">
                        <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] text-text font-bold">
                            Your Photos
                        </p>
                        <button onClick={() => handleEditPart(6)} className="h-[32px] rounded-lg px-3 bg-inputBackground hover:bg-inputBorder transition-colors text-[14px] leading-[17px] font-semibold text-text">
                            Change Photos
                        </button>
                    </div>
                    <div className="flex flex-wrap gap-3 mobile:grid mobile:mobile:grid-cols-[repeat(auto-fill,minmax(80px,1fr))]">
                        {ctx?.uploadedImages.map((image, index) => (
                            <div key={index} className="w-[80px] h-[80px] relative rounded-lg overflow-hidden mobile:w-full mobile:h-full mobile:min-h-[80px] mobile:aspect-square">
                                <img
                                    className="w-full h-full object-cover"
                                    src={typeof image === 'object'
                                        ? (image as GenerationUploadedImageItem).image
                                        : URL.createObjectURL(image)}
                                    alt=""
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="min-h-[80px] w-full mobile:min-h-[24px]"></div>
        </div>
    )
}

export default ReviewStep