import { FC } from "react"

import { calculatePercentageProgress, convertDateToLocaleString } from "../../utils/functions"
import { GenerationItem } from "../../utils/types"
import useViewport from "../../hooks/useViewport"

type AlbumItemsInfoProps = {
    album: GenerationItem
}

const AlbumItemInfo: FC<AlbumItemsInfoProps> = ({ album }) => {
    const { isMobile } = useViewport()

    return (
        <div className="flex flex-col gap-4">
            <p className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] text-text font-bold mobile:text-base mobile:font-semibold mobile:tracking-normal">
                {album.title}
            </p>
            {!isMobile && (
                <>
                    {(album.completed && album.additional_generation !== 'pending') || album.is_draft ? (
                        <span className="text-secondaryText text-[18px] leading-[22px]">
                            {album.is_draft ? 'Draft started' : ''} {convertDateToLocaleString(album.created_at)}
                        </span>
                    ) : (
                        <div className="bg-inputBackground rounded-full h-[8px] w-full overflow-hidden">
                            <div style={{ width: `${calculatePercentageProgress(album.started_at, album.eta)}%` }} className="bg-button h-full"></div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default AlbumItemInfo