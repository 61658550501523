import { ChangeEvent, FC, MouseEvent, useRef } from "react";

import { useDeleteImagesForGenerationMutation } from "../../api/appApi";
import { useCreationContext } from "../../context/CreationContext";
import { GenerationUploadedImageItem } from "../../utils/types";
import useViewport from "../../hooks/useViewport";

import CheckSquare from "../../assets/creation/check-square.svg"
import Change from '../../assets/change.svg'

type UploadedImageItemProps = {
    image: File | GenerationUploadedImageItem;
    index: number;
}

const UploadedImageItem: FC<UploadedImageItemProps> = ({ image, index }) => {
    const ctx = useCreationContext()

    const inputRef = useRef<HTMLInputElement>(null)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const { isMobile } = useViewport()

    const [deleteImages] = useDeleteImagesForGenerationMutation()

    const handleImageUploaded = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            if (image instanceof File || !ctx?.id) {
                return
            } else {
                deleteImages({ id: ctx?.id, data: { ids: [image.id], select_all: false } })

                ctx?.setUploadedImages(
                    ctx.uploadedImages.filter((_, i) => i !== index).concat(Array.from(e.target.files))
                )
            }
        }
    }

    const handleSelectImage = (e: MouseEvent<HTMLDivElement>) => {
        if (buttonRef.current?.contains(e.target as Node) || inputRef?.current?.contains(e.target as Node)) return

        if (ctx?.selectedUploadedImages.includes(index)) {
            ctx.setSelectedUploadedImages(ctx.selectedUploadedImages.filter((imageIndex) => imageIndex !== index))
        } else if (ctx?.selectedUploadedImages) {
            ctx.setSelectedUploadedImages([...ctx.selectedUploadedImages, index])
        }
    }

    const handleChangeImage = () => {
        inputRef.current?.click()
    }

    return (
        <div
            onClick={(e) => handleSelectImage(e)}
            className={`rounded-lg overflow-hidden h-full max-h-[260px] cursor-pointer relative uploaded-image ${ctx?.selectedUploadedImages.includes(index) ? "outline outline-[4px] outline-button outline-offset-[-4px]" : ""} ${ctx?.isValidating || ctx?.isCompressingImages ? 'pointer-events-none' : ''}`}
        >
            {(ctx?.isValidating || ctx?.isCompressingImages) && ctx?.validatedImageIndex <= index && (
                <div className="flex items-center justify-center absolute w-full h-full z-[2] bg-[#00000033] backdrop-blur-[3px]">
                    <div className="validation-animation-container w-full justify-center">
                        <div className="validation-animation-item"></div>
                        <div className="validation-animation-item"></div>
                        <div className="validation-animation-item"></div>
                        <div className="validation-animation-item"></div>
                    </div>
                </div>
            )}
            <img
                className="object-cover h-full w-full min-h-[250px] mobile:min-h-[140px] image-ascept-ratio"
                src={image instanceof File ? URL.createObjectURL(image) : image.image}
                alt=""
            />
            {ctx?.selectedUploadedImages.includes(index) ? (
                <img
                    className={`absolute top-3 right-3 w-[24px] mobile:top-2 mobile:right-2 mobile:w-[18px] mobile:rounded-full ${ctx?.isValidating || ctx?.isCompressingImages ? 'hide-element-important' : ''}`}
                    src={CheckSquare}
                    alt=""
                />
            ) : (
                <>
                    <div className={`uploaded-image-checkbox w-[24px] h-[24px] rounded-[4px] border-solid border-[2px] border-white absolute right-3 top-3 mobile:w-[18px] mobile:h-[18px] mobile:rounded-full mobile:top-2 mobile:right-2 ${ctx?.isValidating || ctx?.isCompressingImages ? 'hide-element-important' : ''}`}></div>
                </>
            )}
            {image instanceof File ? null : !image.is_valid && (
                <div className="absolute inset-0 bg-[#FF525D1A] flex items-center justify-end p-3 flex-col gap-2 select-none mobile:p-2">
                    <div className="px-2 py-[10px] w-full rounded-lg bg-white text-[12px] leading-[18px] font-semibold text-[#191919] text-center">
                        {image.invalid_reason.blurry ? (
                            'The image is blurry'
                        ) : image.invalid_reason.faces > 1 ? (
                            'There are more than 1 face in the image'
                        ) : image.invalid_reason.faces === 0 ? (
                            'There is no face in the image'
                        ) : image.invalid_reason.small ? (
                            'The image is too small. Minimum size is 512x512 pixels'
                        ) : image.invalid_reason.hands_close ? (
                            'Some of your hands are too close to the face'
                        ) : image.invalid_reason.similar && image.invalid_reason.similar.length > 0 ? (
                            'The image is similar to another image'
                        ) : (
                            'The image was not uploaded due to an error'
                        )}
                    </div>
                    <button
                        ref={buttonRef}
                        onClick={handleChangeImage}
                        className="bg-redButton hover:bg-redButtonHover transition-colors w-full h-[44px] rounded-lg flex items-center justify-center gap-2 text-base font-semibold text-white"
                    >
                        <img src={Change} alt="" />
                        {!isMobile && (
                            'Change'
                        )}
                    </button>
                </div>
            )}
            <input
                onChange={(e) => handleImageUploaded(e)}
                accept="images/*"
                ref={inputRef}
                type="file"
                className="hidden"
            />
        </div>
    )
}

export default UploadedImageItem