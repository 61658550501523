import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useState } from "react"

import { useAuthContext } from "../context/AuthContext"
import { useAppSelector } from "../redux/hooks"

import Header from "../components/Header"

import AuthPreview from "../assets/auth-preview.png"
import NoTextLogo from "../assets/no-text-logo.svg"
import EyeClose from "../assets/eye-close.svg"
import Facebook from "../assets/facebook.svg"
import Google from "../assets/google.svg"
import Back from "../assets/back.svg"
import Eye from "../assets/eye.svg"

export type TSignInData = {
    email: string,
    password: string,
}

const SignIn = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const theme = useAppSelector((state) => state.app.theme)

    const navigate = useNavigate()

    const auth = useAuthContext()

    const {
        register,
        handleSubmit,
    } = useForm<TSignInData>()

    const handleSignIn = async (data: TSignInData) => {
        const user = await auth?.emailSignIn(data)

        if (user) {
            setIsError(false)
        } else {
            setIsError(true)
        }
    }

    return (
        <div className="w-full min-h-[100vh] mobile:min-h-[100dvh]">
            <Header type="unauthorized" />
            <div className="grid grid-cols-[1fr,1.1fr] h-[calc(100vh-68px)] mobile:grid-cols-[1fr] mobile:h-[100dvh]">
                <div className="flex flex-col items-center mobile:justify-between gap-[64px] p-6 py-20 mobile:p-4 mobile:gap-4 h-full overflow-y-auto custom-scroll">
                    <div className="mobile:flex w-full justify-start hidden">
                        <div onClick={() => navigate('/')} className="w-[44px] h-[44px] rounded-[10px] border-solid border-[1px] border-inputBorder flex items-center justify-center">
                            <img src={Back} alt="" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-[64px] mobile:gap-4 w-full">
                        <div className="flex flex-col items-center gap-6">
                            <img
                                className={`mobile:hidden`}
                                width={124}
                                src={NoTextLogo}
                                alt=""
                            />
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-.02em] font-bold text-text mobile:text-[28px] mobile:leading-[35px] mobile:mb-3">
                                Sign in
                            </h5>
                        </div>
                        <div className="flex flex-col max-w-[480px] w-full gap-6 mx-auto">
                            <div className="flex flex-col gap-2">
                                <button onClick={auth?.googleAuth} className="bg-inputBackground w-full flex items-center justify-center gap-2 h-[56px] rounded-xl text-text font-semibold mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] mobile:bg-background mobile:border-solid mobile:border-[1px] mobile:border-inputBorder hover:bg-inputBorder transition-colors mobile:rounded-[10px]">
                                    <img src={Google} alt="" />
                                    Continue with Google
                                </button>
                                <button onClick={auth?.facebookAuth} className="bg-inputBackground w-full flex items-center justify-center gap-2 h-[56px] rounded-xl text-text font-semibold mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] mobile:bg-background mobile:border-solid mobile:border-[1px] mobile:border-inputBorder hover:bg-inputBorder transition-colors mobile:rounded-[10px]">
                                    <img src={Facebook} alt="" />
                                    Continue with Facebook
                                </button>
                            </div>
                            <div className="relative w-full h-[1px] bg-placeholder max-w-[480px]">
                                <span className="absolute top-[-8px] left-[50%] text-placeholder font-semibold text-[12px] leading-[18px] px-2 bg-background translate-x-[-50%]">OR SIGN IN WITH EMAIL</span>
                            </div>
                            <form className="max-w-[480px] w-full flex flex-col gap-4" onSubmit={handleSubmit(handleSignIn)}>
                                <div className="flex flex-col gap-2 w-full">
                                    <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter email address"
                                        className={`h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] ${isError ? 'border-redButton' : ''}`}
                                        {...register("email", {
                                            required: "Email is required",
                                            onChange: () => isError && setIsError(false),
                                        })}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <div className="flex items-center w-full justify-between gap-2">
                                        <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                                            Password
                                        </label>
                                        <Link
                                            className="text-button mobile:text-[14px] mobile:leading-[17px] hover:text-buttonHover transition-colors"
                                            to="/reset-password"
                                        >
                                            Forgot?
                                        </Link>
                                    </div>
                                    <div className="relative w-full">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Enter password"
                                            className={`h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base w-full mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] ${isError ? 'border-redButton' : ''}`}
                                            {...register("password", {
                                                required: "Enter a valid password",
                                                onChange: () => isError && setIsError(false),
                                                minLength: 8,
                                            })}
                                        />
                                        {
                                            showPassword ? (
                                                <img
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                    src={Eye}
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                    src={EyeClose}
                                                    alt=""
                                                />
                                            )}
                                    </div>
                                </div>
                                <button type="submit" className="h-[56px] w-full rounded-lg bg-button text-white mt-2 text-[18px] leading-[22px] font-semibold font-[Inter] mobile:h-[44px] hover:bg-buttonHover transition-colors mobile:rounded-[10px]">
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="font-semibold text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                        Don’t have an account?{" "}
                        <Link to={`/sign-up${searchParams.get('plan') ? `?plan=${searchParams.get('plan')}` : ''}`} className="text-button cursor-pointer hover:text-buttonHover transition-colors mobile:active:text-buttonHover">
                            Create an account
                        </Link>
                    </div>
                </div>
                <div className="mobile:hidden flex items-center justify-center p-6 h-[calc(100vh-68px)]">
                    <img className="h-full" src={AuthPreview} alt="" />
                </div>
            </div>
        </div>
    )
}

export default SignIn