import { useState } from 'react'

import { useAppSelector } from '../../redux/hooks'

import ArrowTop from '../../assets/arrow-top.svg'

const GeneratingNewImages = () => {
    const [expanded, setExpanded] = useState(true)
    const theme = useAppSelector((state) => state.app.theme)

    const array = new Array(40).fill(0)

    return (
        <div className="w-[360px] min-w-[360px] pr-12 mobile:p-0 mobile:w-full mobile:fixed mobile:bottom-[0] mobile:left-0 z-[14]">
            <div className="border-solid border-[1px] border-inputBorder rounded-xl w-full overflow-hidden mobile:rounded-t-lg mobile:rounded-b-none mobile:border-x-0">
                <div onClick={() => setExpanded(!expanded)} className={`w-full h-[56px] p-4 border-solid border-inputBorder flex items-center justify-between cursor-pointer hover:bg-inputBackground transition-colors mobile:h-[44px] bg-background ${expanded ? 'border-b-[1px]' : ' border-none'}`}>
                    <h5 className="text-base text-text font-semibold flex items-center justify-center gap-2">
                        Generating New Images
                        <div className="h-[24px] px-2 border-solid border-[1px] border-inputBorder flex items-center justify-center rounded-lg text-secondaryText text-[12px] leading-[18px]">
                            40
                        </div>
                    </h5>
                    <img className={`${expanded ? '' : 'rotate-180'} ${theme === 'dark' ? 'icon-to-white' : ''}`} src={ArrowTop} alt="" />
                </div>
                {expanded && (
                    <div className="grid grid-cols-[repeat(3,1fr)] gap-2 p-4 mobile:bg-background mobile:max-h-[80vh] overflow-y-auto custom-scroll">
                        {array.map((_, index) => (
                            <div key={index} className={`w-full min-h-[115px] rounded-lg overflow-hidden image-ascept-ratio box`}>
                                <div className={`w-full h-full ${theme === 'light' ? 'bg-[#BFBFBF]' : 'bg-[#404040]'}`}></div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div >
    )
}

export default GeneratingNewImages