import { useEffect } from "react"

import { useAppSelector } from "../redux/hooks"

import AgreementBanner from "../components/Common/AgreementBanner"
import Footer from "../components/Landing/Footer"
import Header from "../components/Header"

const TermsAndConditions = () => {
    const user = useAppSelector((state) => state.app.user)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="flex flex-col w-full">
            <Header type={user ? "default" : "landing"} />
            <AgreementBanner type="terms" />
            <div className="flex flex-col gap-[64px] mobile:gap-8">
                <div className="w-full flex justify-center py-[128px] px-[64px] border-solid border-b-[8px] border-inputBorder mobile:py-16 mobile:px-4 mobile:border-0">
                    <div className="max-w-[768px] flex flex-col gap-[64px] w-full mobile:gap-8">
                        <div className="flex flex-col gap-6 w-full">
                            <h3 className="font-[Sora] text-[38px] leading-[48px] tracking-[-0.04em] text-text font-bold text mobile:text-[28px] mobile:leading-[35px]">
                                Effective Date: July 29, 2024
                            </h3>
                            <p className="text-[20px] leading-[28px] text-secondaryText mobile:text-[18px] mobile:leading-[22px]">
                                Welcome to JustHeadshots.ai! These Terms and Conditions ("Terms") govern your use of our AI headshot image generator services. By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                1. Acceptance of Terms
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                By accessing or using JustHeadshots.ai, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you are using our services on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-8 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                2. Use of Services
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    2.1 Eligibility
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    By accessing or using JustHeadshots.ai, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you are using our services on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    2.2 Account Registration
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    To use certain features of our services, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account credentials and for any activities or actions under your account.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    2.3 License
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    JustHeadshots.ai grants you a limited, non-exclusive, non-transferable, and revocable license to access and use our services for personal or commercial purposes in accordance with these Terms.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    2.4 Prohibited Uses
                                </h6>
                                <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                    You agree not to:
                                </p>
                                <ul>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Use our services for any illegal or unauthorized purpose.
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Upload any content that is unlawful, harmful, obscene, or otherwise objectionable.
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Infringe on the intellectual property rights of others.
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Interfere with or disrupt the integrity or performance of our services.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-8 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                3. Payment and Subscription
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    3.1 Fees
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    Certain features of our services may be subject to payment. You agree to pay all applicable fees for the use of our services. We may change our fees from time to time, and any changes will be effective upon posting on our website.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    3.2 Billing
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    You must provide accurate and complete billing information, including a valid payment method. By providing your payment information, you authorize us to charge the applicable fees to your designated payment method.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    3.3 Refunds
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    All fees paid are 100% refundable within 14 days if unsatisfied with the results.
                                </p>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-8 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                4. Ownership and Rights
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    4.1 Ownership
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    By purchasing or using our AI headshot generator, you, the user, are granted full ownership rights to the photos generated. This includes, but is not limited to, the ability to use, distribute, modify, and sell the images without any restrictions.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    4.2 Usage Permissions
                                </h6>
                                <div className="flex flex-col gap-2 w-full">
                                    <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                        You have the unrestricted right to use your generated headshots for any purpose. This includes personal, commercial, and promotional use, such as:
                                    </p>
                                    <ul>
                                        <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                            Posting on social media platforms and online profiles.
                                        </li>
                                        <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                            Using as profile pictures on various platforms.
                                        </li>
                                        <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                            Including in marketing materials, advertisements, and other promotional content.
                                        </li>
                                        <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                            Selling or licensing the images to third parties.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    4.3 Access and Download
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    Once your AI headshots are generated, you will have full, unlimited access to download the high-resolution images. There are no limits on the number of downloads or the duration of access.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    4.4 Modification and Adaptation
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    You are free to edit, retouch, or otherwise modify your generated photos as you see fit. This includes the use of additional software or services to enhance or alter the images.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    4.5 Transferability
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    The rights and access granted to you are transferable. You may transfer, sell, or license your rights to the generated photos to other parties.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    4.6 No Attribution Required
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    You are not required to provide attribution or credit to JustHeadshots.ai when using your generated photos. However, we welcome and appreciate voluntary credits.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    4.7 Privacy and Security
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    Your uploaded photos and generated images are securely stored and processed. We do not share or use your images for any purposes other than generating your headshots, in accordance with our Privacy Policy.
                                </p>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                5. Termination
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                We reserve the right to suspend or terminate your access to our services at any time, with or without cause, and with or without notice. Upon termination, all rights and licenses granted to you in these Terms will cease immediately.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-8 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                6. Disclaimers and Limitation of Liability
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    6.1 Disclaimers
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    Our services are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the availability, reliability, or accuracy of our services.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    6.2 Limitation of Liability
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    To the maximum extent permitted by law, JustHeadshots.ai shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use our services; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; and (c) any interruption or cessation of transmission to or from our services.
                                </p>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                7. Indemnification
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                You agree to indemnify, defend, and hold harmless JustHeadshots.ai and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of our services or your violation of these Terms.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                8. Governing Law
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, U.S.A., without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in the State of Delaware, U.S.A., to resolve any dispute arising out of or relating to these Terms or our services.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                9. Changes to Terms
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. Your continued use of our services after the changes become effective constitutes your acceptance of the new Terms.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                10. Contact Us
                            </h5>
                            <div className="flex flex-col gap-2">
                                <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                    If you have any questions or concerns about these Terms, please contact us at:
                                </p>
                                <ul>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Email:
                                        </span>
                                        <span className="text-base text-button mobile:text-[14px] mobile:leading-[17px]">
                                            support@justheadshots.ai
                                        </span>
                                    </li>
                                </ul>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    Thank you for using JustHeadshots.ai! We appreciate your business and are committed to providing you with the best possible service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer landing={false} />
            </div>
        </div>
    )
}

export default TermsAndConditions