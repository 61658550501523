import { useParams } from "react-router-dom"

import { useCreationContext } from "../../context/CreationContext"
import { useAppSelector } from "../../redux/hooks"

import CreationForward from '../../assets/creation/forward.svg'

const GenderStep = () => {
    const theme = useAppSelector(state => state.app.theme)

    const ctx = useCreationContext()

    const { id } = useParams()

    const handleChangeGender = async (gender: 'male' | 'female') => {
        const prevGender = ctx?.state.gender

        ctx?.setState({
            ...ctx?.state,
            gender: gender,
            outfitStyles: prevGender !== gender ? '' : ctx?.state.outfitStyles
        })
        ctx?.setStep(2)

        if (!id) return

        await ctx?.syncData({
            generation_id: id,
            field: 'gender',
            value: gender === 'male' ? 'man' : 'woman'
        })

        if (prevGender !== gender) {
            ctx?.syncData({
                generation_id: id,
                field: 'styles',
                value: `${ctx?.state?.backgroundStyles}`
            })
        }
    }

    return (
        <div className="flex flex-col items-start gap-[40px] max-w-[480px] w-full mobile:gap-4">
            <div className="flex items-center justify-center w-full relative">
                <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] text-text font-bold mobile:text-[19px] mobile:leading-[23px]">
                    What is your gender?
                </h5>
                {ctx?.state?.gender && (
                    <div onClick={() => ctx?.setStep(2)} className="w-[36px] h-[36px] rounded-lg bg-inputBackground absolute right-0 flex items-center justify-center hover:bg-inputBorder transition-colors cursor-pointer">
                        <img className={`${theme === 'dark' ? 'icon-to-white' : ''}`} src={CreationForward} alt="" />
                    </div>
                )}
            </div>
            <div className="flex flex-col w-full rounded-lg border-solid border-[1px] border-inputBorder h-fit">
                <div
                    onClick={() => handleChangeGender('male')}
                    className={`w-full flex items-center justify-center border-solid border-inputBorder font-semibold text-text text-[18px] leading-[22px] cursor-pointer rounded-t-lg mobile:text-base h-[56px] border-b-[1px] mobile:h-[44px] ${ctx?.state?.gender === 'male' ? 'outline outline-2 outline-button bg-inputTransparentBackground outline-offset-[-2px]' : 'input-focus-hover'}`}
                >
                    Male
                </div>
                <div
                    onClick={() => handleChangeGender('female')}
                    className={`w-full h-[56px] flex items-center justify-center font-semibold text-text text-[18px] leading-[22px] cursor-pointer rounded-b-lg mobile:text-base mobile:h-[44px] ${ctx?.state?.gender === 'female' ? 'outline outline-2 outline-button bg-inputTransparentBackground outline-offset-[-2px]' : 'input-focus-hover'}`}
                >
                    Female
                </div>
            </div>
        </div>
    )
}

export default GenderStep