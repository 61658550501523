import { useEffect, useState } from "react";

const useViewport = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth < 1000) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };

        handleWindowResize()
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { isMobile };
}

export default useViewport