import { useState } from "react";

type UseElementScrolledProps = {
    elementRef: React.RefObject<HTMLElement> | undefined;
    eventScrollPosition?: number;
}

const useElementScrolled = ({ elementRef, eventScrollPosition = 50 }: UseElementScrolledProps) => {
    const [isScrolled, setIsScrolled] = useState(false);

    elementRef?.current?.addEventListener('scroll', function (e: Event) {
        const scrollPosition = elementRef?.current?.scrollTop || 0;

        if (scrollPosition > eventScrollPosition) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    });

    return {
        isScrolled
    }
}

export default useElementScrolled