import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { useAppSelector } from "../redux/hooks"
import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useState } from "react"

import { ADDITIONAL_PLAN_INFO, PLANS } from "../utils/constants"
import { useCreateInvoiceMutation } from "../api/appApi"
import { Coupon } from "../utils/types"

import StripeForm from "../components/UpgradePlan/StripeForm"
import PayPalButton from "../components/PayPalButton"
import Loading from "../components/Common/Loading"
import Header from "../components/Header"

import PlansStripeBlack from "../assets/plans/stripe-black.svg"
import PlansStripeWhite from "../assets/plans/stripe-white.svg"
import PlansMoneyBack from "../assets/plans/money-back.svg"
import PlansCardWhite from "../assets/plans/card-white.svg"
import PlanPayPal from "../assets/plans/paypal.svg"
import PlansArrow from "../assets/plans/arrow.svg"
import PlansCard from "../assets/plans/card.svg"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')

const Checkout = () => {
    const [verifiedCoupon, setVerifiedCoupon] = useState<Coupon | null>(null)
    const [isStripe] = useState<boolean>(true)
    const [clientSecret, setClientSecret] = useState<string>('')
    const [searchParams] = useSearchParams()
    const theme = useAppSelector(state => state.app.theme)

    const { secret, plan } = useParams()

    const selectedPlan = PLANS.find(p => p.name === plan) || ADDITIONAL_PLAN_INFO

    const navigate = useNavigate()

    const [createInvoice, { isLoading }] = useCreateInvoiceMutation()

    const appearance = {
        variables: {
            spacingUnit: '4px',
        },
        rules: {
            '.Tab': {
                border: '1px solid #E2E8F0',
                backgroundColor: `${theme === 'light' ? '#FFFFFF' : '#262626'}`,
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },

            '.Input': {
                border: `1px solid ${theme === 'light' ? '#E6E6E6' : '#333333'}`,
                backgroundColor: `${theme === 'light' ? '#FFFFFF' : '#262626'}`,
                padding: '14px 16px',
                outline: 'none',
                marginBottom: '3px',
                color: `${theme === 'light' ? '#191919' : '#FFFFFF'}`,
                borderRadius: '12px',
                boxShadow: 'none',
            },

            '.Input:focus': {
                boxShadow: '0 0 0 1px #0085FF',
                borderColor: '#0085FF',
            },

            '.Label': {
                fontSize: '15px',
                fontWeight: '400',
                lineHeight: '18px',
                color: '#666666',
                fontVariant: 'normal',
                marginBottom: '3px',
            },

            '.Tab:hover': {
                color: 'var(--colorText)',
            },

            '.Tab--selected': {
                borderColor: '#E2E8F0',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },

            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },

            '.Input::placeholder': {
                color: `${theme === 'light' ? '#BFBFBF' : '#404040'}`,
                fontSize: '16px',
            },

            '.TermsText': {
                color: '#666666',
                fontSize: '12px',
            }
        }
    };

    const options = {
        clientSecret,
        appearance: appearance,
    };

    useEffect(() => {
        if (secret) {
            createInvoice({
                price_id: secret,
                coupon_code: verifiedCoupon?.id || '',
            })
                .unwrap()
                .then(res => {
                    setClientSecret(res.pi_secret)
                })
        }
    }, [secret, createInvoice, verifiedCoupon])

    return (
        <div className="flex flex-col w-full">
            <Header
                type="back"
                backTo={
                    searchParams.get('generation')
                        ? `/album/${searchParams.get('generation')}`
                        : searchParams.get('continue_generation')
                            ? `/plans?continue_generation=${searchParams.get('continue_generation')}`
                            : '/plans'
                }
            />
            <div className="px-6 mobile:p-0 h-[calc(100vh-68px)] mobile:h-[calc(100vh-56px)] overflow-y-auto custom-scroll">
                {clientSecret && !isLoading ? (
                    <Elements key={clientSecret} options={options} stripe={stripePromise}>
                        <StripeForm
                            plan={plan || 'Basic'}
                            selectedPlan={selectedPlan}
                            verifiedCoupon={verifiedCoupon}
                            setVerifiedCoupon={setVerifiedCoupon}
                        />
                    </Elements>
                ) : (
                    <div className="dashboard-background-image flex items-start gap-6 max-w-[960px] m-auto py-[80px] w-full mobile:flex-col mobile:py-0 mobile:gap-0">
                        <div className="border-solid border-[1px] border-inputBorder w-full rounded-lg flex flex-col gap-6 mobile:border-b-[1px] mobile:rounded-none mobile:gap-4">
                            <div className="flex flex-col gap-6 w-full border-solid border-b-[1px] border-inputBorder">
                                <div className="flex items-center justify-between w-full pt-6 px-8 mobile:px-4">
                                    <h5 className="font-[Sora] text-[28px] leading-[35px] font-bold tracking-[-0.02em] text-text mobile:text-[23px] mobile:leading-[28px]">
                                        Secure Checkout
                                    </h5>
                                    <img src={theme === 'light' ? PlansStripeBlack : PlansStripeWhite} alt="" />
                                </div>
                                <div className="h-[38px] flex items-start">
                                    <div
                                        className={`flex items-start justify-center gap-2 px-8 h-full text text-[18px] leading-[22px] font-semibold cursor-pointer ${isStripe ? 'text-button border-solid border-b-[3px] border-button' : 'text-secondaryText hover:text-text transition-colors'} mobile:w-1/2 mobile:px-0`}
                                    >
                                        <img className={`${isStripe ? 'icon-to-blue' : 'icon-to-secondary-text-color'} mt-[2px]`} src={isStripe ? PlansCard : theme === 'light' ? PlansCard : PlansCardWhite} width={18} alt="" />
                                        Credit Card
                                    </div>
                                    <div
                                        className={`flex items-start justify-center gap-2 px-8 h-full text-[18px] leading-[22px] font-semibold cursor-pointer ${!isStripe ? 'text-button border-solid border-b-[3px] border-button' : 'text-secondaryText hover:text-text transition-colors'} mobile:w-1/2 mobile:px-0`}
                                    >
                                        <img className="mt-[2px]" src={PlanPayPal} alt="" />
                                        PayPal
                                    </div>
                                </div>
                            </div>
                            {isStripe ? (
                                <Loading />
                            ) : (
                                <div className="flex flex-col gap-2 w-full items-center justify-center pt-6 mobile:pb-4">
                                    <img width={36} src={PlanPayPal} alt="" />
                                    <p className="text-text text-[14px] leading-[17px] font-semibold">
                                        Click below to pay through PayPal
                                    </p>
                                </div>
                            )}
                            <div className="flex flex-col p-6 gap-6 pt-0 mobile:px-4 mobile:gap-4">
                                {selectedPlan && selectedPlan.name !== 'Additional' && (
                                    <div
                                        onClick={() => navigate('/plans')}
                                        className="flex items-center justify-between p-3 gap-2 rounded-lg border-solid border-[1px] border-inputBorder cursor-pointer"
                                    >
                                        <div className="flex flex-col">
                                            <span className="text-text text-[12px] leading-[17px]">Selected Plan</span>
                                            <span className="text-text text-base font-semibold">{plan}</span>
                                        </div>
                                        <img src={PlansArrow} alt="" />
                                    </div>
                                )}
                                <div className="flex items-center justify-between gap-4">
                                    <p className="text-[23px] leading-[28px] font-bold tracking-[-0.02em] text-text">Due today:</p>
                                    <p className="text-[23px] leading-[28px] font-bold tracking-[-0.02em] text-text">
                                        ${selectedPlan?.price}.00
                                    </p>
                                </div>
                                {isStripe ? (
                                    <button
                                        disabled={true}
                                        className="bg-button rounded-lg w-full px-6 py-[10px] font-semibold text-base text-white h-[56px] hover:bg-buttonHover transition-colors disabled:opacity-20 mobile:h-[44px] mobile:rounded-[10px]"
                                    >
                                        Purchase
                                    </button>
                                ) : (
                                    <PayPalButton
                                        price={selectedPlan.price}
                                        planName={selectedPlan.name}
                                    />
                                )}
                                <p className="text-[12px] leading-[18px] text-secondaryText">
                                    By clicking “Purchase” you agree to be charged ${selectedPlan?.price}.00 now and accept our <Link className="text-button" to='/terms-and-conditions'>Terms of Use</Link> and <Link className="text-button" to="/privacy-policy">Privacy Policy</Link>. Your payment will appear as AI Headshots on your credit card statement.
                                </p>
                            </div>
                        </div>
                        <div className="bg-[#EFEFFF] h-[5px] w-full hidden mobile:block"></div>
                        <div className="border-solid border-[1px] border-inputBorder min-w-[280px] w-[280px] rounded-lg p-6 flex flex-col gap-2 mobile:w-full mobile:rounded-none mobile:border-t-[1px] mobile:p-4">
                            <img width={44} src={PlansMoneyBack} alt="" />
                            <h5 className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] text-text font-semibold">
                                Money-back guarantee
                            </h5>
                            <p className="pb-0 text-text text-[12px] leading-[18px]">
                                If you are not fully satisfied with your image results, just let us know and we will happily process a full refund.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Checkout