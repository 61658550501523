import { FC, useCallback, useEffect, useRef, useState } from "react"
import Hammer from 'hammerjs';

import { useAlbumContext } from "../context/AlbumContext"
import { GenerationResultImage } from "../utils/types"
import { useAppSelector } from "../redux/hooks"
import useViewport from "../hooks/useViewport"

import Modal from "./Modal"

import ArrowRightDarkGrey from "../assets/arrow-right-dark-grey.svg"
import HeartBlackFilled from "../assets/heart-black-filled.svg"
import HeartRedFilled from "../assets/heart-red-filled.svg"
import DownloadWhite from "../assets/download-white.svg"
import ArrowLeft from "../assets/arrow-left.svg"
import Close from "../assets/close.svg"
import Heart from "../assets/heart.svg"

type AlbumPreviewModalProps = {
    onClose: () => void,
    album: GenerationResultImage[],
    previewImage: string,
}

const AlbumPreviewModal: FC<AlbumPreviewModalProps> = ({ onClose, album, previewImage }) => {
    const [activeImage, setActiveImage] = useState<GenerationResultImage>(album.find(image => image.id === previewImage) || album[0])
    const [showScroll, setShowScroll] = useState<boolean>(false)
    const theme = useAppSelector(state => state.app.theme)

    const imagesListRef = useRef<HTMLDivElement>(null)
    const swiperRef = useRef<HTMLDivElement>(null)

    const albumCtx = useAlbumContext()

    const { isMobile } = useViewport()

    const handleDownloadCurrent = () => {
        const link = document.createElement('a')
        link.href = activeImage.image
        link.download = 'image.jpg'
        link.click()
    }

    const handleFavoriteCurrent = () => {
        albumCtx?.handleUpdateFavorite(activeImage.id)
    }

    const handlePreviewPrev = useCallback(() => {
        const currentIndex = album.findIndex(image => image.id === activeImage.id)
        if (currentIndex === 0) {
            setActiveImage(album[album.length - 1])
        } else {
            setActiveImage(album[currentIndex - 1])
        }
    }, [album, activeImage])

    const handlePreviewNext = useCallback(() => {
        const currentIndex = album.findIndex(image => image.id === activeImage.id)
        if (currentIndex === album.length - 1) {
            setActiveImage(album[0])
        } else {
            setActiveImage(album[currentIndex + 1])
        }
    }, [album, activeImage])

    const imageBeforeActive = () => {
        const currentIndex = album.findIndex(image => image.id === activeImage.id)

        if (currentIndex === 0) {
            return album[album.length - 1]
        } else {
            return album[currentIndex - 1]
        }
    }

    const imageAfterActive = () => {
        const currentIndex = album.findIndex(image => image.id === activeImage.id)

        if (currentIndex === album.length - 1) {
            return album[0]
        } else {
            return album[currentIndex + 1]
        }
    }

    useEffect(() => {
        setActiveImage(album.find(image => image.id === activeImage.id) || album[0])
    }, [album, activeImage])

    useEffect(() => {
        if (imagesListRef.current) {
            const imageSize = isMobile ? 42 : 60;
            const gap = 12;
            const width = imagesListRef.current.clientWidth
            const imagesPerSide = Math.floor(width / (imageSize + gap) - 1) / 2

            const currentIndex = album.findIndex(image => image.id === activeImage.id)

            imagesListRef.current.scrollTo({
                left: (currentIndex - imagesPerSide) * (imageSize + gap)
            })
        }
    }, [activeImage, album, isMobile])

    useEffect(() => {
        if (imagesListRef.current) {
            if (imagesListRef.current.scrollWidth > imagesListRef.current.clientWidth) {
                setShowScroll(true)
            } else {
                setShowScroll(false)
            }
        }
    }, [album])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') {
                handlePreviewPrev()
            } else if (e.key === 'ArrowRight') {
                handlePreviewNext()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handlePreviewNext, handlePreviewPrev])

    useEffect(() => {
        const swipeContainer = swiperRef.current;

        if (!swipeContainer || !isMobile) return;

        const hammer = new Hammer(swipeContainer);

        hammer.on('swipeleft', () => {
            handlePreviewNext();
        });

        hammer.on('swiperight', () => {
            handlePreviewPrev();
        });

        return () => {
            hammer.destroy();
        };
    }, [handlePreviewNext, handlePreviewPrev, isMobile]);

    return (
        <Modal
            onClose={onClose}
            bodyStyles={{ padding: 0 }}
            customBodyClasses="bg-background max-w-[90vw] max-h-[90vh] mobile:max-w-unset mobile:max-h-unset mobile:rounded-xl"
        >
            <div className="flex flex-col w-full h-full">
                <div className="h-[76px] flex items-center justify-between px-6 gap-6 border-solid border-b-[1px] border-inputBorder pe-0 mobile:h-[56px]">
                    <h5 className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] font-bold text-text mobile:text-[19px] mobile:leading-[23px] mobile:font-semibold">
                        Preview
                    </h5>
                    <div className="w-[76px] h-full flex items-center justify-center border-solid border-s-[1px] border-inputBorder hover:bg-inputBackground transition-colors cursor-pointer mobile:border-0 mobile:w-[56px]" onClick={onClose}>
                        <img
                            className={`cursor-pointer ${theme === 'light' ? '' : 'icon-to-white'} mobile:w-[24px]`}
                            src={Close}
                            alt=""
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center gap-6 justify-center h-[calc(90vh-176px)] p-6 relative mobile:p-4 mobile:gap-4">
                    <div ref={swiperRef} className="relative mobile:h-[calc(100%-80px)] hidden mobile:flex items-center gap-4 justify-center mobile:px-4">
                        {album.length > 1 && (
                            <img
                                className="hidden mobile:block rounded-xl h-full"
                                src={imageBeforeActive().image}
                                alt=""
                            />
                        )}
                        <img
                            className={`${showScroll ? 'h-[calc(100%-150px)]' : 'h-[calc(100%-110px)]'} rounded-xl mobile:h-full object-cover`}
                            src={activeImage.image}
                            alt=""
                        />
                        {album.length > 1 && (
                            <img
                                className="hidden mobile:block rounded-xl h-full"
                                src={imageAfterActive().image}
                                alt=""
                            />
                        )}
                    </div>
                    <img
                        className={`${showScroll ? 'h-[calc(100%-150px)]' : 'h-[calc(100%-110px)]'} rounded-xl mobile:h-[calc(100%-80px)] object-cover mobile:hidden`}
                        src={activeImage.image}
                        alt=""
                    />
                    <div className="relative w-[calc(100%-88px)] mobile:w-[calc(100%-32px)]">
                        <div ref={imagesListRef} className={`flex items-center gap-3 w-full min-h-[88px] h-[88px] overflow-x-auto hidden-scroll-bar mobile:h-[60px] mobile:min-h-[60px] px-2 ${showScroll ? '' : 'justify-center'}`}>
                            {album.map(image => (
                                <div key={image.id} onClick={() => setActiveImage(image)} className={`w-[60px] min-w-[60px] h-[80px] rounded-lg overflow-hidden cursor-pointer relative mobile:w-[42px] mobile:min-w-[42px] mobile:h-[56px] ${activeImage.id === image.id ? 'outline outline-[4px] mobile:outline-[2px] outline-button' : ''}`}>
                                    <img
                                        className={`object-cover ${activeImage.id === image.id ? '' : ''}`}
                                        src={image.image}
                                        alt=""
                                    />
                                    {image.favorite && (
                                        <img className="absolute top-1 left-1 z-10" width={14} src={HeartRedFilled} alt="" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <button onClick={handlePreviewPrev} className="absolute top-[50%] left-[64px] rounded-lg flex items-center justify-center w-[52px] h-[120px] translate-y-[-50%] bg-inputBackground hover:bg-inputBorder transition-colors mobile:hidden">
                        <img
                            className={`${theme === 'light' ? '' : 'icon-to-white rotate-[180deg]'}`}
                            width={20}
                            src={theme === 'light' ? ArrowLeft : ArrowRightDarkGrey}
                            alt=""
                        />
                    </button>
                    <button onClick={handlePreviewNext} className="absolute top-[50%] right-[64px] rounded-lg flex items-center justify-center w-[52px] h-[120px] translate-y-[-50%] bg-inputBackground hover:bg-inputBorder transition-colors mobile:hidden">
                        <img
                            className={`${theme === 'light' ? 'rotate-[180deg]' : 'icon-to-white'}`}
                            width={20}
                            src={theme === 'light' ? ArrowLeft : ArrowRightDarkGrey}
                            alt=""
                        />
                    </button>
                </div>
                <div className="h-[92px] flex items-center justify-end px-6 border-solid border-t-[1px] border-inputBorder gap-2 mobile:h-[76px] mobile:px-4">
                    {activeImage.favorite ? (
                        <button onClick={handleFavoriteCurrent} className="flex items-center gap-2 h-[44px] rounded-lg px-4 font-[Inter] font-semibold text-[14px] leading-[17px] text-background bg-redButton hover:bg-redButtonHover transition-colors mobile:w-1/2 mobile:justify-center">
                            <img className={theme === 'light' ? 'icon-to-white' : ''} src={HeartBlackFilled} alt="" />
                            Favorited
                        </button>
                    ) : (
                        <button onClick={handleFavoriteCurrent} className="flex items-center gap-2 h-[44px] rounded-lg px-4 font-[Inter] font-semibold text-[14px] leading-[17px] text-text bg-inputBackground hover:bg-inputBorder transition-colors mobile:w-1/2 mobile:justify-center">
                            <img className={`${theme === 'light' ? '' : 'icon-to-white'}`} src={Heart} alt="" />
                            Favorite
                        </button>
                    )}
                    <button onClick={handleDownloadCurrent} className="text-white bg-button h-[44px] flex items-center gap-2 rounded-lg px-4 font-[Inter] font-semibold text-[14px] leading-[17px] hover:bg-buttonHover transition-colors mobile:w-1/2 mobile:justify-center">
                        <img src={DownloadWhite} alt="" />
                        Download
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default AlbumPreviewModal