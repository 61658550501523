import { useEffect } from "react"

import { useAppSelector } from "../redux/hooks"

import AgreementBanner from "../components/Common/AgreementBanner"
import Footer from "../components/Landing/Footer"
import Header from "../components/Header"

const PrivacyPolicy = () => {
    const user = useAppSelector((state) => state.app.user)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="flex flex-col">
            <Header type={user ? "default" : "landing"} />
            <AgreementBanner type="privacy" />
            <div className="flex flex-col gap-[64px] mobile:gap-8">
                <div className="w-full flex justify-center py-[128px] px-[64px] border-solid border-b-[8px] border-inputBorder mobile:py-16 mobile:px-4 mobile:border-0">
                    <div className="max-w-[768px] flex flex-col gap-[64px] w-full mobile:gap-8">
                        <div className="flex flex-col gap-6 w-full">
                            <h3 className="font-[Sora] text-[38px] leading-[48px] tracking-[-0.04em] text-text font-bold mobile:text-[28px] mobile:leading-[35px]">
                                Effective Date: July 29, 2024
                            </h3>
                            <p className="text-[20px] leading-[28px] text-secondaryText mobile:text-[18px] mobile:leading-[22px]">
                                Welcome to JustHeadshots.ai! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our AI headshot image generator services.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-8 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                1. Information We Collect
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    1.1 Personal Information
                                </h6>
                                <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                    When you use our services, we may collect the following personal information:
                                </p>
                                <ul>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Name:
                                        </span>
                                        <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                            To personalize your experience.
                                        </span>
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Email Address:
                                        </span>
                                        <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                            For account creation, communication, and support.
                                        </span>
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Payment Information:
                                        </span>
                                        <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                            For processing transactions securely.
                                        </span>
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Uploaded Images:
                                        </span>
                                        <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                            To generate headshots using our AI technology.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    1.2 Non-Personal Information
                                </h6>
                                <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                    We may also collect non-personal information automatically when you interact with our services, including:
                                </p>
                                <ul>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Device Information:
                                        </span>
                                        <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                            Type of device, operating system, and browser used.
                                        </span>
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Usage Data:
                                        </span>
                                        <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                            Pages visited, time spent on the site, and other usage metrics.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                2. How We Use Your Information
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    2.1 Providing Services
                                </h6>
                                <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                    We use the information we collect to:
                                </p>
                                <ul>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Generate AI headshots based on your uploaded images.
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Process payments and manage your account.
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Communicate with you about your orders, updates, and support requests.
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    2.2 Improving Services
                                </h6>
                                <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                    We analyze usage data to:
                                </p>
                                <ul>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Enhance the performance and functionality of our services.
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Develop new features and improve existing ones.
                                    </li>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        Understand user preferences and improve user experience.
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    2.3 Legal Compliance
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    We may use your information to comply with legal obligations, enforce our terms of service, and protect the rights, property, or safety of JustHeadshots.ai, our users, and the public.
                                </p>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-8 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                3. Data Sharing and Disclosure
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    3.1 Third-Party Service Providers
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    We may share your information with trusted third-party service providers who assist us in operating our services, such as payment processors and cloud storage providers. These providers are contractually obligated to maintain the confidentiality and security of your information.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    3.2 Legal Requirements
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
                                </p>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                4. Data Security
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                We implement robust security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and secure server infrastructure.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                5. Data Retention
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. After that, we will securely delete or anonymize your information.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-8 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                6. Your Rights
                            </h5>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    6.1 Access and Correction
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    You have the right to access and correct your personal information. You can do this by logging into your account or contacting us directly.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    6.2 Deletion
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    You have the right to request the deletion of your personal information. Please note that we may retain certain information as required by law or for legitimate business purposes.
                                </p>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <h6 className="font-[Sora] text-[23px] leading-[28px] font-bold text-text tracking-[-0.02em] mobile:text-[18px] mobile:leading-[22px] mobile:font-semibold">
                                    6.3 Opt-Out
                                </h6>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    You can opt-out of receiving marketing communications from us by following the unsubscribe instructions provided in our emails or contacting us directly.
                                </p>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                7. Changes to This Privacy Policy
                            </h5>
                            <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of our services after the changes become effective constitutes your acceptance of the new Privacy Policy.
                            </p>
                        </div>
                        <div className="h-[1px] w-full bg-inputBorder"></div>
                        <div className="flex flex-col gap-6 w-full">
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                                8. Contact Us
                            </h5>
                            <div className="flex flex-col gap-2">
                                <p className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] font-semibold text-text mobile:text-[14px] mobile:leading-[17px]">
                                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                                </p>
                                <ul>
                                    <li className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] list-disc ms-6">
                                        <span className="text-base font-semibold text-text me-2 mobile:text-[14px] mobile:leading-[17px]">
                                            Email:
                                        </span>
                                        <span className="text-base text-button">
                                            support@justheadshots.ai
                                        </span>
                                    </li>
                                </ul>
                                <p className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px]">
                                    Thank you for choosing JustHeadshots.ai! We are dedicated to protecting your privacy and providing a secure and enjoyable experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer landing={false} />
            </div>
        </div>
    )
}

export default PrivacyPolicy