import { FC } from "react"

import { GenerationItem } from "../../utils/types"
import { useAppSelector } from "../../redux/hooks"

type AlbumItemImagesProps = {
    album: GenerationItem
}

const AlbumItemImages: FC<AlbumItemImagesProps> = ({ album }) => {
    const theme = useAppSelector((state) => state.app.theme)

    return (
        <div className="flex relative max-w-[145px] w-full mobile:max-w-[42px] mobile:me-[10px] h-full mobile:max-h-[56px]">
            {album.completed || album?.additional_generation === 'pending' ? (
                <>
                    {album?.images?.map((image, index) => (
                        <div key={index} className="album-image-wrapper">
                            <img
                                className="album-image"
                                key={index}
                                src={image.image}
                                alt=""
                            />
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {theme === 'light' ? (
                        <>
                            <div className="album-image-wrapper bg-[#BFBFBF]"></div>
                            <div className="album-image-wrapper bg-[#D9D9D9]"></div>
                            <div className="album-image-wrapper bg-[#E6E6E6]"></div>
                        </>
                    ) : (
                        <>
                            <div className="album-image-wrapper bg-[#404040]"></div>
                            <div className="album-image-wrapper bg-[#404040]"></div>
                            <div className="album-image-wrapper bg-[#333333]"></div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default AlbumItemImages