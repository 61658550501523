import { useNavigate } from "react-router-dom"

import Header from "../components/Header"

import CreationStartedSvg from "../assets/creation/started.svg"

const CreationStarted = () => {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col w-full min-h-screen">
            <Header type="default" />
            <div className="dashboard-background-image w-full min-h-[calc(100vh-68px)] flex items-center justify-center flex-col gap-6 py-10 px-6">
                <div className="flex flex-col gap-[10px] items-center">
                    <img className="mb-[30px] mobile:w-[240px]" src={CreationStartedSvg} alt="" />
                    <h5 className="font-[Sora] text-[32px] text-text leading-[40px] tracking-[-0.02em] font-bold text-center mobile:text-[23px] mobile:leading-[28px]">
                        Great, now it’s our turn!
                    </h5>
                    <p className="text-text text-[20px] leading-[28px] text-center mobile:text-base">
                        Check back in 2 hours to see your awesome AI generated images.
                    </p>
                </div>
                <button onClick={() => navigate('/')} className="bg-button hover:bg-buttonHover transition-colors text-white h-[56px] px-6 rounded-lg font-semibold font-[Inter] text-base mobile:h-[44px] mobile:w-[calc(100%-32px)] mobile:absolute mobile:bottom-4 mobile:left-4">
                    Continue to Dashboard
                </button>
            </div>
        </div >
    )
}

export default CreationStarted