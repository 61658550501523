import { useEffect } from "react"

import { useGetGenerationsQuery } from "../api/appApi"
import { useAppSelector } from "../redux/hooks"

import CreateHeader from "../components/Dashboard/CreateHeader"
import EmptyState from "../components/Dashboard/EmptyState"
import AlbumsList from "../components/Dashboard/AlbumsList"
import Header from "../components/Header"

const Dashboard = () => {
    const subscription = useAppSelector((state) => state.app.subscription)

    const { data: generations, refetch } = useGetGenerationsQuery(undefined, { skip: !subscription })

    useEffect(() => {
        let timeout: NodeJS.Timeout

        if (generations?.some((generation) => !generation.is_draft && !generation.completed)) {
            timeout = setTimeout(() => {
                refetch()
            }, 100000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [generations, refetch])

    return (
        <div className="flex flex-col">
            <Header />
            <div className={`dashboard-background-image h-[calc(100vh-68px)] overflow-y-auto flex flex-col items-center gap-6 px-6 pb-[60px] mobile:h-[calc(100vh-56px)] mobile:p-0 mobile:justify-start mobile:items-start mobile:gap-4 ${generations && generations.length ? 'mobile:py-6 mobile:px-4 pt-[120px]' : 'mobile:p-0 pt-[80px]'}`}>
                {generations && generations.length ? (
                    <>
                        <CreateHeader />
                        <AlbumsList albums={generations} />
                    </>
                ) : (
                    <EmptyState />
                )}
            </div>
        </div>
    )
}

export default Dashboard