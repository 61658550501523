import { useAlbumContext } from '../../context/AlbumContext'

import HeartSecondaryColor from '../../assets/heart-secondary-color.svg'
import AllPhotosDefault from '../../assets/all-photos-default.svg'
import AllPhotosActive from '../../assets/all-photos-active.svg'
import HeartBlueFilled from '../../assets/heart-blue-filled.svg'

const SwitchMobile = () => {
    const albumCtx = useAlbumContext()

    return (
        <div className="hidden mobile:grid grid-cols-2 w-full">
            <div onClick={() => albumCtx?.setShowAll(true)} className={`w-full h-[44px] flex items-start pt-2 justify-center gap-2 border-solid text-base font-semibold ${albumCtx?.showAll ? 'border-b-[3px] border-button text-button' : 'border-b-[1px] border-inputBorder text-secondaryText'}`}>
                <div className='flex items-center w-full justify-center gap-2'>
                    {albumCtx?.showAll ? (
                        <img src={AllPhotosActive} alt="" />
                    ) : (
                        <img src={AllPhotosDefault} alt="" />
                    )}
                    All Photos
                </div>
            </div>
            <div onClick={() => albumCtx?.setShowAll(false)} className={`w-full h-[44px] flex items-start pt-2 justify-center gap-2 border-solid text-base font-semibold ${!albumCtx?.showAll ? 'border-b-[3px] border-button text-button' : 'border-b-[1px] border-inputBorder text-secondaryText'}`}>
                <div className='flex items-center w-full justify-center gap-2'>
                    {!albumCtx?.showAll ? (
                        <img src={HeartBlueFilled} alt="" />
                    ) : (
                        <img src={HeartSecondaryColor} alt="" />
                    )}
                    Favorites
                    {albumCtx?.favorites && albumCtx?.favorites.length > 0 && (
                        <div className='bg-redButton text-white h-[16px] w-[16px] rounded-full flex items-center justify-center text-[12px] leading-[18px]'>
                            {albumCtx?.favorites.length}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SwitchMobile