import { useParams, useSearchParams } from "react-router-dom"
import { Fragment, useEffect } from "react"

import { BACKGROUND_IMAGES, BACKGROUND_TO_KEY, BACKGROUNDS, PLANS } from "../../utils/constants"
import { gtag_report_conversion, uet_report_conversion } from "../../utils/conversions";
import { useCreationContext } from "../../context/CreationContext"
import { useSaveStripePaymentMutation } from "../../api/appApi";
import useElementScrolled from "../../hooks/useElementScrolled";
import useViewport from "../../hooks/useViewport";

import ImagePlaceholder from '../../assets/image-placeholder.svg'

type BackgroundCategory = keyof typeof BACKGROUND_TO_KEY;
type BackgroundStyle = keyof (typeof BACKGROUND_TO_KEY)[BackgroundCategory];

const BackgroundStylesStep = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const ctx = useCreationContext()

    const { id } = useParams()
    const { isMobile } = useViewport()
    const { isScrolled } = useElementScrolled({
        elementRef: ctx?.contentRef,
        eventScrollPosition: 40
    })

    const [saveStripePayment] = useSaveStripePaymentMutation()

    const handleSelectStyle = (type: string, style: string) => {
        const styleType = BACKGROUND_TO_KEY[type as keyof typeof BACKGROUND_TO_KEY]
        const styleKey: string = styleType[style as keyof typeof styleType]

        if (ctx?.state.backgroundStyles.split(',').includes(styleKey)) {
            const filteredStyles = ctx?.state.backgroundStyles.split(',').filter((style) => style !== styleKey)

            ctx?.setState({ ...ctx.state, backgroundStyles: filteredStyles.length > 0 ? filteredStyles.join(',') : '' })

            if (!id) return

            ctx?.syncData({
                generation_id: id,
                field: 'styles',
                value: filteredStyles?.length > 0
                    ? `${filteredStyles.join(',')}${ctx?.state.outfitStyles ? `,${ctx?.state.outfitStyles}` : ''}`
                    : `${ctx?.state.outfitStyles}`
            })
        } else if (ctx?.state && ctx?.state.backgroundStyles.split(',').length < ctx?.stylesAvailable) {
            if (!ctx?.state.backgroundStyles) {
                ctx?.setState({ ...ctx.state, backgroundStyles: styleKey })

                if (!id) return

                ctx?.syncData({
                    generation_id: id,
                    field: 'styles',
                    value: `${styleKey}${ctx?.state.outfitStyles ? `,${ctx?.state.outfitStyles}` : ''}`
                })
            } else {
                ctx?.setState({ ...ctx.state, backgroundStyles: `${ctx.state.backgroundStyles},${styleKey}` })

                if (!id) return

                ctx?.syncData({
                    generation_id: id,
                    field: 'styles',
                    value: `${ctx.state.backgroundStyles},${styleKey}${ctx?.state.outfitStyles ? `,${ctx?.state.outfitStyles}` : ''}`
                })
            }
        }
    }

    useEffect(() => {
        const payment_intent = searchParams.get('payment_intent')
        const planName = searchParams.get('planName')

        if (!payment_intent || !planName) return;

        const plan = PLANS.find((p) => p.name === planName)

        if (!plan) return;

        gtag_report_conversion(plan?.price, payment_intent || '')
        uet_report_conversion(plan?.price)

        saveStripePayment(payment_intent)
        setSearchParams({})
    }, [saveStripePayment, searchParams, setSearchParams])

    return (
        <div className="flex flex-col items-start gap-8 max-w-[960px] h-full w-full mobile:gap-4 mobile:pt-16">
            <div className={`${isScrolled && !isMobile ? 'w-full fixed top-[68px] p-8 left-0 flex items-center justify-center bg-background shadow-md' : 'w-full flex items-center justify-center'}`}>
                <div className={`flex items-center justify-between w-full gap-4 mobile:bg-background mobile:fixed mobile:top-[56px] mobile:left-0 mobile:p-4 mobile:border-solid mobile:border-b-[1px] mobile:border-inputBorder max-w-[960px] mobile:items-start`}>
                    <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] text-text font-bold mobile:text-[23px] mobile:leading-[28px]">
                        Select up to {ctx?.stylesAvailable} background styles.
                    </h5>
                    <div className={`h-[24px] rounded-lg px-2 flex items-center justify-center text-[12px] leading-[18px] font-semibold ${ctx?.state?.backgroundStyles ? 'bg-lightBlueBackground text-button' : 'bg-inputBackground text-secondaryText'}`}>
                        {ctx?.state?.backgroundStyles ? ctx?.state?.backgroundStyles.split(',').length : 0}/{ctx?.stylesAvailable}
                    </div>
                </div>
            </div>
            {Object.keys(BACKGROUNDS).map((key, index) => {
                return (
                    <Fragment key={key}>
                        <div
                            className={`h-[1px] min-h-[1px] w-full bg-inputBorder ${index === 0 && isMobile ? 'hidden' : ''}`}
                        ></div>
                        <div className="flex flex-col w-full gap-4 last:pb-20 mobile:last:pb-6">
                            <h5 className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] text-text font-semibold mobile:text-[19px] mobile:leading-[23px]">
                                {key}
                            </h5>
                            <div className="grid grid-cols-[repeat(auto-fill,minmax(186px,1fr))] mobile:grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-3">
                                {BACKGROUNDS[key as keyof typeof BACKGROUNDS].map((style) => (
                                    <div
                                        className={`style-item-block ${ctx?.state.backgroundStyles && ctx?.state.backgroundStyles.split(',').some((el) => el === BACKGROUND_TO_KEY[key as BackgroundCategory][style as BackgroundStyle]) ? 'selected' : ''}`}
                                        onClick={() => handleSelectStyle(key, style)}
                                        key={style}
                                    >
                                        <img
                                            className="w-full"
                                            src={
                                                BACKGROUND_IMAGES[style as keyof typeof BACKGROUND_IMAGES]?.[ctx?.state.gender || 'male']
                                                || ImagePlaceholder
                                            }
                                            alt=""
                                        />
                                        <span className="text-center break-words">
                                            {style}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default BackgroundStylesStep