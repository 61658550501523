const LoadingStep = () => {
    return (
        <div className="w-full h-full flex flex-col items-center justify-center relative">
            <div className="flex w-full items-end justify-center absolute top-1/2 left-1/2 translate-x-[calc(-50%-80px)] translate-y-[calc(-50%-50px)] mobile:translate-x-[calc(-50%-40px)] mobile:translate-y-[calc(-50%-30px)]">
                <div className="preparing-model-animation-container">
                    <div className="preparing-model-animation-item"></div>
                    <div className="preparing-model-animation-item"></div>
                    <div className="preparing-model-animation-item"></div>
                    <div className="preparing-model-animation-item"></div>
                </div>
            </div>
            <div className="flex flex-col gap-3 items-center mt-10">
                <h5 className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] font-bold text-text mobile:text-[19px] mobile:leading-[23px] mobile:text-center">
                    Prepping our AI model based on your responses
                    <span className="dots-animation-container">
                        <span className="dots-animation-item"></span>
                        <span className="dots-animation-item"></span>
                        <span className="dots-animation-item"></span>
                    </span>
                </h5>
                <span className="text-base text-secondaryText mobile:hidden">
                    Hang tight, this will only take a moment!
                </span>
            </div>
        </div>
    )
}

export default LoadingStep