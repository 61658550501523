import { createSlice } from '@reduxjs/toolkit'

import { FirebaseUser, Subscription, ThemeChangePayload } from '../utils/types'
import { appApi } from '../api/appApi'

type TAppState = {
    theme: 'light' | 'dark',
    user: FirebaseUser | null,
    subscription: Subscription | null
}

const initialState: TAppState = {
    theme: 'light',
    user: null,
    subscription: null
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        signOut: (state) => {
            state.user = null
            state.subscription = null
            state.theme = 'light'
        },
        setTheme: (state, { payload }: ThemeChangePayload) => {
            state.theme = payload
        },
        setUser: (state, { payload }: { payload: FirebaseUser | null }) => {
            state.user = payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            appApi.endpoints.getUserSubscription.matchFulfilled,
            (state, { payload }) => {
                state.subscription = payload
                state.theme = payload.theme
            }
        )
    }
})

export const { signOut, setTheme, setUser } = appSlice.actions

export default appSlice.reducer