import { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import type { DropTargetMonitor } from 'react-dnd'

import { useCreationContext } from '../../context/CreationContext'
import { useAppSelector } from '../../redux/hooks'

import DropToUpload from "../../assets/drop-to-upload.svg"
import UploadWhite from "../../assets/upload-white.svg"
import UploadBlack from "../../assets/upload-black.svg"
import Upload from "../../assets/upload.svg"

const TargetBox = () => {
    const theme = useAppSelector((state) => state.app.theme)

    const inputRef = useRef<HTMLInputElement>(null)

    const ctx = useCreationContext()

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop(item: { files: File[] }) {
                if (handleFileDrop) {
                    handleFileDrop(item)
                }
            },
            canDrop() {
                return true
            },
            collect: (monitor: DropTargetMonitor) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                }
            },
        }),
        [],
    )

    const isActive = canDrop && isOver

    const handleUploadImages = () => {
        inputRef.current?.click()
    }

    const handleFileDrop = (item: { files: File[] }) => {
        ctx?.setUploadedImages(item.files)
    }

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files

        if (files) {
            ctx?.setUploadedImages(Array.from(files))
        }
    }

    return (
        <>
            {
                isActive ? (
                    <div className="flex flex-col gap-3 border-dashed border-[1px] border-button w-full justify-center items-center py-20 rounded-xl h-[363px] bg-inputTransparentBackgroundNoOpacity mobile:rounded-none mobile:border-none" ref={drop}>
                        <img src={DropToUpload} alt="" />
                        <h5 className="text-text text-[19px] leading-[23px] tracking-[-0.02em] font-semibold">
                            Drop to Upload
                        </h5>
                    </div>
                ) : (
                    <div className="flex flex-col gap-6 border-solid border-[1px] border-inputBorder w-full justify-center items-center rounded-xl h-[363px] mobile:rounded-none mobile:border-none mobile:h-full mobile:p-4" ref={drop}>
                        <div className="flex flex-col gap-3 w-full items-center mobile:justify-between mobile:h-full">
                            <div className="hidden mobile:block"></div>
                            <div className="flex flex-col gap-3 items-center w-full">
                                <img width={44} src={Upload} alt="" />
                                <h5 className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] text-text font-semibold">
                                    Upload Photos
                                </h5>
                                <p className="text-base text-secondaryText text-center">
                                    Please upload a minimum of 6 photos to generate results.
                                </p>
                            </div>
                            <button onClick={handleUploadImages} className="bg-text flex items-center justify-center gap-2 h-[44px] p-4 rounded-lg font-[Inter] font-semibold text-base text-background hover:bg-secondaryText transition-colors mobile:w-full">
                                {theme === 'light' ? (
                                    <img className="hidden mobile:block" src={UploadWhite} alt="" />
                                ) : (
                                    <img className="hidden mobile:block" src={UploadBlack} alt="" />
                                )}
                                Upload
                            </button>
                            <span className="text-secondaryText text-[14px] leading-[17px] text-center mobile:hidden">
                                or drag and drop
                            </span>
                            <input
                                multiple
                                type="file"
                                ref={inputRef}
                                className="hidden"
                                accept='image/*'
                                onChange={(e) => handleFileUpload(e)}
                            />
                        </div>
                    </div>
                )}
        </>
    )
}

export default TargetBox
