import { FC, useState } from "react"

import { GenerationUploadedImageItem } from "../../utils/types"
import { useAlbumContext } from "../../context/AlbumContext"
import { useAppSelector } from "../../redux/hooks"

import ArrowTop from '../../assets/arrow-top.svg'

type PhotosUploadedProps = {
    imagesForGeneration: GenerationUploadedImageItem[]
}

const PhotosUploaded: FC<PhotosUploadedProps> = ({ imagesForGeneration }) => {
    const [expanded, setExpanded] = useState(false)
    const theme = useAppSelector((state) => state.app.theme)

    const albumCtx = useAlbumContext()

    return (
        <div className={`w-[360px] min-w-[360px] pr-12 mobile:p-0 mobile:w-full mobile:fixed mobile:left-0 z-[14] ${albumCtx?.additionalGenerationStatus === 'pending' ? 'mobile:hidden' : ''} ${albumCtx?.additionalGenerationStatus === 'completed' ? 'mobile:bottom-0' : 'mobile:bottom-[66px]'}`}>
            <div className="border-solid border-[1px] border-inputBorder rounded-xl w-full overflow-hidden mobile:rounded-t-lg mobile:rounded-b-none mobile:border-x-0">
                <div onClick={() => setExpanded(!expanded)} className={`w-full h-[56px] p-4 border-solid border-inputBorder flex items-center justify-between cursor-pointer hover:bg-inputBackground transition-colors mobile:h-[44px] bg-background ${expanded ? 'border-b-[1px]' : ' border-none'}`}>
                    <h5 className="text-base text-text font-semibold flex items-center justify-center gap-2">
                        Photos Uploaded
                        <div className="h-[24px] px-2 border-solid border-[1px] border-inputBorder flex items-center justify-center rounded-lg text-secondaryText text-[12px] leading-[18px]">
                            {imagesForGeneration.length}
                        </div>
                    </h5>
                    <img className={`${expanded ? '' : 'rotate-180'} ${theme === 'dark' ? 'icon-to-white' : ''}`} src={ArrowTop} alt="" />
                </div>
                {expanded && (
                    <div className="grid grid-cols-[repeat(3,1fr)] gap-2 p-4 mobile:bg-background mobile:max-h-[80vh] overflow-y-auto custom-scroll">
                        {imagesForGeneration.map((image, index) => (
                            <div key={index} className="w-full min-h-[115px] rounded-lg overflow-hidden image-ascept-ratio">
                                <img className="object-cover h-full w-full" src={image.image} alt="" />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default PhotosUploaded