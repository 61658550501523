import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { useCreationContext } from "../../context/CreationContext"
import { useAppSelector } from "../../redux/hooks"
import { GenerationItem } from "../../utils/types"
import useViewport from "../../hooks/useViewport"

import AlbumItemMobileStatus from "../AlbumItem/AlbumItemMobileStatus"
import AlbumItemImages from "../AlbumItem/AlbumItemImages"
import AlbumItemStatus from "../AlbumItem/AlbumItemStatus"
import AlbumItemInfo from "../AlbumItem/AlbumItemInfo"
import AlbumItemTags from "../AlbumItem/AlbumItemTags"

import ArrowRightDarkGrey from '../../assets/arrow-right-dark-grey.svg'
import ArrowRightDark from '../../assets/arrow-right-dark.svg'
import ArrowRight from '../../assets/arrow-right.svg'

type AlbumProps = {
    album: GenerationItem
}

const AlbumItem: FC<AlbumProps> = ({ album }) => {
    const theme = useAppSelector((state) => state.app.theme)

    const creationCtx = useCreationContext()

    const navigate = useNavigate()

    const { isMobile } = useViewport()

    const handleNavigateToAlbum = async () => {
        if (album.is_draft) {
            creationCtx?.clearData()
            creationCtx?.setId(album.id)

            navigate(`/creation/${album.id}`)
        } else if (album.completed || album.additional_generation === 'pending') {
            navigate(`/album/${album.id}`)
        }
    }

    return (
        <div
            onClick={handleNavigateToAlbum}
            className={`w-full rounded-lg border-solid border-[1px] border-inputBorder p-6 flex gap-6 transition-colors generation-block mobile:p-4 mobile:gap-3 mobile:flex-col bg-background ${!album.completed && !album.is_draft ? 'hover:bg-transparent no-hover-album' : 'hover:bg-inputBackground cursor-pointer'}`}
        >
            {isMobile ? (
                <>
                    <div className="flex items-center justify-between gap-4 w-full">
                        <div className="flex flex-col gap-2">
                            <AlbumItemInfo album={album} />
                            <AlbumItemTags album={album} />
                        </div>
                        <AlbumItemImages album={album} />
                    </div>
                    <AlbumItemMobileStatus album={album} />
                </>
            ) : (
                <>
                    <AlbumItemImages album={album} />
                    <div className="flex items-center justify-between gap-4 w-full">
                        <div className="flex flex-col gap-4">
                            <AlbumItemInfo album={album} />
                            <AlbumItemTags album={album} />
                        </div>
                        <AlbumItemStatus album={album} />
                    </div>
                    <img
                        className={`album-item-arrow-hover min-w-[36px] cursor-pointer mobile:hidden ${theme === 'light' ? '' : 'icon-to-white'}`}
                        width={36}
                        src={ArrowRightDark}
                        alt=""
                    />
                    <img
                        className="album-item-arrow min-w-[36px] cursor-pointer mobile:hidden"
                        width={36}
                        src={theme === 'light' ? ArrowRight : ArrowRightDarkGrey}
                        alt=""
                    />
                </>
            )}
        </div>
    )
}

export default AlbumItem