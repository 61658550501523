import { useState } from "react"

import EnterNewPassword from "../components/ResetPassword/EnterNewPassword"
import PasswordChanged from "../components/ResetPassword/PasswordChanged"
import EnterEmail from "../components/ResetPassword/EnterEmail"
import EnterCode from "../components/ResetPassword/EnterCode"
import Header from "../components/Header"

const ResetPassword = () => {
    const [email, setEmail] = useState<string>('')
    const [step, setStep] = useState<number>(1)

    return (
        <div className="w-full flex flex-col h-[100dvh]">
            <Header type="unauthorized" />
            <div className="reset-password-background h-[calc(100vh-60px)] overflow-y-auto custom-scroll flex items-center justify-center mobile:h-screen">
                {step === 1 ? (
                    <EnterEmail
                        setEmail={setEmail}
                        setStep={setStep}
                        email={email}
                    />
                ) : step === 2 ? (
                    <EnterCode
                        setStep={setStep}
                        email={email}
                    />
                ) : step === 3 ? (
                    <EnterNewPassword
                        setStep={setStep}
                        email={email}
                    />
                ) : step === 4 ? (
                    <PasswordChanged />
                ) : null}
            </div>
        </div>
    )
}

export default ResetPassword