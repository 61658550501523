import { FC, useState } from "react"

import { useAlbumContext } from "../../context/AlbumContext"
import { GenerationResultImage } from "../../utils/types"
import { useAppSelector } from "../../redux/hooks"
import useViewport from "../../hooks/useViewport"

import HeartWhiteFilled from '../../assets/heart-white-filled.svg'
import CheckSquare from '../../assets/creation/check-square.svg'
import HeartRedFilled from '../../assets/heart-red-filled.svg'
import DownloadWhite from '../../assets/download-white.svg'
import DownloadBlack from '../../assets/download-black.svg'
import Heart from '../../assets/heart.svg'
import Dots from '../../assets/dots.svg'

type AlbumImageProps = {
    image: GenerationResultImage,
}

const AlbumImage: FC<AlbumImageProps> = ({ image }) => {
    const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false)
    const theme = useAppSelector(state => state.app.theme)

    const albumCtx = useAlbumContext()

    const { isMobile } = useViewport()

    const handleDownload = () => {
        albumCtx?.downloadImage(image)
    }

    const onMobileImageDownload = () => {
        albumCtx?.downloadImage(image)
        setShowMobileOptions(false)
    }

    const onMobileFavoriteUpdate = () => {
        albumCtx?.handleUpdateFavorite(image.id)
        setShowMobileOptions(false)
    }

    return (
        <div
            className={`rounded-lg overflow-hidden cursor-pointer relative uploaded-image min-h-[260px] mobile:min-h-[145px] image-ascept-ratio h-max ${albumCtx?.selectedImages.includes(image.id) ? "outline outline-[4px] outline-button outline-offset-[-4px]" : ""}`}
            key={image.id}
        >
            <div onClick={() => albumCtx?.handleOpenPreview(image.id)} className="uploaded-image-hover-top-layer"></div>
            <img
                onClick={() => albumCtx?.handleOpenPreview(image.id)}
                className="object-cover h-full w-full"
                src={image.image}
                alt=""
            />
            {image.favorite && (
                <img
                    className="absolute left-3 top-3 z-10 mobile:left-2 mobile:top-2"
                    src={HeartRedFilled}
                    alt=""
                />
            )}
            {showMobileOptions && isMobile && (
                <div className="absolute top-0 left-0 h-full w-full p-2 z-[11] flex flex-col gap-1">
                    <button onClick={onMobileImageDownload} className="bg-text hover:bg-secondaryText transition-colors w-full h-[42px] rounded-[10px] flex items-center justify-center">
                        <img
                            className={theme === 'light' ? 'icon-to-white' : ''}
                            src={DownloadBlack}
                            alt=""
                        />
                    </button>
                    {image.favorite ? (
                        <button onClick={onMobileFavoriteUpdate} className="bg-redButton hover:bg-redButtonHover transition-colors h-[42px] rounded-[10px] flex items-center justify-center w-full">
                            <img src={HeartWhiteFilled} alt="" />
                        </button>
                    ) : (
                        <button onClick={onMobileFavoriteUpdate} className="bg-background hover:bg-secondaryText transition-colors h-[42px] rounded-[10px] flex items-center justify-center">
                            <img className={theme === 'light' ? '' : 'icon-to-white'} src={Heart} alt="" />
                        </button>
                    )}
                </div>
            )}
            {albumCtx?.selectedImages.includes(image.id) ? (
                <>
                    <img
                        onClick={() => albumCtx?.handleSelectImage(image.id)}
                        className="absolute top-3 right-3 z-10 w-[24px] mobile:w-[18px] mobile:rounded-full mobile:top-2 mobile:right-2 increase-clickable-area"
                        src={CheckSquare}
                        alt=""
                    />
                    <div onClick={() => setShowMobileOptions(!showMobileOptions)} className={`hidden mobile:flex absolute bottom-2 right-2 w-[32px] h-[32px] rounded-lg bg-white hover:bg-secondaryText transition-colors items-center justify-center z-[12] increase-clickable-area ${showMobileOptions ? 'mobile:bg-secondaryText' : 'mobile:hover:bg-white'}`}>
                        <img width={18} src={Dots} alt="" />
                    </div>
                    <div className="uploaded-image-checkbox flex w-full items-center gap-2 absolute bottom-3 px-3 z-10 mobile-hidden">
                        <button onClick={() => albumCtx?.handleUpdateFavorite(image.id)} className={`w-1/2 flex items-center justify-center h-[44px] rounded-lg transition-colors ${image.favorite ? 'bg-redButton hover:bg-redButtonHover' : 'bg-background hover:bg-secondaryText'}`}>
                            <img
                                className={`${theme === 'light' ? '' : 'icon-to-white'}`}
                                src={image.favorite ? HeartWhiteFilled : Heart}
                                alt=""
                            />
                        </button>
                        <button onClick={() => handleDownload()} className="w-1/2 flex items-center justify-center h-[44px] rounded-lg bg-text hover:bg-secondaryText transition-colors">
                            <img src={theme === 'light' ? DownloadWhite : DownloadBlack} alt="" />
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div onClick={() => setShowMobileOptions(!showMobileOptions)} className={`hidden mobile:flex absolute bottom-2 right-2 w-[32px] h-[32px] rounded-lg bg-white hover:bg-secondaryText transition-colors items-center justify-center z-[12] increase-clickable-area ${showMobileOptions ? 'mobile:bg-secondaryText' : 'mobile:hover:bg-white'}`}>
                        <img width={18} src={Dots} alt="" />
                    </div>
                    <div onClick={() => albumCtx?.handleSelectImage(image.id)} className="uploaded-image-checkbox w-[24px] h-[24px] rounded-[4px] border-solid border-[2px] border-white absolute right-3 top-3 z-10 mobile:top-2 mobile:right-2 mobile:rounded-full mobile:w-[18px] mobile:h-[18px] increase-clickable-area"></div>
                    <div className="uploaded-image-checkbox flex w-full items-center gap-2 absolute bottom-3 px-3 z-10 mobile-hidden">
                        <button onClick={() => albumCtx?.handleUpdateFavorite(image.id)} className={`w-1/2 flex items-center justify-center h-[44px] rounded-lg transition-colors ${image.favorite ? 'bg-redButton hover:bg-redButtonHover' : 'bg-background hover:bg-secondaryText'}`}>
                            <img
                                className={`${theme === 'light' ? '' : 'icon-to-white'}`}
                                src={image.favorite ? HeartWhiteFilled : Heart}
                                alt=""
                            />
                        </button>
                        <button onClick={() => handleDownload()} className="w-1/2 flex items-center justify-center h-[44px] rounded-lg bg-text hover:bg-secondaryText transition-colors">
                            <img src={theme === 'light' ? DownloadWhite : DownloadBlack} alt="" />
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

export default AlbumImage