import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { FC } from "react"

import { useSendContactUsEmailMutation } from "../../api/appApi"
import { useAppSelector } from "../../redux/hooks"

import LogoBlack from "../../assets/logo-black.svg"
import LogoWhite from "../../assets/logo-white.svg"
import { toast } from "react-toastify"

export type TContactUsData = {
    email: string,
    message: string,
}

type TFooterProps = {
    landing?: boolean,
}

const Footer: FC<TFooterProps> = ({ landing = true }) => {
    const theme = useAppSelector((state) => state.app.theme)

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
    } = useForm<TContactUsData>()

    const [sendEmail] = useSendContactUsEmailMutation()

    const handleGetStarted = () => {
        navigate('/continue-with')
    }

    const handleGoToLogin = () => {
        navigate('/sign-in')
    }

    const handleNavigateToLanding = () => {
        navigate('/')
    }

    const onSendMessage = (data: TContactUsData) => {
        sendEmail(data).then((res) => {
            if ('error' in res) {
                toast.error('Failed to send message')
            } else {
                toast.success('Message sent successfully')
                reset()
            }
        })
    }

    return (
        <div className={`landing-intro-block flex pb-16 flex-col gap-16 mt-[-64px] mobile:gap-8 mobile:pb-8 ${landing ? 'pt-4 mobile:mt-[-16px]' : 'p-16 mobile:p-0 pt-16 mobile:pt-6'}`}>
            <div className={`flex justify-between gap-16 mobile:flex-col-reverse mobile:gap-4 ${landing ? 'mobile:px-0' : 'mobile:px-4'}`}>
                <div className={`flex flex-col gap-6 pt-16 ps-16 w-full mobile:ps-0 mobile:pt-6`}>
                    <div onClick={handleNavigateToLanding} className="flex items-center">
                        <img
                            className="cursor-pointer w-[260px] mobile:w-[222px]"
                            src={theme === 'light' ? LogoBlack : LogoWhite}
                            alt=""
                        />
                    </div>
                    <div className="flex flex-col items-start gap-6">
                        <div className="flex flex-col gap-2">
                            <Link className="text-base font-semibold text-text px-2 h-[40px] rounded-xl hover:bg-inputBackground transition-colors flex items-center justify-start w-fit" to='https://justheadshots.ai'>
                                Home
                            </Link>
                            <Link className="text-base font-semibold text-text px-2 h-[40px] rounded-xl hover:bg-inputBackground transition-colors flex items-center justify-start w-fit" to='/terms-and-conditions'>
                                Terms & Conditions
                            </Link>
                            <Link className="text-base font-semibold text-text px-2 h-[40px] rounded-xl hover:bg-inputBackground transition-colors flex items-center justify-start w-fit" to='/privacy-policy'>
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <button onClick={handleGetStarted} className="h-[44px] rounded-[10px] bg-text text-background hover:bg-secondaryText transition-colors px-4 text-base font-semibold mobile:w-1/2">
                            Get Started
                        </button>
                        <button onClick={handleGoToLogin} className="h-[44px] rounded-[10px] bg-inputBackground hover:bg-inputBorder transition-colors px-4 text-base font-semibold text-text mobile:w-1/2">
                            Sign In
                        </button>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSendMessage)} className="bg-inputBackground p-16 rounded-xl flex flex-col gap-8 w-full mobile:p-6 mobile:gap-6">
                    <h5 className="font-[Sora] text-[28px] leading-[35px] text-text tracking-[-0.02em] font-bold mobile:text-[23px] mobile:leading-[28px]">
                        Reach out to us!
                    </h5>
                    <div className="flex flex-col gap-6 mobile:gap-4">
                        <div className="flex flex-col gap-2 w-full">
                            <label className="text-base text-text font-semibold mobile:text-[14px] mobile:leading-[17px]">
                                Email Address
                            </label>
                            <input
                                type="text"
                                placeholder="Enter email address"
                                className="h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px]"
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Invalid email address",
                                    }
                                })}
                            />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <label className="text-base text-text font-semibold mobile:text-[14px] mobile:leading-[17px]">
                                Message
                            </label>
                            <textarea
                                placeholder="Write your message here..."
                                className="min-h-[112px] rounded-lg border-solid border-[1px] border-inputBorder p-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] mobile:min-h-[88px]"
                                {...register("message", {
                                    required: "Message is required",
                                })}
                            />
                        </div>
                    </div>
                    <button className="h-[56px] rounded-xl px-6 bg-button hover:bg-buttonHover transition-colors text-[18px] leading-[22px] font-semibold text-white mobile:h-[44px] mobile:text-base">
                        Send Message
                    </button>
                </form>
            </div>
            <div className="h-[1px] w-full bg-inputBorder"></div>
            <p className="text-[14px] leading-[17px] text-text w-full text-center mobile:max-w-[340px] mobile:text-center mobile:mx-auto">
                Copyright © 2024 JustHeadshots.ai. All Rights Reserved
            </p>
        </div>
    )
}

export default Footer