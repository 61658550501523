import { useForm } from 'react-hook-form'
import { FC, useState } from 'react'

import { useSetNewPasswordMutation } from '../../api/appApi'
import { useAppSelector } from '../../redux/hooks'

import BackHeader from './BackHeader'

import EyeClose from "../../assets/eye-close.svg"
import Eye from "../../assets/eye.svg"

type TResetPasswordData = {
    password: string,
    confirmPassword: string,
}

type EnterNewPasswordProps = {
    setStep: (step: number) => void,
    email: string,
}

const EnterNewPassword: FC<EnterNewPasswordProps> = ({ setStep, email }) => {
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const theme = useAppSelector((state) => state.app.theme)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TResetPasswordData>()

    const [setNewPassword] = useSetNewPasswordMutation()

    const updatePassword = (data: TResetPasswordData) => {
        if (data.password !== data.confirmPassword) {
            setIsError(true)
            return
        } else {
            setIsError(false)
            setNewPassword({ password: data.password, email })
            setStep(4)
        }
    }

    return (
        <form onSubmit={handleSubmit(updatePassword)} className="max-w-[640px] w-full p-6 flex flex-col gap-6 bg-background rounded-[24px] border-solid border-[1px] border-inputBorder shadow-modal min-h-[404px] justify-between mobile:h-full mobile:rounded-none mobile:p-4">
            <div className="flex flex-col gap-6 w-full">
                <BackHeader title="Reset Password" />
                <div className="h-[1px] w-full bg-inputBorder mobile:hidden"></div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center w-full justify-between gap-2">
                    <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                        New Password
                    </label>
                </div>
                <div className="relative w-full">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="New password (8 characters minimum)"
                        className={`h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base w-full mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] ${errors.password || isError ? 'outline outline-redButton outline-[2px] outline-offset-[-2px]' : ''}`}
                        {...register("password", {
                            required: "Enter a valid password",
                            minLength: 8,
                        })}
                    />
                    {
                        showPassword ? (
                            <img
                                onClick={() => setShowPassword(!showPassword)}
                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                src={Eye}
                                alt=""
                            />
                        ) : (
                            <img
                                onClick={() => setShowPassword(!showPassword)}
                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                src={EyeClose}
                                alt=""
                            />
                        )}
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full mobile:h-full">
                <div className="flex items-center w-full justify-between gap-2">
                    <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                        Confirm Password
                    </label>
                </div>
                <div className="relative w-full">
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm password (8 characters minimum)"
                        className={`h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base w-full mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] ${errors.confirmPassword || isError ? 'outline outline-redButton outline-[2px] outline-offset-[-2px]' : ''}`}
                        {...register("confirmPassword", {
                            required: "Enter a valid password",
                            minLength: 8,
                        })}
                    />
                    {
                        showConfirmPassword ? (
                            <img
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                src={Eye}
                                alt=""
                            />
                        ) : (
                            <img
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                src={EyeClose}
                                alt=""
                            />
                        )}
                </div>
            </div>
            <div className="w-full">
                <button type='submit' className="h-[56px] rounded-xl bg-button hover:bg-buttonHover transition-colors disabled:hover:bg-button disabled:opacity-20 text-[18px] leading-[22px] font-semibold text-white w-full mobile:h-[44px] mobile:text-base">
                    Submit
                </button>
            </div>
        </form>
    )
}

export default EnterNewPassword