import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalButtonsComponentProps } from '@paypal/react-paypal-js';
import { useSearchParams } from 'react-router-dom';
import { FC } from 'react';

import { gtag_report_conversion, uet_report_conversion } from "../utils/conversions";
import { useSavePayPalPaymentMutation } from '../api/appApi';
import { useAppSelector } from '../redux/hooks';

type PayPalButtonProps = {
    price: number,
    planName: string,
}

const PayPalButton: FC<PayPalButtonProps> = ({ price, planName }) => {
    const [searchParams] = useSearchParams()
    const user = useAppSelector(state => state.app.user)

    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID ? process.env.REACT_APP_PAYPAL_CLIENT_ID : '';

    const [savePayPalPayment] = useSavePayPalPaymentMutation()

    const handleCreateOrder: PayPalButtonsComponentProps['createOrder'] = (data, actions) => {
        return actions.order.create({
            intent: 'CAPTURE',
            purchase_units: [{
                amount: {
                    value: price.toFixed(2),
                    currency_code: 'USD',
                },
                custom_id: JSON.stringify({
                    'uid': user?.uid,
                    'plan_name': planName,
                }),
            }],
        });
    }

    const handleApprove: PayPalButtonsComponentProps['onApprove'] = async (data, actions) => {
        if (actions.order) {
            return actions.order.capture().then(async (res) => {
                if (res?.purchase_units && res?.purchase_units[0]?.custom_id) {
                    const customData = JSON.parse(res?.purchase_units[0]?.custom_id)

                    await Promise.all([
                        savePayPalPayment({
                            id: res?.id || '',
                            currency_code: res?.purchase_units[0]?.amount?.currency_code || 'USD',
                            value: Number(res?.purchase_units[0]?.amount?.value) || price,
                            uid: customData.uid,
                            plan_name: customData.plan_name,
                        }),
                        uet_report_conversion(price),
                        gtag_report_conversion(price, res?.id || '')
                    ])
                }

                const generation = searchParams.get('generation')
                const continue_generation = searchParams.get('continue_generation')

                const returnUrl = generation
                    ? `${window.location.origin}/checkout-success?planName=${planName}&generation=${generation}`
                    : continue_generation
                        ? `${window.location.origin}/creation/${continue_generation}`
                        : `${window.location.origin}`

                window.location.href = returnUrl
            });
        } else {
            return Promise.reject(new Error('actions.order is undefined'));
        }
    }

    const handleError = (err: Record<string, unknown>) => {
        console.error(err);
    }

    return (
        <PayPalScriptProvider
            options={{
                "clientId": CLIENT_ID,
                "disable-funding": "card,credit",
                "vault": "true",
                "intent": "capture",
            }}
        >
            <PayPalButtons
                createOrder={handleCreateOrder}
                onApprove={handleApprove}
                onError={handleError}
            />
        </PayPalScriptProvider>
    )
}

export default PayPalButton