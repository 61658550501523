import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect } from "react"

import Header from "../components/Header"

import { gtag_report_conversion, uet_report_conversion } from "../utils/conversions"
import { useSaveStripePaymentMutation } from "../api/appApi"
import { PLANS } from "../utils/constants"

import PlansPaymentSuccess from '../assets/plans/payment-success.svg'

const CheckoutSuccess = () => {
    const [searchParams] = useSearchParams()

    const navigate = useNavigate()

    const [saveStripePayment] = useSaveStripePaymentMutation()

    const handleNavigate = () => {
        const generation = searchParams.get('generation')

        if (generation && generation !== 'null') {
            navigate(`/album/${searchParams.get('generation')}?startGeneration=true`)
        } else {
            navigate('/creation/0')
        }
    }

    useEffect(() => {
        const payment_intent = searchParams.get('payment_intent')

        if (!payment_intent) return;

        saveStripePayment(payment_intent)
    }, [saveStripePayment, searchParams])

    useEffect(() => {
        const planName = searchParams.get('planName')

        if (!planName) return;

        const plan = PLANS.find((p) => p.name === planName)

        if (!plan) return;

        let transactionId = ''

        const transactionIdPayPal = searchParams.get('transactionId')

        if (transactionIdPayPal) {
            transactionId = transactionIdPayPal
        } else {
            const transactionIdStripe = searchParams.get('payment_intent')

            if (transactionIdStripe) {
                transactionId = transactionIdStripe
            }
        }

        uet_report_conversion(plan.price)
        gtag_report_conversion(plan.price, transactionId)
    }, [searchParams])

    return (
        <div className="flex flex-col w-full">
            <Header />
            <div className="dashboard-background-image flex flex-col min-h-[calc(100vh-68px)] justify-center items-center gap-6 p-6">
                <div className="flex flex-col gap-[10px] items-center">
                    <img className="w-[280px] mobile:w-[180px] mobile:mb-[20px]" width={280} src={PlansPaymentSuccess} alt="" />
                    <h5 className="font-[Sora] text-text text-[32px] leading-[40px] tracking-[-0.02em] text-center font-bold mobile:text-[23px] mobile:leading-[28px]">
                        Your payment was successful
                    </h5>
                </div>
                <button
                    onClick={handleNavigate}
                    className="bg-button hover:bg-buttonHover transition-colors text-white h-[56px] px-6 rounded-lg text-base font-semibold mobile:h-[44px] mobile:w-[calc(100%-32px)] mobile:absolute mobile:bottom-4 mobile:left-4"
                >
                    Continue
                </button>
            </div>
        </div>
    )
}

export default CheckoutSuccess