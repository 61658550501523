import {
    useState,
    useRef,
    FC,
    ChangeEvent,
    KeyboardEvent
} from "react";

type InputCodeProps = {
    length: number;
    isError: boolean;
    setIsError: (isError: boolean) => void;
    onComplete: (code: string) => void;
};

const InputCode: FC<InputCodeProps> = ({ length, onComplete, isError, setIsError }) => {
    const [code, setCode] = useState<string[]>([...Array(length)].map(() => ""));
    const inputs = useRef<(HTMLInputElement | null)[]>([]);

    const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
        const num = e.target.value;

        if (/[^0-9]/.test(num)) return;

        const newCode = [...code];
        newCode[slot] = num;
        setCode(newCode);
        setIsError(false);

        if (slot !== length - 1) {
            inputs.current[slot + 1]?.focus();
        }

        if (newCode.every(num => num !== "")) {
            onComplete(newCode.join(""));
        }
    };

    const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, slot: number) => {
        if (e.key === "Backspace" && !code[slot] && slot !== 0) {
            const newCode = [...code];
            newCode[slot - 1] = "";

            setCode(newCode);

            inputs.current[slot - 1]?.focus();
        }
    };

    return (
        <div className="flex items-center gap-2 w-full justify-center">
            {code.map((num, idx) => {
                return (
                    <input
                        key={idx}
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        value={num}
                        placeholder="_"
                        autoFocus={!code[0].length && idx === 0}
                        onChange={e => processInput(e, idx)}
                        onKeyUp={e => onKeyUp(e, idx)}
                        ref={ref => inputs.current.push(ref)}
                        className={`border-[1px] border-solid border-inputBorder h-[56px] w-[56px] rounded-xl text-center placeholder:text-[#191919] text-text placeholder:invisible focus:placeholder:visible ${isError ? "outline outline-[2px] outline-redButton outline-offset-[-2px]" : ""}`}
                    />
                );
            })}
        </div>
    );
};

export default InputCode;
