import { FC } from "react"
import { useNavigate } from "react-router-dom"

import {
    calculatePercentageProgress,
    checkEstimatedTimeLeft,
    convertDateToLocaleString
} from "../utils/functions"
import {
    KEY_TO_BACKGROUND,
    KEY_TO_MAN_OUTFITS,
    KEY_TO_WOMAN_OUTFITS
} from "../utils/constants"
import { useAppSelector } from "../redux/hooks"
import { GenerationItem } from "../utils/types"

import ProgressSpinner from "../assets/progress-spinner.svg"
import CompleteCheck from '../assets/complete-check.svg'

type AlbumProps = {
    album: GenerationItem
}

const AccountDeletionAlbumItem: FC<AlbumProps> = ({ album }) => {
    const theme = useAppSelector((state) => state.app.theme)
    const navigate = useNavigate()

    const handleNavigateToAlbum = () => {
        if (album.is_draft) {
            navigate(`/creation/${album.id}`)
        } else if (album.completed) {
            navigate(`/album/${album.id}`)
        }
    }

    return (
        <div
            onClick={handleNavigateToAlbum}
            className="w-full rounded-lg border-solid border-[1px] border-inputBorder flex cursor-pointer hover:bg-inputBackground transition-colors generation-block p-4 gap-3 flex-col"
        >
            <div className="flex items-center justify-between gap-4 w-full">
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-4">
                        <p className="font-[Sora] text-text text-base font-semibold tracking-normal">
                            {album.title}
                        </p>
                    </div>
                    {(album.name || album.gender || album.ethnicity) && (
                        <div className="flex items-center gap-1 flex-wrap">
                            {album.name && (
                                <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                                    {album.name}
                                </div>
                            )}
                            {album.gender && (
                                <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                                    {album.gender === 'man' ? 'Male' : 'Female'}
                                </div>
                            )}
                            {album.ethnicity && (
                                <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                                    {album.ethnicity}
                                </div>
                            )}
                            {album.styles && album.styles.split(',').map((style, index) => (
                                <div key={index} className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                                    {
                                        KEY_TO_BACKGROUND[style as keyof typeof KEY_TO_BACKGROUND]
                                            ? KEY_TO_BACKGROUND[style as keyof typeof KEY_TO_BACKGROUND].style
                                            : KEY_TO_MAN_OUTFITS[style as keyof typeof KEY_TO_MAN_OUTFITS]
                                                ? KEY_TO_MAN_OUTFITS[style as keyof typeof KEY_TO_MAN_OUTFITS].style
                                                : KEY_TO_WOMAN_OUTFITS[style as keyof typeof KEY_TO_WOMAN_OUTFITS]
                                                    ? KEY_TO_WOMAN_OUTFITS[style as keyof typeof KEY_TO_WOMAN_OUTFITS].style
                                                    : style

                                    }
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex relative w-full max-w-[42px] me-[10px] h-full max-h-[56px]">
                    {album.completed ? (
                        <>
                            {album?.images?.map((image, index) => (
                                <div key={index} className="deletion-account-album-image-wrapper">
                                    <img
                                        className="album-image"
                                        key={index}
                                        src={image.image}
                                        alt=""
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            {theme === 'light' ? (
                                <>
                                    <div className="deletion-account-album-image-wrapper bg-[#BFBFBF]"></div>
                                    <div className="deletion-account-album-image-wrapper bg-[#D9D9D9]"></div>
                                    <div className="deletion-account-album-image-wrapper bg-[#E6E6E6]"></div>
                                </>
                            ) : (
                                <>
                                    <div className="deletion-account-album-image-wrapper bg-[#404040]"></div>
                                    <div className="deletion-account-album-image-wrapper bg-[#404040]"></div>
                                    <div className="deletion-account-album-image-wrapper bg-[#333333]"></div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="flex w-full items-center justify-between gap-2">
                {album.completed || album.is_draft ? (
                    <span className="text-secondaryText text-[12px] leading-[18px]">
                        {album.is_draft ? 'Draft started' : ''} {convertDateToLocaleString(album.created_at)}
                    </span>
                ) : (
                    <div className="bg-inputBackground rounded-full h-[8px] w-full overflow-hidden">
                        <div style={{ width: `${calculatePercentageProgress(album.started_at, album.eta)}%` }} className="bg-button h-full"></div>
                    </div>
                )}
                {album.completed ? (
                    <div
                        className="flex items-center gap-1 px-[10px] h-[24px] rounded-[8px] text-button bg-inputTransparentBackground text-[12px] leading-[18px] mobile:w-fit"
                    >
                        <img src={CompleteCheck} alt="" />
                        Complete
                    </div>
                ) : (
                    <span className="text-end text-secondaryText text-[18px] leading-[22px]">
                        {album.is_draft ? (
                            <div className="h-[24px] rounded-[8px] px-2 bg-[#FF7A001A] flex items-center gap-2 text-[#FF7A00] text-[12px] leading-[18px] font-semibold mobile:w-fit">
                                <img width={14} src={ProgressSpinner} alt="" />
                                In Progress
                            </div>
                        ) : (
                            <div className="text-base text-secondaryText mobile:text-[12px] mobile:leading-[18px] mobile:whitespace-nowrap">
                                {checkEstimatedTimeLeft(album.eta)}
                            </div>
                        )}
                    </span>
                )}
            </div>
        </div>
    )
}

export default AccountDeletionAlbumItem