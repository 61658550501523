import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';

import { useAppSelector } from './redux/hooks';

import TermsAndConditions from './pages/TermsAndConditions';
import CreationStarted from './pages/CreationStarted';
import CheckoutSuccess from './pages/CheckoutSuccess';
import ResetPassword from './pages/ResetPassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContinueWith from './pages/ContinueWith';
import Dashboard from './pages/Dashboard';
import Checkout from './pages/Checkout';
import Creation from './pages/Creation';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Plans from './pages/Plans';
import Album from './pages/Album';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const theme = useAppSelector((state) => state.app.theme)
  const user = useAppSelector((state) => state.app.user)

  useEffect(() => {
    document.querySelector("html")?.setAttribute("data-theme", theme);
  }, [theme])

  return (
    <div className='min-h-screen w-full'>
      {user ? (
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/plans' element={<Plans />} />
          <Route path='/creation/:id' element={<Creation />} />
          <Route path='/checkout/:secret/:plan' element={<Checkout />} />
          <Route path='/checkout-success' element={<CheckoutSuccess />} />
          <Route path='/creation-started' element={<CreationStarted />} />
          <Route path='/album/:id' element={<Album />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      ) : (
        <Routes>
          <Route path='/' element={<ContinueWith />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='*' element={<ContinueWith />} />
        </Routes>
      )}
      <ToastContainer
        autoClose={5000}
        position="bottom-right"
        theme={theme}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
