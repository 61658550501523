import { useNavigate } from 'react-router-dom'
import { FC } from 'react'

import Plus from '../../assets/plus.svg'

type CreateHeaderProps = {}

const CreateHeader: FC<CreateHeaderProps> = () => {
    const navigate = useNavigate()

    const handleCreateGeneration = () => {
        navigate('/creation/0')
    }

    return (
        <div className="w-full flex items-center justify-between gap-4 max-w-[960px]">
            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-.02em] font-bold text-text mobile:text-[23px] mobile:leading-[28px]">
                Albums
            </h5>
            <button
                onClick={handleCreateGeneration}
                className={`flex items-center gap-2 h-[44px] rounded-xl text-text px-4 font-semibold text-[14px] bg-button text-white hover:bg-buttonHover transition-colors mobile:fixed mobile:bottom-4 mobile:left-4 mobile:w-[calc(100%-32px)] mobile:justify-center mobile:z-[4]`}
            >
                <img
                    className={`icon-to-white`}
                    src={Plus}
                    alt=""
                />
                Create New
            </button>
        </div>
    )
}

export default CreateHeader