import { FC } from "react"

import { GenerationItem } from "../../utils/types"
import { KEY_TO_BACKGROUND, KEY_TO_MAN_OUTFITS, KEY_TO_WOMAN_OUTFITS } from "../../utils/constants"

type AlbumItemTagsProps = {
    album: GenerationItem
}

const AlbumItemTags: FC<AlbumItemTagsProps> = ({ album }) => {
    return (
        <>
            {(album.name || album.gender || album.ethnicity) && (
                <div className="flex items-center gap-1 flex-wrap">
                    {album.eye_color && (
                        <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                            {album.eye_color}
                        </div>
                    )}
                    {album.hair_color && (
                        <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                            {album.hair_color}
                        </div>
                    )}
                    {album.age && (
                        <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                            {album.age}
                        </div>
                    )}
                    {album.name && (
                        <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                            {album.name}
                        </div>
                    )}
                    {album.gender && (
                        <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                            {album.gender === 'man' ? 'Male' : 'Female'}
                        </div>
                    )}
                    {album.ethnicity && (
                        <div className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                            {album.ethnicity}
                        </div>
                    )}
                    {album.styles && album.styles.split(',').map((style, index) => (
                        <div key={index} className="generation-tag text-secondaryText font-[Inter] h-[24px] px-[10px] text-[12px] leading-[18px] rounded-[8px] flex items-center justify-center capitalize">
                            {
                                KEY_TO_BACKGROUND[style as keyof typeof KEY_TO_BACKGROUND]
                                    ? KEY_TO_BACKGROUND[style as keyof typeof KEY_TO_BACKGROUND].style
                                    : KEY_TO_MAN_OUTFITS[style as keyof typeof KEY_TO_MAN_OUTFITS]
                                        ? KEY_TO_MAN_OUTFITS[style as keyof typeof KEY_TO_MAN_OUTFITS].style
                                        : KEY_TO_WOMAN_OUTFITS[style as keyof typeof KEY_TO_WOMAN_OUTFITS]
                                            ? KEY_TO_WOMAN_OUTFITS[style as keyof typeof KEY_TO_WOMAN_OUTFITS].style
                                            : style

                            }
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default AlbumItemTags