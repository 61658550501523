import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo } from "react"

import { useCreationContext } from "../context/CreationContext"
import { useCreateGenerationMutation } from "../api/appApi"

import BackgroundStylesStep from "../components/Creation/BackgroundStylesStep"
import UploadImagesStep from "../components/Creation/UploadImagesStep"
import OutfitStylesStep from "../components/Creation/OutfitStylesStep"
import CreationFooter from "../components/Creation/CreationFooter"
import EthnicityStep from "../components/Creation/EthnicityStep"
import HairColorStep from "../components/Creation/HairColorStep"
import EyeColorStep from "../components/Creation/EyeColorStep"
import ProgressBar from "../components/Creation/ProgressBar"
import GenderStep from "../components/Creation/GenderStep"
import ReviewStep from "../components/Creation/ReviewStep"
import AgeStep from "../components/Creation/AgeStep"
import Header from "../components/Header"

const Creation = () => {
    const ctx = useCreationContext()

    const { id } = useParams()

    const navigate = useNavigate()

    const [createGeneration, { isError }] = useCreateGenerationMutation()

    const currentStep = useMemo(() => {
        switch (ctx?.step) {
            case 1: return <GenderStep />
            case 2: return <AgeStep />
            case 3: return <EyeColorStep />
            case 4: return <HairColorStep />
            case 5: return <EthnicityStep />
            case 6: return <UploadImagesStep />
            case 7: return <BackgroundStylesStep />
            case 8: return <OutfitStylesStep />
            case 9: return <ReviewStep />
            default: return <GenderStep />
        }
    }, [ctx?.step])

    useEffect(() => {
        if (id) {
            ctx?.setId(id)
        }
    }, [id, ctx])

    useEffect(() => {
        if (isError) {
            navigate('/')
        }
    }, [isError, navigate])

    useEffect(() => {
        if (id && Number(id) === 0) {
            (async () => {
                const res = await createGeneration()

                if (res.data) {
                    navigate(`/creation/${res.data.id}`)
                }
            })()
        }
    }, [id, createGeneration, navigate])

    return (
        <div className="flex flex-col w-full h-fit">
            <Header type={"creation"} />
            {ctx?.doNotGoToNextStep !== id ? (
                <div className="h-[calc(100vh-70px)] mobile:h-[calc(100dvh-79px)] w-full flex items-center justify-center">
                    <div className="preparing-model-animation-container translate-x-[-80px] mobile:translate-x-[-40px]">
                        <div className="preparing-model-animation-item"></div>
                        <div className="preparing-model-animation-item"></div>
                        <div className="preparing-model-animation-item"></div>
                        <div className="preparing-model-animation-item"></div>
                    </div>
                </div>
            ) : (
                <>
                    <div
                        ref={ctx?.contentRef}
                        className={`dashboard-background-image ${ctx?.step && (ctx?.step > 5 || (ctx?.step <= 5 && ctx?.isEdit)) ? 'h-[calc(100vh-158px)] mobile:h-[calc(100dvh-129px)]' : 'h-[calc(100vh-70px)] mobile:h-[calc(100dvh-79px)]'} w-full flex flex-col gap-20 items-center overflow-y-auto custom-scroll mobile:items-center mobile:gap-6 ${ctx?.step && ctx?.step !== 6 ? `px-6 py-[80px] ${ctx?.step <= 5 ? 'mobile:gap-8 mobile:py-8' : 'mobile:py-6 mobile:px-4'}` : 'mobile:overflow-y-hidden'}`}
                    >
                        {ctx?.step && ctx?.step <= 5 && !ctx?.isPreparingModel && (
                            <ProgressBar />
                        )}
                        {currentStep}
                    </div>
                    {ctx?.step && (ctx?.step > 5 || (ctx?.step <= 5 && ctx?.isEdit)) && (
                        <CreationFooter />
                    )}
                </>
            )}
        </div>
    )
}

export default Creation