import { useNavigate, useSearchParams } from "react-router-dom"

import { useAuthContext } from "../context/AuthContext"
import { useAppSelector } from "../redux/hooks"

import Header from "../components/Header"

import AuthPreview from "../assets/auth-preview.png"
import NoTextLogo from "../assets/no-text-logo.svg"
import Facebook from "../assets/facebook.svg"
import Google from "../assets/google.svg"
import Email from "../assets/email.svg"

const ContinueWith = () => {
    const [searchParams] = useSearchParams()
    const theme = useAppSelector((state) => state.app.theme)

    const navigate = useNavigate()

    const auth = useAuthContext()

    const handleContinueWithEmail = () => {
        navigate(`/sign-up${searchParams.get('plan') ? `?plan=${searchParams.get('plan')}` : ''}`)
    }

    const handleSignIn = () => {
        navigate(`/sign-in${searchParams.get('plan') ? `?plan=${searchParams.get('plan')}` : ''}`)
    }

    return (
        <div className="w-full min-h-[100vh]">
            <Header type="unauthorized" />
            <div className="grid grid-cols-[1fr,1.1fr] h-[calc(100vh-68px)] mobile:h-[100vh] mobile:grid-cols-[1fr]">
                <div className="flex flex-col items-center justify-center gap-[64px] px-6 py-[64px] mobile:p-4">
                    <div className="flex flex-col items-center gap-6">
                        <img
                            width={124}
                            src={NoTextLogo}
                            alt=""
                        />
                        <h5 className="font-[Sora] text-[32px] mobile:text-[28px] leading-[40px] mobile:leading-[35px] tracking-[-.02em] font-bold text-text">
                            Get Started
                        </h5>
                    </div>
                    <div className="flex flex-col max-w-[480px] w-full gap-6">
                        <div className="flex flex-col gap-2">
                            <button onClick={auth?.googleAuth} className="bg-text w-full flex items-center justify-center gap-2 h-[56px] rounded-xl text-background font-semibold text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] hover:bg-secondaryText transition-colors mobile:rounded-[10px]">
                                <img src={Google} alt="" />
                                Continue with Google
                            </button>
                            <button onClick={auth?.facebookAuth} className="bg-inputBackground w-full flex items-center justify-center gap-2 h-[56px] rounded-xl text-text font-semibold text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] hover:bg-inputBorder transition-colors mobile:rounded-[10px]">
                                <img src={Facebook} alt="" />
                                Continue with Facebook
                            </button>
                        </div>
                        <div className="relative w-full h-[1px] bg-placeholder max-w-[480px]">
                            <span className="absolute top-[-8px] left-[50%] text-placeholder font-semibold text-[12px] px-2 bg-background translate-x-[-50%]">OR</span>
                        </div>
                        <button onClick={handleContinueWithEmail} className="bg-inputBackground flex items-center gap-2 rounded-xl justify-center text-text font-semibold h-[56px] text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] hover:bg-inputBorder transition-colors mobile:rounded-[10px]">
                            <img className={`${theme === 'light' ? '' : 'icon-to-white'}`} src={Email} alt="" />
                            Continue with Email
                        </button>
                    </div>
                    <div className="font-semibold text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                        Already have any account?{" "}
                        <span
                            onClick={handleSignIn}
                            className="text-button cursor-pointer hover:text-buttonHover transition-colors"
                        >
                            Sign in
                        </span>
                    </div>
                </div>
                <div className="mobile:hidden flex items-center justify-center p-6 h-[calc(100vh-68px)]">
                    <img className="h-full" src={AuthPreview} alt="" />
                </div>
            </div>
        </div >
    )
}

export default ContinueWith