import { Fragment } from "react"
import { useParams } from "react-router-dom"

import {
    MAN_OUTFITS,
    MAN_OUTFITS_IMAGES,
    MAN_OUTFITS_TO_KEY,
    WOMAN_OUTFITS,
    WOMAN_OUTFITS_IMAGES,
    WOMAN_OUTFITS_TO_KEY
} from "../../utils/constants"
import { useCreationContext } from "../../context/CreationContext"
import useElementScrolled from "../../hooks/useElementScrolled"
import useViewport from "../../hooks/useViewport"

import ImagePlaceholder from '../../assets/image-placeholder.svg'

type ManOutfitCategory = keyof typeof MAN_OUTFITS_TO_KEY;
type ManOutfitStyle = keyof (typeof MAN_OUTFITS_TO_KEY)[ManOutfitCategory];
type WomanOutfitCategory = keyof typeof WOMAN_OUTFITS_TO_KEY;
type WomanOutfitStyle = keyof (typeof WOMAN_OUTFITS_TO_KEY)[WomanOutfitCategory];
type ManOutfitImageCategory = keyof typeof MAN_OUTFITS_IMAGES;
type ManOutfitImageStyle = keyof (typeof MAN_OUTFITS_IMAGES)[ManOutfitImageCategory];
type WomanOutfitImageCategory = keyof typeof WOMAN_OUTFITS_IMAGES;
type WomanOutfitImageStyle = keyof (typeof WOMAN_OUTFITS_IMAGES)[WomanOutfitImageCategory];

const OutfitStylesStep = () => {
    const ctx = useCreationContext()

    const { id } = useParams()
    const { isMobile } = useViewport()
    const { isScrolled } = useElementScrolled({
        elementRef: ctx?.contentRef,
        eventScrollPosition: 40
    })

    const handleSelectStyle = (type: string, style: string) => {
        const styleType = ctx?.state.gender === 'male'
            ? MAN_OUTFITS_TO_KEY[type as keyof typeof MAN_OUTFITS_TO_KEY]
            : WOMAN_OUTFITS_TO_KEY[type as keyof typeof WOMAN_OUTFITS_TO_KEY]
        const styleKey: string = styleType[style as keyof typeof styleType]

        if (ctx?.state.outfitStyles.split(',').includes(styleKey)) {
            const filteredStyles = ctx?.state.outfitStyles.split(',').filter((style) => style !== styleKey)

            ctx?.setState({ ...ctx.state, outfitStyles: filteredStyles.length > 0 ? filteredStyles.join(',') : '' })

            if (!id) return

            ctx?.syncData({
                generation_id: id,
                field: 'styles',
                value: filteredStyles?.length > 0
                    ? `${filteredStyles.join(',')}${ctx?.state.backgroundStyles ? `,${ctx?.state.backgroundStyles}` : ''}`
                    : `${ctx?.state.backgroundStyles}`
            })
        } else if (ctx?.state && ctx?.state.outfitStyles.split(',').length < ctx?.stylesAvailable) {
            if (!ctx?.state.outfitStyles) {
                ctx?.setState({ ...ctx.state, outfitStyles: styleKey })

                if (!id) return

                ctx?.syncData({
                    generation_id: id,
                    field: 'styles',
                    value: `${styleKey}${ctx?.state.backgroundStyles ? `,${ctx?.state.backgroundStyles}` : ''}`
                })
            } else {
                ctx?.setState({ ...ctx.state, outfitStyles: `${ctx.state.outfitStyles},${styleKey}` })

                if (!id) return

                ctx?.syncData({
                    generation_id: id,
                    field: 'styles',
                    value: `${ctx.state.outfitStyles},${styleKey}${ctx?.state.backgroundStyles ? `,${ctx?.state.backgroundStyles}` : ''}`
                })
            }
        }
    }

    return (
        <div className="flex flex-col items-start gap-8 max-w-[960px] h-full w-full mobile:gap-4 mobile:pt-16">
            <div className={`${isScrolled && !isMobile ? 'w-full fixed top-[68px] p-8 left-0 flex items-center justify-center bg-background shadow-md' : 'w-full flex items-center justify-center'}`}>
                <div className="flex items-center justify-between w-full gap-4 mobile:bg-background mobile:fixed mobile:top-[56px] mobile:left-0 mobile:p-4 mobile:border-solid mobile:border-b-[1px] mobile:border-inputBorder max-w-[960px] mobile:items-start">
                    <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] text-text font-bold mobile:text-[23px] mobile:leading-[28px]">
                        Select up to {ctx?.stylesAvailable} outfit styles.
                    </h5>
                    <div className={`h-[24px] rounded-lg px-2 flex items-center justify-center text-[12px] leading-[18px] font-semibold ${ctx?.state?.outfitStyles ? 'bg-lightBlueBackground text-button' : 'bg-inputBackground text-secondaryText'}`}>
                        {ctx?.state?.outfitStyles ? ctx?.state?.outfitStyles.split(',').length : 0}/{ctx?.stylesAvailable}
                    </div>
                </div>
            </div>
            {ctx?.state.gender === 'male' ? (
                <>
                    {Object.keys(MAN_OUTFITS).map((key, index) => {
                        return (
                            <Fragment key={key}>
                                <div
                                    className={`h-[1px] min-h-[1px] w-full bg-inputBorder ${index === 0 && isMobile ? 'hidden' : ''}`}
                                ></div>
                                <div className="flex flex-col w-full gap-4 last:pb-20 mobile:last:pb-6">
                                    <h5 className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] text-text font-semibold mobile:text-[19px] mobile:leading-[23px]">
                                        {key}
                                    </h5>
                                    <div className="grid grid-cols-[repeat(auto-fill,minmax(186px,1fr))] mobile:grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-3">
                                        {MAN_OUTFITS[key as keyof typeof MAN_OUTFITS].map((style) => (
                                            <div
                                                className={`style-item-block ${ctx?.state.outfitStyles && ctx?.state.outfitStyles.split(',').some((el) => el === MAN_OUTFITS_TO_KEY[key as ManOutfitCategory][style as ManOutfitStyle]) ? 'selected' : ''}`}
                                                onClick={() => handleSelectStyle(key, style)}
                                                key={style}
                                            >
                                                <img
                                                    className="w-full"
                                                    src={
                                                        MAN_OUTFITS_IMAGES[key as ManOutfitCategory][style as ManOutfitImageStyle]
                                                        || ImagePlaceholder
                                                    }
                                                    alt=""
                                                />
                                                <span className="text-center break-words">
                                                    {style}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })}
                </>
            ) : (
                <>
                    {Object.keys(WOMAN_OUTFITS).map((key, index) => {
                        return (
                            <Fragment key={key}>
                                <div
                                    className={`h-[1px] min-h-[1px] w-full bg-inputBorder ${index === 0 && isMobile ? 'hidden' : ''}`}
                                ></div>
                                <div className="flex flex-col w-full gap-4 last:pb-20 mobile:last:pb-6">
                                    <h5 className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] text-text font-semibold mobile:text-[19px] mobile:leading-[23px]">
                                        {key}
                                    </h5>
                                    <div className="grid grid-cols-[repeat(auto-fill,minmax(186px,1fr))] mobile:grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-3">
                                        {WOMAN_OUTFITS[key as keyof typeof WOMAN_OUTFITS].map((style) => (
                                            <div
                                                className={`style-item-block ${ctx?.state.outfitStyles && ctx?.state.outfitStyles.split(',').some((el) => el === WOMAN_OUTFITS_TO_KEY[key as WomanOutfitCategory][style as WomanOutfitStyle]) ? 'selected' : ''}`}
                                                onClick={() => handleSelectStyle(key, style)}
                                                key={style}
                                            >
                                                <img
                                                    className="w-full"
                                                    src={
                                                        WOMAN_OUTFITS_IMAGES[key as WomanOutfitImageCategory][style as WomanOutfitImageStyle]
                                                        || ImagePlaceholder
                                                    }
                                                    alt=""
                                                />
                                                <span className="text-center break-words">
                                                    {style}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })}
                </>
            )
            }
        </div >
    )
}

export default OutfitStylesStep