import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useNavigate, useSearchParams, Link } from "react-router-dom"
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js"
import { FC, MouseEvent, useState } from "react"

import { useCheckCouponStatusMutation } from "../../api/appApi"
import { ADDITIONAL_PLAN_INFO } from "../../utils/constants"
import { useAppSelector } from "../../redux/hooks"
import { Coupon, Plan } from "../../utils/types"

import PayPalButton from "../PayPalButton"

import PlansStripeBlack from "../../assets/plans/stripe-black.svg"
import PlansStripeWhite from "../../assets/plans/stripe-white.svg"
import PlansMoneyBack from "../../assets/plans/money-back.svg"
import PlansCardWhite from "../../assets/plans/card-white.svg"
import CouponCheck from "../../assets/coupon-check.svg"
import CouponMinus from "../../assets/coupon-minus.svg"
import CouponClose from "../../assets/coupon-close.svg"
import PlanPayPal from "../../assets/plans/paypal.svg"
import PlansArrow from "../../assets/plans/arrow.svg"
import CouponPlus from "../../assets/coupon-plus.svg"
import PlansCard from "../../assets/plans/card.svg"
import CouponTag from "../../assets/coupon-tag.svg"

type StripeFormProps = {
    plan: string,
    selectedPlan: Plan | typeof ADDITIONAL_PLAN_INFO,
    verifiedCoupon: Coupon | null,
    setVerifiedCoupon: (coupon: Coupon | null) => void
}

const StripeForm: FC<StripeFormProps> = ({ plan, selectedPlan, verifiedCoupon, setVerifiedCoupon }) => {
    const [formComplete, setFormComplete] = useState<boolean>(false)
    const [enterCoupon, setEnterCoupon] = useState<boolean>(false)
    const [couponCode, setCouponCode] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isStripe, setIsStripe] = useState<boolean>(true)
    const [searchParams] = useSearchParams()
    const theme = useAppSelector(state => state.app.theme)

    const elements = useElements();
    const navigate = useNavigate();
    const stripe = useStripe();

    const [checkCouponStatus] = useCheckCouponStatusMutation()

    const handleFormChange = (event: StripePaymentElementChangeEvent) => {
        setFormComplete(event.complete)
    }

    const handleRemoveCoupon = () => {
        setVerifiedCoupon(null)
        setCouponCode('')
        setEnterCoupon(false)
    }

    const handleEnterCoupon = () => {
        if (enterCoupon || verifiedCoupon) {
            handleRemoveCoupon()
        } else {
            setEnterCoupon(true)
        }
    }

    const handleApplyCoupon = () => {
        checkCouponStatus(couponCode).then((res) => {
            if ("error" in res) return;

            if (res.data?.coupon) {
                setVerifiedCoupon(res.data.coupon)
            }
        })
    }

    const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true)

        const generation = searchParams.get('generation')
        const continue_generation = searchParams.get('continue_generation')

        const returnUrl = generation
            ? `${window.location.origin}/checkout-success?planName=${plan}&generation=${generation}`
            : continue_generation
                ? `${window.location.origin}/creation/${continue_generation}?planName=${plan}`
                : `${window.location.origin}`

        await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
        })
    };

    return (
        <div className="px-6 mobile:px-0">
            <div className="dashboard-background-image flex items-start gap-6 max-w-[960px] m-auto py-[80px] w-full mobile:flex-col mobile:py-0 mobile:gap-0">
                <div className="border-solid border-[1px] border-inputBorder w-full rounded-lg flex flex-col gap-6 mobile:border-b-[1px] mobile:rounded-none mobile:gap-4 mobile:border-none">
                    <div className="flex flex-col gap-6 w-full border-solid border-b-[1px] border-inputBorder">
                        <div className="flex items-center justify-between w-full pt-6 px-8 mobile:px-4">
                            <h5 className="font-[Sora] text-[28px] leading-[35px] font-bold tracking-[-0.02em] text-text mobile:text-[23px] mobile:leading-[28px]">
                                Secure Checkout
                            </h5>
                            <img src={theme === 'light' ? PlansStripeBlack : PlansStripeWhite} alt="" />
                        </div>
                        <div className="h-[38px] flex items-start">
                            <div
                                onClick={() => setIsStripe(true)}
                                className={`flex items-start justify-center gap-2 px-8 h-full text text-[18px] leading-[22px] font-semibold cursor-pointer ${isStripe ? 'text-button border-solid border-b-[3px] border-button' : 'text-secondaryText hover:text-text transition-colors'} mobile:w-1/2 mobile:px-0`}
                            >
                                <img className={`${isStripe ? 'icon-to-blue' : 'icon-to-secondary-text-color'} mt-[2px]`} src={isStripe ? PlansCard : theme === 'light' ? PlansCard : PlansCardWhite} width={18} alt="" />
                                Credit Card
                            </div>
                            <div
                                onClick={() => setIsStripe(false)}
                                className={`flex items-start justify-center gap-2 px-8 h-full text-[18px] leading-[22px] font-semibold cursor-pointer ${!isStripe ? 'text-button border-solid border-b-[3px] border-button' : 'text-secondaryText hover:text-text transition-colors'} mobile:w-1/2 mobile:px-0`}
                            >
                                <img className="mt-[2px]" src={PlanPayPal} alt="" />
                                PayPal
                            </div>
                        </div>
                    </div>
                    {isStripe ? (
                        <div className="px-6 mobile:px-4">
                            <PaymentElement onChange={handleFormChange} id="payment-element" />
                        </div>
                    ) : (
                        <div className="flex flex-col gap-2 w-full items-center justify-center pt-6 mobile:pb-4">
                            <img width={36} src={PlanPayPal} alt="" />
                            <p className="text-text text-[14px] leading-[17px] font-semibold">
                                Click below to pay through PayPal
                            </p>
                        </div>
                    )}
                    {isStripe && (
                        <div className="px-6 mobile:px-4 flex flex-col gap-4">
                            <span onClick={handleEnterCoupon} className="flex gap-2 items-center text-base font-semibold select-none cursor-pointer text-text w-fit">
                                Coupon Code
                                <img
                                    className={theme === "light" ? "" : "icon-to-white"}
                                    src={enterCoupon || verifiedCoupon ? CouponMinus : CouponPlus}
                                    alt=""
                                />
                            </span>
                            {verifiedCoupon ? (
                                <div className="h-[56px] max-w-[400px] w-full rounded-xl border-solid border-[1px] border-button py-2 px-4 ps-3 flex items-center justify-between gap-2 bg-lightBlueBackground">
                                    <div className="flex items-center gap-2">
                                        <img src={CouponCheck} alt="" />
                                        <div className="flex flex-col">
                                            <h5 className="flex items-center gap-1 text-text text-base font-semibold">
                                                {verifiedCoupon.name}
                                                <img src={CouponTag} alt="" />
                                            </h5>
                                            <span className="text-text text-[14px] leading-[18px]">
                                                {verifiedCoupon.percent_off}% discount (-${selectedPlan?.price * (verifiedCoupon.percent_off / 100)}0)
                                            </span>
                                        </div>
                                    </div>
                                    <img onClick={handleRemoveCoupon} className="cursor-pointer" src={CouponClose} alt="" />
                                </div>
                            ) : enterCoupon ? (
                                <div className="h-[56px] max-w-[400px] w-full rounded-xl border-solid border-[1px] border-inputBorder py-2 pe-2 ps-4 flex items-center justify-between gap-2">
                                    <input
                                        className="stripe-coupon-input placeholder:text-placeholder"
                                        onChange={(e) => setCouponCode(e.target.value)}
                                        placeholder="Enter Code"
                                        value={couponCode}
                                        type="text"
                                    />
                                    <button onClick={handleApplyCoupon} disabled={!couponCode} className="bg-button hover:bg-buttonHover rounded-lg px-4 h-[44px] text-base font-semibold text-white disabled:opacity-30 disabled:bg-button">
                                        Apply
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    )}
                    <div className="flex flex-col p-6 gap-6 pt-0 mobile:px-4 mobile:gap-4">
                        {selectedPlan && selectedPlan.name !== 'Additional' && (
                            <div
                                onClick={() => navigate('/plans')}
                                className="flex items-center justify-between p-3 gap-2 rounded-lg border-solid border-[1px] border-inputBorder cursor-pointer"
                            >
                                <div className="flex flex-col">
                                    <span className="text-text text-[12px] leading-[17px]">Selected Plan</span>
                                    <span className="text-text text-base font-semibold">{plan}</span>
                                </div>
                                <img src={PlansArrow} alt="" />
                            </div>
                        )}
                        <div className="flex items-center justify-between gap-4">
                            <p className="text-[23px] leading-[28px] font-bold tracking-[-0.02em] text-text">Due today:</p>
                            <p className="text-[23px] leading-[28px] font-bold tracking-[-0.02em] text-text flex items-center gap-3">
                                {verifiedCoupon && (
                                    <span className="bg-[#0085FF1A] h-[24px] rounded-lg flex items-center justify-center px-2 text-[12px] leading-[18px] text-button font-semibold">
                                        -${selectedPlan?.price * (verifiedCoupon.percent_off / 100)}0
                                    </span>
                                )}
                                {
                                    verifiedCoupon
                                        ? `$${selectedPlan?.price - (selectedPlan?.price * (verifiedCoupon.percent_off / 100))}0`
                                        : `$${selectedPlan?.price}.00`
                                }
                            </p>
                        </div>
                        {isStripe ? (
                            <button
                                disabled={!formComplete || isLoading}
                                onClick={handleSubmit}
                                className="bg-button rounded-lg w-full px-6 py-[10px] font-semibold text-base text-white h-[56px] hover:bg-buttonHover transition-colors disabled:opacity-20 mobile:h-[44px] mobile:rounded-[10px] relative"
                            >
                                {isLoading && (
                                    <div className="w-[50px] h-[50px] absolute top-1/2 left-[35%] translate-y-[-50%] mobile:left-[25%] mobile:w-[40px] mobile:h-[40px]">
                                        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 100 100" enable-background="new 0 0 0 0" xmlSpace="preserve">
                                            <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                <animateTransform
                                                    attributeName="transform"
                                                    attributeType="XML"
                                                    type="rotate"
                                                    dur="1s"
                                                    from="0 50 50"
                                                    to="360 50 50"
                                                    repeatCount="indefinite" />
                                            </path>
                                        </svg>
                                    </div>
                                )}
                                Purchase
                            </button>
                        ) : (
                            <PayPalButton
                                price={selectedPlan.price}
                                planName={selectedPlan.name}
                            />
                        )}
                        <p className="text-[12px] leading-[18px] text-secondaryText">
                            By clicking “Purchase” you agree to be charged ${selectedPlan?.price}.00 now and accept our <Link className="text-button" to='/terms-and-conditions'>Terms of Use</Link> and <Link className="text-button" to="/privacy-policy">Privacy Policy</Link>. Your payment will appear as AI Headshots on your credit card statement.
                        </p>
                    </div>
                </div>
                <div className="bg-[#EFEFFF] h-[5px] w-full hidden mobile:block"></div>
                <div className="border-solid border-[1px] border-inputBorder min-w-[280px] w-[280px] rounded-lg p-6 flex flex-col gap-2 mobile:w-full mobile:rounded-none mobile:border-t-[1px] mobile:p-4">
                    <img width={44} src={PlansMoneyBack} alt="" />
                    <h5 className="font-[Sora] text-[19px] leading-[23px] tracking-[-0.02em] text-text font-semibold">
                        Money-back guarantee
                    </h5>
                    <p className="pb-0 text-text text-[12px] leading-[18px]">
                        If you are not fully satisfied with your image results, just let us know and we will happily process a full refund.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default StripeForm