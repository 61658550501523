import { useNavigate } from 'react-router-dom'

import { useAuthContext } from '../../context/AuthContext'
import { useAppSelector } from '../../redux/hooks'

import ResetSuccessDark from '../../assets/reset-success-dark.svg'
import ResetSuccess from '../../assets/reset-success.svg'

const PasswordChanged = () => {
    const theme = useAppSelector((state) => state.app.theme)
    const user = useAppSelector((state) => state.app.user)

    const navigate = useNavigate()

    const authCtx = useAuthContext()

    const handleNavigateToSignIn = () => {
        if (user) {
            authCtx?.signOutUser()
        }
        navigate('/sign-in')
    }

    return (
        <div className="max-w-[640px] w-full p-6 flex flex-col gap-6 bg-background rounded-[24px] border-solid border-[1px] border-inputBorder shadow-modal min-h-[404px] justify-between mobile:h-full mobile:rounded-none mobile:p-4">
            <div className="w-full flex flex-col gap-4 items-center justify-center mt-14 mobile:h-full">
                {theme === 'light' ? (
                    <img className="w-[100px]" src={ResetSuccess} alt="" />
                ) : (
                    <img className="w-[100px]" src={ResetSuccessDark} alt="" />
                )}
                <p className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] font-bold text-text text-center max-w-[450px]">
                    Your password was successfully changed.
                </p>
            </div>
            <button onClick={handleNavigateToSignIn} className="h-[56px] rounded-xl bg-button hover:bg-buttonHover transition-colors disabled:hover:bg-button disabled:opacity-20 text-[18px] leading-[22px] font-semibold text-white w-full mobile:h-[44px] mobile:text-base mobile:min-h-[44px]">
                Sign In
            </button>
        </div>
    )
}

export default PasswordChanged