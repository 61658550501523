import { PLANS } from "../utils/constants"

import PlanItem from "../components/UpgradePlan/PlanItem"
import Header from "../components/Header"

const Plans = () => {
    return (
        <div className="flex flex-col w-full">
            <Header />
            <div className="px-6 h-[calc(100vh-68px)] mobile:h-[calc(100vh-56px)] custom-scroll overflow-y-auto mobile:px-0">
                <div className="dashboard-background-image flex flex-col min-h-[calc(100vh-68px)] mobile:min-h-[calc(100vh-56px)] max-w-[960px] m-auto py-[80px] gap-[40px] w-full mobile:py-6 mobile:px-4 mobile:gap-4">
                    <div className="flex flex-col gap-2">
                        <h5 className="font-[Sora] text-[32px] tracking-[-0.02em] leading-[40px] text-text font-bold mobile:text-[23px] mobile:leading-[28px]">
                            Select a Plan
                        </h5>
                        <p className="text-secondaryText text-[20px] leading-[28px] mobile:text-[14px] mobile:leading-[17px]">
                            Pay once, no subscriptions or hidden fees. We offer no trial due to high costs, but we will refund you if you're unsatisfied.
                        </p>
                    </div>
                    <div className="flex flex-col border-solid border-[1px] border-inputBorder rounded-xl">
                        {PLANS.map((plan) => (
                            <PlanItem key={plan.id} plan={plan} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans