import { useCreationContext } from "../../context/CreationContext"

const ProgressBar = () => {
    const ctx = useCreationContext()

    return (
        <div className="max-w-[480px] flex items-center min-h-[4px] bg-inputBackground relative w-full">
            <div
                style={{
                    width: `${ctx?.step && ctx?.step >= 2 ? (ctx?.step - 1) * 25 : 0}%`
                }}
                className="min-h-[4px] bg-highlight"
            ></div>
            <div className="flex items-center justify-between w-full absolute">
                {[1, 2, 3, 4, 5].map((step) => (
                    <div key={step} className={`w-[24px] h-[24px] rounded-full flex items-center justify-center text-[12px] leading-[18px] font-semibold text-text ${ctx?.step && ctx?.step >= step ? 'bg-highlight' : 'bg-inputBackground'}`}>
                        {ctx?.step && ctx?.step >= step ? step : ''}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProgressBar