import { FC } from "react"

import { GenerationItem } from "../../utils/types"

import AlbumItem from "./AlbumItem"

type AlbumsListProps = {
    albums: GenerationItem[],
}

const AlbumsList: FC<AlbumsListProps> = ({ albums }) => {
    return (
        <div className="w-full flex flex-col max-w-[960px] gap-6 mobile:pb-[50px]">
            {albums.map((album) => (
                <AlbumItem key={album.id} album={album} />
            ))}
        </div>
    )
}

export default AlbumsList