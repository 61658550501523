import { FC, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { appApi, useUpdateUsersMutation } from "../../api/appApi"
import { useAuthContext } from "../../context/AuthContext"
import { setTheme, signOut } from "../../redux/appSlice"
import { useAppSelector } from "../../redux/hooks"
import useViewport from "../../hooks/useViewport"

import ProfileSettingsModal from "../../modals/ProfileSettingsModal"

import ArrowRightDarkGrey from "../../assets/arrow-right-dark-grey.svg"
import DefaultAvatar from "../../assets/default-avatar.jpg"
import LogoutRed from "../../assets/logout-red.svg"
import ThemeBlue from "../../assets/theme-blue.svg"
import Logout from "../../assets/logout.svg"
import Theme from "../../assets/theme.svg"
import User from "../../assets/user.svg"

type UserBlockProps = {
    nameHidden?: boolean
}

const UserBlock: FC<UserBlockProps> = ({ nameHidden }) => {
    const [showProfileSettings, setShowProfileSettings] = useState<boolean>(false)
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const subscription = useAppSelector(state => state.app.subscription)
    const theme = useAppSelector(state => state.app.theme)
    const user = useAppSelector(state => state.app.user)

    const userBlockRef = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()

    const auth = useAuthContext()

    const { isMobile } = useViewport()

    const [updateUser] = useUpdateUsersMutation()

    const switchTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light'
        dispatch(setTheme(newTheme))
        updateUser({ theme: newTheme })

        localStorage.setItem('theme', newTheme)
    }

    const handleLogout = () => {
        auth?.signOutUser()
        dispatch(signOut())
        dispatch(appApi.util.resetApiState())
    }

    const handleOpenProfileSettings = () => {
        setShowProfileSettings(true)
        setShowDropdown(false)
    }

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (userBlockRef.current && !userBlockRef.current.contains(e.target as Node)) {
                setShowDropdown(false)
            }
        }

        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [])

    return (
        <div ref={userBlockRef} className="relative">
            <div
                onClick={() => setShowDropdown(!showDropdown)}
                className={`flex items-center gap-2 p-2 mobile:h-full rounded-xl cursor-pointer mobile:active:bg-inputBackground ${showDropdown ? 'bg-inputBorder hover:bg-inputBorder mobile:hover:bg-inputBorder' : 'hover:bg-inputBackground mobile:hover:bg-transparent'}`}
            >
                <img
                    className="w-[36px] h-[36px] rounded-lg mobile:w-[32px] mobile:h-[32px]"
                    src={subscription?.image ? subscription?.image : DefaultAvatar}
                    alt=""
                />
                {!nameHidden && (
                    <span className="text-base text-text font-semibold">
                        {user?.displayName && user?.displayName.split(' ').length > 1 ? `${user.displayName.split(' ')[0]} ${user.displayName.split(' ')[1][0]}.` : user?.displayName}
                    </span>
                )}
            </div>
            {showDropdown && (
                <div className="absolute w-[296px] right-0 top-[68px] border-solid border-[1px] border-inputBorder rounded-xl bg-background z-20 mobile:z-[101]">
                    <div className="text-text w-full border-solid border-b-[1px] border-inputBorder px-4 py-2 text-base font-semibold">
                        Settings
                    </div>
                    <div className="flex flex-col p-4 gap-4">
                        <div className="w-full flex items-center gap-2">
                            <div
                                onClick={switchTheme}
                                className={`flex flex-col gap-1 items-center w-1/2 p-3 rounded-lg cursor-pointer transition-colors ${theme === 'dark' ? 'bg-inputTransparentBackground hover:bg-[#0085FF33]' : 'hover:bg-inputBackground'}`}
                            >
                                {theme === 'dark' ? (
                                    <img src={ThemeBlue} alt="" />
                                ) : (
                                    <img src={Theme} alt="" />
                                )}
                                <h5 className={`text-[14px] leading-[17px] font-semibold ${theme === 'dark' ? 'text-button' : 'text-text'}`}>
                                    Dark
                                </h5>
                                <span className={`text-[12px] leading-[18px] ${theme === 'dark' ? 'text-button' : 'text-secondaryText'}`}>
                                    {theme === 'light' ? 'Off' : 'On'}
                                </span>
                            </div>
                            <div onClick={handleOpenProfileSettings} className={`flex flex-col gap-1 items-center w-1/2 p-3 rounded-lg cursor-pointer transition-colors ${theme === 'dark' ? 'hover:bg-inputBorder' : 'hover:bg-inputBackground'}`}>
                                <img className={theme === 'dark' ? 'icon-to-white' : ''} src={User} alt="" />
                                <h5 className="text-[14px] leading-[17px] font-semibold text-text">
                                    Profile
                                </h5>
                                <img
                                    className={theme === 'dark' ? 'icon-to-white' : ''}
                                    width={18}
                                    src={ArrowRightDarkGrey}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div onClick={handleLogout} className="text-text flex items-center justify-between p-3 border-solid border-[1px] border-inputBorder rounded-lg cursor-pointer text-[14px] leading-[17px] font-semibold hover:bg-[#FF525D1A] transition-colors hover:text-[#FF525D] logout-button">
                            Log Out
                            <img className="logout-icon-red" src={LogoutRed} alt="" />
                            <img className="logout-icon" src={Logout} alt="" />
                        </div>
                    </div>
                </div>
            )}
            {showProfileSettings && (
                <ProfileSettingsModal
                    onClose={() => setShowProfileSettings(false)}
                    disableCloseOutside={isMobile}
                />
            )}
        </div>
    )
}

export default UserBlock