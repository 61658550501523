import { FC, createContext, useContext, useEffect, useState } from "react";

import {
    useFavoriteImagesMutation,
    useGetGenerationResultsQuery,
    useUpdateGenerationMutation
} from "../api/appApi";
import { GenerationResultImage } from "../utils/types";
import useViewport from "../hooks/useViewport";

type AlbumProviderProps = {
    children: React.ReactNode;
}

type AlbumContextType = {
    setId: (id: string) => void,
    album: GenerationResultImage[],
    setAlbum: (album: GenerationResultImage[]) => void,
    favorites: GenerationResultImage[],
    setFavorites: (favorites: GenerationResultImage[]) => void,
    previewImage: string | null,
    setPreviewImage: (previewImage: string | null) => void,
    selectedImages: string[],
    setSelectedImages: (selectedImages: string[]) => void,
    showAll: boolean,
    setShowAll: (showAll: boolean) => void,
    handleDownloadSelected: () => void,
    handleOpenPreview: (id: string) => void,
    handleUpdateFavorite: (itemId: string) => void,
    handleUnfavoriteSelected: () => void,
    handleFavoriteSelected: () => void,
    handleSelectImage: (id: string) => void,
    handleSelectAll: () => void,
    title: string,
    setTitle: (title: string) => void,
    updateAlbumTitle: () => void,
    downloadImage: (image: GenerationResultImage) => void,
    showFavorite: boolean,
    setShowFavorite: (showFavorite: boolean) => void,
    openPhotoOptions: boolean,
    setOpenPhotoOptions: (openPhotoOptions: boolean) => void,
    additionalGenerationStatus: "do_not_exist" | "pending" | "completed",
    nonEditedTitle: string
} | null;

const AlbumContext = createContext<AlbumContextType>(null);

export const useAlbumContext = () => useContext(AlbumContext);

export const AlbumProvider: FC<AlbumProviderProps> = ({ children }) => {
    const [additionalGenerationStatus, setAdditionalGenerationStatus] = useState<"do_not_exist" | "pending" | "completed">("do_not_exist")
    const [openPhotoOptions, setOpenPhotoOptions] = useState<boolean>(false)
    const [favorites, setFavorites] = useState<GenerationResultImage[]>([])
    const [previewImage, setPreviewImage] = useState<string | null>(null)
    const [selectedImages, setSelectedImages] = useState<string[]>([])
    const [nonEditedTitle, setNonEditedTitle] = useState<string>('')
    const [album, setAlbum] = useState<GenerationResultImage[]>([])
    const [showFavorite, setShowFavorite] = useState<boolean>(true)
    const [showAll, setShowAll] = useState<boolean>(true)
    const [id, setId] = useState<string | null>(null)
    const [title, setTitle] = useState<string>('')

    const { isMobile } = useViewport()

    const [favoriteImages] = useFavoriteImagesMutation()
    const [updateGeneration] = useUpdateGenerationMutation()
    const { data } = useGetGenerationResultsQuery(id ? id : '', { skip: !id })

    const handleOpenPreview = (itemId: string) => {
        setPreviewImage(itemId)
    }

    const updateAlbumTitle = () => {
        if (!id) return

        const data = { title: title }

        updateGeneration({ id: id, data: data })
    }

    const handleSelectImage = (itemId: string) => {
        if (selectedImages.includes(itemId)) {
            setSelectedImages(selectedImages.filter((image) => image !== itemId))
        } else {
            setSelectedImages([...selectedImages, itemId])
        }
    }

    const handleSelectAll = () => {
        if ((showAll && selectedImages.length === album.length) || (!showAll && favorites.length === selectedImages.length)) {
            setSelectedImages([])
        } else {
            if (showAll) {
                setSelectedImages(album.map((image) => image.id))
            } else {
                setSelectedImages(favorites.map((image) => image.id))
            }
        }
    }

    const handleFavoriteSelected = () => {
        if (!id) return

        const ids = selectedImages
        const filteredIds = ids.filter((id) => !album.find((image) => image.id === id)!.favorite)

        favoriteImages({
            data: {
                ids: [...filteredIds],
                select_all: false,
                favorite: true
            },
            id: id,
        })
        setSelectedImages([])

        if (isMobile) {
            setOpenPhotoOptions(false)
        }
    }

    const handleUnfavoriteSelected = () => {
        if (!id) return

        const ids = selectedImages
        const filteredIds = ids.filter((id) => album.find((image) => image.id === id)!.favorite)

        favoriteImages({
            data: {
                ids: [...filteredIds],
                select_all: false,
                favorite: false
            },
            id: id,
        })
        setSelectedImages([])

        if (isMobile) {
            setOpenPhotoOptions(false)
        }
    }

    const handleUpdateFavorite = (itemId: string) => {
        if (!id) return

        favoriteImages({
            data: {
                ids: [itemId],
                select_all: false,
                favorite: !album.find((image) => image.id === itemId)!.favorite
            },
            id: id,
        })
        setSelectedImages([])

        if (isMobile) {
            setOpenPhotoOptions(false)
        }
    }

    const handleDownloadSelected = async () => {
        if (selectedImages.length > 0) {
            const filteredImages = album.filter((image) => selectedImages.includes(image.id))

            for (const image of filteredImages) {
                await downloadImage(image)
            }
        } else {
            if (showAll) {
                for (const image of album) {
                    await downloadImage(image)
                }
            } else {
                for (const image of favorites) {
                    await downloadImage(image)
                }
            }
        }
        setSelectedImages([])

        if (isMobile) {
            setOpenPhotoOptions(false)
        }
    }

    const downloadImage = async (image: GenerationResultImage) => {
        const response = await fetch(image.image);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a')
        link.href = url
        link.download = 'image.jpg'
        link.click()
    }

    useEffect(() => {
        const favoritesList = album.filter((image) => image.favorite)
        setFavorites(favoritesList)
    }, [album])

    useEffect(() => {
        if (data) {
            setAlbum(data.images)
            setTitle(data.title)
            setNonEditedTitle(data.title)
            setAdditionalGenerationStatus(data.additional_generation)
        }
    }, [data])

    const value = {
        setId, album, setAlbum, favorites,
        setFavorites, previewImage, setPreviewImage,
        selectedImages, setSelectedImages, showAll,
        setShowAll, handleDownloadSelected, handleOpenPreview,
        handleUpdateFavorite, handleUnfavoriteSelected,
        handleFavoriteSelected, handleSelectImage,
        handleSelectAll, title, setTitle, updateAlbumTitle,
        downloadImage, showFavorite, setShowFavorite,
        openPhotoOptions, setOpenPhotoOptions, additionalGenerationStatus,
        nonEditedTitle
    }

    return (
        <AlbumContext.Provider value={value}>
            {children}
        </AlbumContext.Provider>
    )
}