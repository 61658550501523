import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import {
    useDeleteUserMutation,
    useGetInvoicesQuery,
    useGetPendingGenerationQuery,
    useUpdateUserEmailMutation,
    useUpdateUserImageMutation,
    useUpdateUserPasswordMutation,
    useUpdateUsersMutation
} from "../api/appApi"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { Invoice, UpdateUserPayload } from "../utils/types"
import { validateUserPassword } from "../utils/functions"
import { useAuthContext } from "../context/AuthContext"
import useViewport from "../hooks/useViewport"
import { setUser } from "../redux/appSlice"

import GenerationDeleteUserModal from "./GenerationDeleteUserModal"
import DefaultDeleteUserModal from "./DefaultDeleteUserModal"
import Modal from "./Modal"

import TrashBlack from "../assets/creation/trash-black.svg"
import DefaultAvatar from "../assets/default-avatar.jpg"
import DownloadBlack from "../assets/download-black.svg"
import ArrowBack from "../assets/back-arrow.svg"
import Trash from '../assets/creation/trash.svg'
import EyeClose from "../assets/eye-close.svg"
import Facebook from "../assets/facebook.svg"
import Google from "../assets/google.svg"
import Close from "../assets/close.svg"
import Check from "../assets/check.svg"
import Pen from "../assets/pen.svg"
import Eye from "../assets/eye.svg"

type ProfileSettingsModalProps = {
    onClose: () => void,
    disableCloseOutside: boolean
}

type UpdateEmailForm = {
    currentEmail: string,
    newEmail: string,
    confirmEmail: string
}

type UpdatePasswordForm = {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

const ProfileSettingsModal: FC<ProfileSettingsModalProps> = ({ onClose, disableCloseOutside }) => {
    const [showGenerationsDeleteModal, setShowGenerationsDeleteModal] = useState<boolean>(false)
    const [showDefaultDeleteModal, setShowDefaultDeleteModal] = useState<boolean>(false)
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
    const [isProfileSettings, setIsProfileSettings] = useState<boolean>(true)
    const [edit, setEdit] = useState<null | 'password' | 'email'>(null)
    const [photoFile, setPhotoFile] = useState<File | null>(null)
    const [gender, setGender] = useState<string | null>(null)
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const subscription = useAppSelector((state) => state.app.subscription)
    const theme = useAppSelector((state) => state.app.theme)
    const user = useAppSelector((state) => state.app.user)

    const inputRef = useRef<HTMLInputElement>(null)

    const { isMobile } = useViewport()

    const dispatch = useAppDispatch()

    const authCtx = useAuthContext()

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors }
    } = useForm<UpdateEmailForm>({
        defaultValues: {
            currentEmail: user?.email || '',
            newEmail: '',
            confirmEmail: ''
        }
    })
    const {
        register: registerPassword,
        handleSubmit: handleSubmitPassword,
        setError: setErrorPassword,
        reset: resetPassword,
        formState: { errors: errorsPassword }
    } = useForm<UpdatePasswordForm>({})

    const [deleteUser] = useDeleteUserMutation()
    const [updateUser] = useUpdateUsersMutation()
    const [updateUserEmail] = useUpdateUserEmailMutation()
    const [updateUserImage] = useUpdateUserImageMutation()
    const [updateUserPassword] = useUpdateUserPasswordMutation()
    const { data: userGeneration } = useGetPendingGenerationQuery()
    const { data } = useGetInvoicesQuery()

    const navigate = useNavigate()

    const isDisabled = useMemo(() => {
        return user?.displayName !== `${firstName} ${lastName}` || subscription?.gender !== gender || photoFile ? false : true
    }, [user, subscription, firstName, lastName, gender, photoFile])

    const handleChangePhoto = () => {
        inputRef.current?.click()
    }

    const onChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setPhotoFile(e.target.files[0])
        }
    }

    const handleRemoveLogo = () => {
        updateUserImage({})
    }

    const handleDiscardEmailUpdate = () => {
        reset()
        setEdit(null)
    }

    const handleDiscardPasswordUpdate = () => {
        resetPassword()
        setEdit(null)
    }

    const handleDeleteUser = () => {
        if (userGeneration) {
            setShowGenerationsDeleteModal(true)
        } else {
            setShowDefaultDeleteModal(true)
        }
    }

    const handleDownloadInvoices = () => {
        data?.forEach((invoice) => {
            if (!invoice.download) return

            window.open(invoice.download, '_blank')
        })
    }

    const handleDownloadSingleDownload = (invoice: Invoice) => {
        if (!invoice.download) return

        window.open(invoice.download, '_blank')
    }

    const handleViewSingleInvoice = (invoice: Invoice) => {
        if (!invoice.view) return

        window.open(invoice.view, '_blank')
    }

    const deleteAccountConfirmed = (withDownload: boolean) => {
        deleteUser().then(() => {
            authCtx?.signOutUser()
        })

        if (withDownload) {
            handleDownloadInvoices()
        }
    }

    const handleUpdateEmail = (data: UpdateEmailForm) => {
        if (data?.confirmEmail !== data?.newEmail) {
            setError('confirmEmail', {
                type: 'manual',
                message: 'Email addresses do not match'
            })
        } else {
            updateUserEmail(data.newEmail).then(async () => {
                setEdit(null)
                await authCtx?.signOutUser()
                navigate('/sign-in')
                toast.success('Email updated successfully. Please sign in with your new email address.')
            })
        }
    }

    const handleUpdatePassword = async (data: UpdatePasswordForm) => {
        const result = await validateUserPassword(data.currentPassword)

        if (!result) {
            setErrorPassword('currentPassword', {
                type: 'manual',
                message: 'Incorrect password'
            })
        } else {
            if (data.newPassword !== data.confirmPassword) {
                setErrorPassword('confirmPassword', {
                    type: 'manual',
                    message: 'Passwords do not match'
                })
            } else {
                updateUserPassword(data.newPassword).then(async () => {
                    setEdit(null)
                    await authCtx?.signOutUser()
                    navigate('/sign-in')
                    toast.success('Password updated successfully. Please sign in with your new password.')
                })
            }
        }
    }

    const handleSaveChanges = () => {
        const data: UpdateUserPayload = {}

        if (subscription?.gender !== gender && gender) {
            data.gender = gender
        }

        if (user?.displayName !== `${firstName} ${lastName}`) {
            data.name = `${firstName} ${lastName}`
        }

        if (data?.gender || data?.name) {
            updateUser(data).then(() => {
                if (data.name && user) {
                    dispatch(setUser({
                        ...user,
                        displayName: data.name
                    }))
                }
            })
        }

        if (photoFile) {
            const formData = new FormData()
            formData.append('image', photoFile)
            updateUserImage(formData).then(() => {
                setPhotoFile(null)
            })
        }
    }

    useEffect(() => {
        if (user) {
            setFirstName(user?.displayName?.split(' ')[0] || '')
            setLastName(user?.displayName?.split(' ')[1] || '')
        }
    }, [user])

    useEffect(() => {
        if (subscription) {
            setGender(subscription?.gender)
        }
    }, [subscription])

    return (
        <Modal
            onClose={onClose}
            bodyStyles={{ padding: 0 }}
            customModalClasses="mobile:bg-transparent mobile:p-0 mobile:top-[56px] mobile:items-start"
            customBodyClasses="max-w-[90vw] max-h-[90vh] h-[90vh] bg-background mobile:max-w-[100vw] mobile:max-h-[calc(100vh-56px)] mobile:h-[calc(100vh-56px)] mobile:rounded-none mobile:border-0"
            disableCloseOutside={disableCloseOutside}
        >
            <div className="flex flex-col gap-6 w-full mobile:gap-0">
                <div className="flex items-center justify-between p-8 pb-0 mobile:py-6 mobile:px-4">
                    <h5 className="font-[Sora] text-[28px] leading-[25px] font-bold tracking-[-0.02em] text-text mobile:text-[23px] mobile:leading-[28px]">
                        Settings
                    </h5>
                    <img
                        className={`${theme === 'light' ? '' : 'icon-to-white'} w-[28px] cursor-pointer close-icon mobile:hidden`}
                        onClick={onClose}
                        src={Close}
                        alt=""
                    />
                </div>
                <div className="flex border-solid border-b-[1px] border-inputBorder">
                    <div onClick={() => setIsProfileSettings(true)} className={`h-[38px] flex items-start text-[18px] leading-[22px] font-semibold px-8 cursor-pointer transition-colors mobile:w-1/2 justify-center ${isProfileSettings ? 'border-solid border-b-[3px] border-button text-button' : 'text-secondaryText'}`}>
                        Profile
                    </div>
                    <div onClick={() => setIsProfileSettings(false)} className={`h-[38px] flex items-start text-[18px] leading-[22px] font-semibold px-8 cursor-pointer transition-colors mobile:w-1/2 justify-center ${!isProfileSettings ? 'border-solid border-b-[3px] border-button text-button' : 'text-secondaryText'}`}>
                        Billing
                    </div>
                </div>
            </div>
            {isProfileSettings ? (
                <div className="flex flex-col gap-0 h-[calc(100%-128px)] justify-between mobile:h-[calc(100vh-172px)]">
                    <div className="flex flex-col w-full p-8 gap-8 max-h-[calc(90vh-250px)] overflow-y-auto custom-scroll mobile:p-4 mobile:gap-4 mobile:max-h-[100%] mobile:h-full mobile:px-0">
                        <div className="flex flex-col gap-2 mobile:items-center mobile:px-4">
                            <p className="text-base text-text font-semibold">
                                Profile Photo
                            </p>
                            <div className="flex items-center gap-4 mobile:gap-2 mobile:flex-col">
                                <img
                                    className="w-[120px] h-[120px] rounded-full object-cover"
                                    src={photoFile ? URL.createObjectURL(photoFile) : subscription?.image ? subscription?.image : DefaultAvatar}
                                    alt=""
                                />
                                <input
                                    onChange={(e) => onChangePhoto(e)}
                                    defaultValue={undefined}
                                    className="hidden"
                                    ref={inputRef}
                                    accept="image/*"
                                    type="file"
                                />
                                <div className="flex items-center gap-4 mobile:gap-2">
                                    <button onClick={handleChangePhoto} className="h-[44px] bg-inputBackground hover:bg-inputBorder transition-colors px-4 rounded-[10px] text-text text-base font-semibold mobile:h-[32px] mobile:rounded-lg mobile:px-3 mobile:text-[14px] mobile:leading-[17px]">
                                        Change Photo
                                    </button>
                                    {subscription?.image && (
                                        <button onClick={handleRemoveLogo} className="flex items-center justify-center h-[44px] w-[44px] rounded-[10px] bg-redButton hover:bg-redButtonHover transition-colors mobile:h-[32px] mobile:w-[32px] mobile:rounded-lg">
                                            <img className="w-[20px] mobile:w-[18px]" src={Trash} alt="" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-3 items-center w-full gap-4 mobile:grid-cols-1 mobile:px-4">
                            <div className="flex flex-col gap-2">
                                <p className="text-base text-text font-semibold">
                                    First Name
                                </p>
                                <input
                                    type="text"
                                    value={firstName}
                                    readOnly={edit ? true : false}
                                    placeholder="Enter first name"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className={`h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] ${edit ? 'text-secondaryText disable-hover-input-settings' : 'text-text'}`}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="text-base text-text font-semibold">
                                    Last Name
                                </p>
                                <input
                                    type="text"
                                    value={lastName}
                                    readOnly={edit ? true : false}
                                    placeholder="Enter last name"
                                    onChange={(e) => setLastName(e.target.value)}
                                    className={`h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] ${edit ? 'text-secondaryText disable-hover-input-settings' : 'text-text'}`}
                                />
                            </div>
                            <div className="flex flex-col gap-2 mobile:hidden">
                                <p className="text-base text-text font-semibold">
                                    Gender
                                </p>
                                <div className={`flex items-center w-full rounded-lg border-solid border-[1px] border-inputBorder h-[56px] mobile:h-[44px] ${edit ? 'pointer-events-none' : ''}`}>
                                    <div
                                        onClick={() => setGender('male')}
                                        className={`w-1/2 h-full flex items-center justify-center border-solid border-e-[1px] border-inputBorder font-semibold text-base cursor-pointer rounded-s-lg mobile:text-base transition-colors ${gender === 'male' ? 'text-button outline outline-2 outline-button bg-inputTransparentBackground outline-offset-[-2px]' : 'input-focus-hover text-secondaryText hover:text-text'}`}
                                    >
                                        Male
                                    </div>
                                    <div
                                        onClick={() => setGender('female')}
                                        className={`w-1/2 h-full flex items-center justify-center font-semibold text-base cursor-pointer rounded-e-lg mobile:text-base transition-colors ${gender === 'female' ? 'text-button outline outline-2 outline-button bg-inputTransparentBackground outline-offset-[-2px]' : 'input-focus-hover text-secondaryText hover:text-text'}`}
                                    >
                                        Female
                                    </div>
                                </div>
                            </div>
                        </div>
                        {edit ? (
                            <div className="w-full border-solid border-[1px] border-inputBorder rounded-[24px] mobile:rounded-none mobile:border-l-0 mobile:border-r-0 mobile:border-t-[5px] mobile:border-b-[5px]">
                                <div className="h-[76px] border-solid border-b-[1px] border-inputBorder flex items-center px-6 mobile:px-4 mobile:h-[71px]">
                                    <h5 className="font-[Sora] text-[23px] leading-[28px] text-text tracking-[-0.02em] font-bold mobile:text-[19px] mobile:leading-[23px]">
                                        {edit === 'email' ? 'Change Email Address' : 'Change Password'}
                                    </h5>
                                </div>
                                <div className="p-6 mobile:p-4">
                                    {edit === 'email' ? (
                                        <form className="gap-6 w-full flex flex-col mobile:gap-4" onSubmit={handleSubmit(handleUpdateEmail)}>
                                            <div className="grid grid-cols-3 gap-4 w-full mobile:grid-cols-1">
                                                <div className="flex flex-col gap-2">
                                                    <p className="text-base text-text font-semibold">
                                                        Current Email
                                                    </p>
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        {...register('currentEmail')}
                                                        placeholder="Current email address"
                                                        className="h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-secondaryText text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] disable-hover-input-settings"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <p className="text-base text-text font-semibold">
                                                        New Email Address
                                                    </p>
                                                    <input
                                                        type="text"
                                                        {...register('newEmail', {
                                                            required: true,
                                                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                        })}
                                                        placeholder="Enter your new email address"
                                                        className={`h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] ${errors.newEmail ? 'outline outline-[2px] outline-redButton outline-offset-[-2px] border-0' : ''}`}
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <p className="text-base text-text font-semibold">
                                                        Confirm New Email Address
                                                    </p>
                                                    <input
                                                        type="text"
                                                        {...register('confirmEmail', {
                                                            required: true,
                                                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                        })}
                                                        placeholder="Re-enter your new email address"
                                                        className={`h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] ${errors.confirmEmail ? 'outline outline-[2px] outline-redButton outline-offset-[-2px] border-0' : ''}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-end w-full gap-2">
                                                <button onClick={handleDiscardEmailUpdate} type="button" className="h-[56px] px-6 rounded-xl bg-inputBackground hover:bg-inputBorder transition-colors text-[18px] leading-[22px] text-text font-semibold mobile:h-[44px] mobile:rounded-[10px] mobile:px-4 mobile:w-1/2 mobile:text-base">
                                                    Discard
                                                </button>
                                                <button className="bg-button hover:bg-buttonHover transition-colors rounded-xl h-[56px] text-white text-[18px] leading-[22px] font-semibold px-6 mobile:h-[44px] mobile:rounded-[10px] mobile:px-4 mobile:w-1/2 mobile:text-base">
                                                    {isMobile ? 'Update' : 'Update Email Address'}
                                                </button>
                                            </div>
                                        </form>
                                    ) : (
                                        <form className="gap-6 w-full flex flex-col" onSubmit={handleSubmitPassword(handleUpdatePassword)}>
                                            <div className="grid grid-cols-3 gap-4 w-full mobile:grid-cols-1">
                                                <div className="flex flex-col gap-2">
                                                    <p className="text-base text-text font-semibold">
                                                        Current Password
                                                    </p>
                                                    <div className="relative w-full">
                                                        <input
                                                            type={showCurrentPassword ? "text" : "password"}
                                                            {...registerPassword('currentPassword', {
                                                                required: true
                                                            })}
                                                            placeholder="Enter your current password"
                                                            className={`w-full h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-secondaryText text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] ${errorsPassword.currentPassword ? 'outline outline-[2px] outline-redButton outline-offset-[-2px] border-0' : ''}`}
                                                        />
                                                        {showCurrentPassword ? (
                                                            <img
                                                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                                src={Eye}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                                src={EyeClose}
                                                                alt=""
                                                            />
                                                        )}
                                                    </div>
                                                    <Link className="text-[12px] leading-[18px] text-button hover:text-buttonHover transition-colors cursor-pointer" to="/reset-password">
                                                        Forgot your current password?
                                                    </Link>
                                                </div>
                                                <div className="flex flex-col gap-2 h-full">
                                                    <p className="text-base text-text font-semibold">
                                                        New Password
                                                    </p>
                                                    <div className="w-full relative">
                                                        <input
                                                            type={showNewPassword ? "text" : "password"}
                                                            {...registerPassword('newPassword', {
                                                                required: true,
                                                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                                                            })}
                                                            placeholder="Create new password"
                                                            className={`w-full h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] ${errorsPassword.newPassword ? 'outline outline-[2px] outline-redButton outline-offset-[-2px] border-0' : ''}`}
                                                        />
                                                        {showNewPassword ? (
                                                            <img
                                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                                src={Eye}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                                src={EyeClose}
                                                                alt=""
                                                            />
                                                        )}
                                                    </div>
                                                    <p className="text-secondaryText text-[12px] leading-[18px]">
                                                        Your new password must be at least 8 characters long and include a mix of letters, numbers, and a special character.
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <p className="text-base text-text font-semibold">
                                                        Confirm New Password
                                                    </p>
                                                    <div className="w-full relative">
                                                        <input
                                                            type={showConfirmPassword ? "text" : "password"}
                                                            {...registerPassword('confirmPassword', {
                                                                required: true,
                                                            })}
                                                            placeholder="Re-enter your new password"
                                                            className={`w-full h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] ${errorsPassword.confirmPassword ? 'outline outline-[2px] outline-redButton outline-offset-[-2px] border-0' : ''}`}
                                                        />
                                                        {showConfirmPassword ? (
                                                            <img
                                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                                src={Eye}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                                src={EyeClose}
                                                                alt=""
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-end w-full gap-2 h-full">
                                                <button onClick={handleDiscardPasswordUpdate} type="button" className="h-[56px] px-6 rounded-xl bg-inputBackground hover:bg-inputBorder transition-colors text-[18px] leading-[22px] text-text font-semibold mobile:h-[44px] mobile:rounded-[10px] mobile:px-4 mobile:w-1/2 mobile:text-base">
                                                    Discard
                                                </button>
                                                <button className="bg-button hover:bg-buttonHover transition-colors rounded-xl h-[56px] text-white text-[18px] leading-[22px] font-semibold px-6 mobile:h-[44px] mobile:rounded-[10px] mobile:px-4 mobile:w-1/2 mobile:text-base">
                                                    {isMobile ? 'Update' : 'Update Password'}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="grid grid-cols-3 w-full items-center gap-4 mobile:grid-cols-1 mobile:px-4">
                                <div className="flex flex-col gap-2 h-full">
                                    <p className="text-base text-text font-semibold">
                                        Email
                                    </p>
                                    <div className="w-full relative">
                                        <input
                                            type="text"
                                            value={user?.email || ''}
                                            placeholder="Email"
                                            readOnly
                                            className="h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-secondaryText text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] w-full disable-hover-input-settings"
                                        />
                                        {user?.providerData[0]?.providerId === 'google.com' ? (
                                            <img
                                                className="absolute top-1/2 right-4 translate-y-[-50%] w-[24px] cursor-pointer"
                                                src={Google}
                                                alt=""
                                            />
                                        ) : user?.providerData[0]?.providerId === 'facebook.com' ?
                                            (
                                                <img
                                                    className="absolute top-1/2 right-4 translate-y-[-50%] w-[24px] cursor-pointer"
                                                    src={Facebook}
                                                    alt=""
                                                />
                                            )
                                            : (
                                                <img
                                                    className="absolute top-1/2 right-4 translate-y-[-50%] w-[24px] cursor-pointer"
                                                    onClick={() => setEdit('email')}
                                                    src={Pen}
                                                    alt=""
                                                />
                                            )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <p className="text-base text-text font-semibold">
                                        Password
                                    </p>
                                    <div className="w-full relative">
                                        <input
                                            type="password"
                                            value="Password"
                                            placeholder="Password"
                                            readOnly
                                            className="h-[56px] bg-inputSecondaryBackground rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-secondaryText text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px] w-full disable-hover-input-settings"
                                        />
                                        {user?.providerData[0]?.providerId === 'google.com' ? (
                                            <img
                                                className="absolute top-1/2 right-4 translate-y-[-50%] w-[24px] cursor-pointer"
                                                src={Google}
                                                alt=""
                                            />
                                        ) : user?.providerData[0]?.providerId === 'facebook.com' ?
                                            (
                                                <img
                                                    className="absolute top-1/2 right-4 translate-y-[-50%] w-[24px] cursor-pointer"
                                                    src={Facebook}
                                                    alt=""
                                                />
                                            )
                                            : (
                                                <img
                                                    className="absolute top-1/2 right-4 translate-y-[-50%] w-[24px] cursor-pointer"
                                                    onClick={() => setEdit('password')}
                                                    src={Pen}
                                                    alt=""
                                                />
                                            )}
                                    </div>
                                    {user?.providerData[0]?.providerId === 'google.com' && (
                                        <p className="text-secondaryText text-[12px] leading-[18px]">
                                            Your password is managed through your Google account. To change your password, visit your <a className="text-button" href="https://myaccount.google.com/security" target="_blank" rel="noreferrer">Google Account Security</a> page.
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="flex-col gap-2 hidden mobile:flex mobile:px-4">
                            <p className="text-base text-text font-semibold">
                                Gender
                            </p>
                            <div className={`flex items-center w-full rounded-lg border-solid border-[1px] border-inputBorder h-[56px] mobile:h-[44px] ${edit ? 'pointer-events-none' : ''}`}>
                                <div
                                    onClick={() => setGender('male')}
                                    className={`w-1/2 h-full flex items-center justify-center border-solid border-e-[1px] border-inputBorder font-semibold text-base cursor-pointer rounded-s-lg mobile:text-base transition-colors ${gender === 'male' ? 'text-button outline outline-2 outline-button bg-inputTransparentBackground outline-offset-[-2px]' : 'input-focus-hover text-secondaryText hover:text-text'}`}
                                >
                                    Male
                                </div>
                                <div
                                    onClick={() => setGender('female')}
                                    className={`w-1/2 h-full flex items-center justify-center font-semibold text-base cursor-pointer rounded-e-lg mobile:text-base transition-colors ${gender === 'female' ? 'text-button outline outline-2 outline-button bg-inputTransparentBackground outline-offset-[-2px]' : 'input-focus-hover text-secondaryText hover:text-text'}`}
                                >
                                    Female
                                </div>
                            </div>
                        </div>
                        <div className="h-[1px] min-h-[1px] w-full bg-inputBorder mobile:w-[calc(100%-32px)] mobile:ms-4"></div>
                        <div className="flex items-center justify-start mobile:px-4 mobile:pb-[140px]">
                            <button onClick={handleDeleteUser} className="h-[44px] rounded-[10px] bg-inputBackground hover:bg-inputBorder transition-colors px-4 text-base font-semibold text-text flex items-center gap-2 mobile:w-full justify-center">
                                <img
                                    className={theme === 'light' ? '' : 'icon-to-white'}
                                    src={TrashBlack}
                                    alt=""
                                />
                                Delete Account
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center justify-end p-8 border-solid border-t-[1px] border-inputBorder gap-2 mobile:p-4 mobile:fixed mobile:left-0 mobile:bottom-0 mobile:w-full mobile:bg-background">
                        <button onClick={onClose} disabled={edit ? true : false} className="h-[56px] rounded-xl bg-inputBackground hover:bg-inputBorder transition-colors px-6 font-semibold text-[18px] leading-[22px] text-text disabled:opacity-30 disabled:hover:bg-inputBackground mobile:h-[44px] mobile:rounded-[10px] mobile:w-[44px] mobile:min-w-[44px] mobile:px-0 mobile:flex mobile:items-center mobile:justify-center">
                            {isMobile ? (
                                <img className={`w-[20px] ${theme === 'light' ? '' : 'icon-to-white'}`} src={ArrowBack} alt="" />
                            ) : (
                                'Discard'
                            )}
                        </button>
                        <button
                            disabled={isDisabled || edit ? true : false}
                            onClick={handleSaveChanges}
                            className="h-[56px] rounded-xl bg-button hover:bg-buttonHover transition-colors px-6 font-semibold text-white text-[18px] leading-[22px] disabled:opacity-30 disabled:hover:bg-button mobile:h-[44px] mobile:rounded-[10px] mobile:w-full"
                        >
                            Save
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-4 p-8 mobile:p-4 h-full">
                    <div className="flex items-center justify-between gap-4">
                        <h5 className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] text-text font-bold mobile:text-[19px] mobile:leading-[23px]">
                            Invoices
                        </h5>
                        {data && data.length > 0 && (
                            <button onClick={handleDownloadInvoices} className="h-[44px] rounded-[10px] px-4 bg-inputBackground hover:bg-inputBorder transition-colors text-text text-base font-semibold mobile:h-[32px] mobile:rounded-lg mobile:text-[14px] mobile:leading-[17px]">
                                Download All
                            </button>
                        )}
                    </div>
                    <div className="w-full border-solid border-[1px] border-inputBorder rounded-xl overflow-x-hidden overflow-y-auto custom-scroll max-h-[calc(100%-188px)] mobile:max-h-[calc(100vh-256px)] mobile:border-0 mobile:gap-4 mobile:flex mobile:flex-col mobile:pb-20">
                        <div className="bg-inputSecondaryBackground border-solid border-b-[1px] border-inputBorder grid grid-cols-5 h-[40px] mobile:hidden">
                            <div className="w-full h-full flex items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] font-semibold">
                                Order Date
                            </div>
                            <div className="w-full h-full flex items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] font-semibold">
                                Order Number
                            </div>
                            <div className="w-full h-full flex items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] font-semibold">
                                Plan
                            </div>
                            <div className="w-full h-full flex items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] font-semibold">
                                Payment Method
                            </div>
                            <div className="w-full h-full flex items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] font-semibold">
                                Status
                            </div>
                        </div>
                        {data && data?.length > 0 ? (
                            data.map((invoice) => (
                                <div key={invoice.id} className="grid grid-cols-5 h-[56px] border-solid border-b-[1px] border-inputBorder last:border-0 mobile:grid-cols-1 mobile:h-auto mobile:border-[1px] mobile:rounded-xl mobile:p-4 mobile:gap-2 mobile:last:border-[1px]">
                                    <div className="w-full mobile:grid mobile:grid-cols-2 gap-1">
                                        <div className="w-full h-full items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] mobile:px-0 hidden mobile:flex">
                                            Order Date
                                        </div>
                                        <div className="w-full h-full flex items-center justify-start px-4 text-text text-[14px] leading-[17px] mobile:p-0 mobile:font-semibold">
                                            {invoice.date}
                                        </div>
                                    </div>
                                    <div className="w-full mobile:grid mobile:grid-cols-2 gap-1">
                                        <div className="w-full h-full items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] mobile:px-0 hidden mobile:flex">
                                            Order Number
                                        </div>
                                        <div className="w-full h-full flex items-center justify-start px-4 text-text text-[14px] leading-[17px] mobile:p-0 break-all">
                                            {invoice.id}
                                        </div>
                                    </div>
                                    <div className="w-full mobile:grid mobile:grid-cols-2 gap-1">
                                        <div className="w-full h-full items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] mobile:px-0 hidden mobile:flex">
                                            Plan
                                        </div>
                                        <div className="w-full h-full flex items-center justify-start px-4 text-text text-[14px] leading-[17px] capitalize mobile:p-0">
                                            {invoice.plan}
                                        </div>
                                    </div>
                                    <div className="w-full mobile:grid mobile:grid-cols-2 gap-1">
                                        <div className="w-full h-full items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] mobile:px-0 hidden mobile:flex">
                                            Payment Method
                                        </div>
                                        <div className="w-full h-full flex items-center justify-start px-4 text-text text-[14px] leading-[17px] mobile:p-0">
                                            {invoice.method}
                                        </div>
                                    </div>
                                    <div className="w-full h-full flex items-center justify-between px-4 gap-2 mobile:p-0 mobile:flex-col">
                                        <div className="w-full mobile:grid mobile:grid-cols-2 gap-1">
                                            <div className="w-full h-full items-center justify-start px-4 text-secondaryText text-[14px] leading-[17px] mobile:px-0 hidden mobile:flex">
                                                Status
                                            </div>
                                            <div className="h-full flex items-center justify-start text-text text-[14px] leading-[17px] gap-1">
                                                <img src={Check} alt="" />
                                                {invoice.status === 'paid' || invoice.method === 'PayPal' ? 'Complete' : 'Pending'}
                                            </div>
                                        </div>
                                        {invoice?.method !== 'PayPal' && (
                                            <div className="flex items-center gap-4 min-w-fit mobile:w-full mobile:gap-2">
                                                {isMobile ? (
                                                    <>
                                                        <button onClick={() => handleViewSingleInvoice(invoice)} className="w-1/2 h-[32px] rounded-lg bg-inputBackground hover:bg-inputBorder transition-colors font-semibold text-[14px] leading-[17px] text-text flex items-center gap-2 justify-center">
                                                            <img
                                                                className={`w-[18px] ${theme === 'light' ? '' : 'icon-to-white'}`}
                                                                src={Eye}
                                                                alt=""
                                                            />
                                                            Preview
                                                        </button>
                                                        <button onClick={() => handleDownloadSingleDownload(invoice)} className="w-1/2 h-[32px] rounded-lg bg-inputBackground hover:bg-inputBorder transition-colors font-semibold text-[14px] leading-[17px] text-text flex items-center gap-2 justify-center">
                                                            <img
                                                                className={`w-[18px] ${theme === 'light' ? '' : 'icon-to-white'}`}
                                                                src={DownloadBlack}
                                                                alt=""
                                                            />
                                                            Download
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img
                                                            onClick={() => handleViewSingleInvoice(invoice)}
                                                            className="icon-to-secondary-text-color cursor-pointer w-[24px]"
                                                            src={Eye}
                                                            alt=""
                                                        />
                                                        <img
                                                            onClick={() => handleDownloadSingleDownload(invoice)}
                                                            className="icon-to-secondary-text-color cursor-pointer w-[24px]"
                                                            src={DownloadBlack}
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex items-center justify-center h-[40px] text-secondaryText text-[14px] leading-[17px] font-semibold">
                                No invoices found
                            </div>
                        )}
                    </div>
                </div>
            )}
            {showDefaultDeleteModal && (
                <DefaultDeleteUserModal
                    onClose={() => setShowDefaultDeleteModal(false)}
                    deleteAction={deleteAccountConfirmed}
                />
            )}
            {showGenerationsDeleteModal && userGeneration && (
                <GenerationDeleteUserModal
                    onClose={() => setShowGenerationsDeleteModal(false)}
                    generation={userGeneration}
                    deleteAction={deleteAccountConfirmed}
                />
            )}
        </Modal>
    )
}

export default ProfileSettingsModal