import { FC, useState } from "react"

import { useResetPasswordMutation } from "../../api/appApi"

import BackHeader from "./BackHeader"

type EnterEmailProps = {
    setEmail: (email: string) => void,
    setStep: (step: number) => void,
    email: string,
}

const EnterEmail: FC<EnterEmailProps> = ({ setStep, email, setEmail }) => {
    const [isError, setIsError] = useState<boolean>(false)

    const [resetPassword] = useResetPasswordMutation()

    const handleChangeEmail = (email: string) => {
        setEmail(email)
        setIsError(false)
    }

    const handleSendCode = () => {
        resetPassword({ email }).then((res) => {
            if ("error" in res) {
                setIsError(true)
            } else {
                setIsError(false)
                setStep(2)
            }
        })
    }

    return (
        <div className="max-w-[640px] w-full p-6 flex flex-col gap-6 bg-background rounded-[24px] border-solid border-[1px] border-inputBorder shadow-modal min-h-[404px] justify-between mobile:h-full mobile:rounded-none mobile:p-4">
            <div className="flex flex-col gap-6 w-full">
                <BackHeader title="Forgot Password?" />
                <div className="h-[1px] w-full bg-inputBorder mobile:hidden"></div>
                <div className="flex flex-col gap-2 w-full">
                    <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                        Email
                    </label>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => handleChangeEmail(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && handleSendCode()}
                        placeholder="Enter email address"
                        className={`h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] ${isError ? "outline-redButton outline outline-[2px] outline-offset-[-2px]" : ""}`}
                    />
                </div>
            </div>
            <div className="w-full">
                <button onClick={handleSendCode} disabled={!email} className="h-[56px] rounded-xl bg-button hover:bg-buttonHover transition-colors disabled:hover:bg-button disabled:opacity-20 text-[18px] leading-[22px] font-semibold text-white w-full mobile:h-[44px] mobile:text-base">
                    Send Code
                </button>
            </div>
        </div>
    )
}

export default EnterEmail