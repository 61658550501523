import { FC, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import imageCompression from "browser-image-compression"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
    appApi,
    useGetGenerationIdQuery,
    useGetImagesForGenerationQuery,
    useStartGenerationMutation,
    useUpdateGenerationMutation,
    useValidateImageMutation
} from "../api/appApi";
import { GenerationItem, GenerationUploadedImageItem } from "../utils/types";
import { getHeightAndWidthFromDataUrl } from "../utils/functions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { KEY_TO_BACKGROUND } from "../utils/constants";

const options = {
    maxWidthOrHeight: 1080,
    useWebWorker: true,
}

type CreationProviderProps = {
    children: React.ReactNode;
}

export type CreationSyncContentType = {
    generation_id: string,
    field: string,
    value: string | string[] | boolean,
}

type CreationStateType = {
    name: string,
    gender: 'male' | 'female' | '',
    ethnicity: string,
    backgroundStyles: string,
    outfitStyles: string,
    age: string,
    eye_color: string,
    hair_color: string,
}

type TempUserInformationType = {
    gender: string,
    age: string,
    eye_color: string,
    hair_color: string,
    ethnicity: string,
    outfitStyles: string
}

type CreationContextType = {
    state: CreationStateType;
    setState: (state: CreationStateType) => void;
    step: number;
    setStep: (step: number) => void;
    title: string,
    setTitle: (title: string) => void;
    requirementsAccepted: boolean,
    setRequirementsAccepted: (accepted: boolean) => void;
    syncData: (data: CreationSyncContentType, refetchCreationData?: boolean) => void;
    uploadedImages: (File | GenerationUploadedImageItem)[],
    setUploadedImages: (images: (File | GenerationUploadedImageItem)[]) => void;
    selectedUploadedImages: number[],
    setSelectedUploadedImages: (images: number[]) => void,
    generateImages: () => void,
    isLoading: boolean,
    setGenerationData: (generation: GenerationItem) => void,
    setId: (id: string) => void,
    id: string | null,
    isValidating: boolean,
    clearData: () => void,
    nonEditedTitle: string,
    stylesAvailable: number,
    isGenerationStarting: boolean,
    isEdit: boolean,
    setIsEdit: (isEdit: boolean) => void,
    isPreparingModel: boolean,
    setIsPreparingModel: (isPreparing: boolean) => void,
    isJustFinishedValidation: boolean,
    setIsJustFinishedValidation: (isJustFinishedValidation: boolean) => void,
    contentRef: React.MutableRefObject<HTMLDivElement | null>,
    doNotGoToNextStep: string | null,
    setDoNotGoToNextStep: (doNotGoToNextStep: string | null) => void,
    tempUserInformation: TempUserInformationType,
    setTempUserInformation: React.Dispatch<React.SetStateAction<TempUserInformationType>>,
    cancelUserInformationEdit: () => void,
    imagesValidating: number,
    setImagesValidating: (imagesValidating: number) => void,
    validatedImageIndex: number,
    setValidatedImageIndex: React.Dispatch<React.SetStateAction<number>>,
    trainingProgress: number,
    setTrainingProgress: React.Dispatch<React.SetStateAction<number>>,
    isCreationImagesLoading: boolean,
    isCreationDataLoading: boolean,
    isCreationImagesLoaded: boolean,
    isCompressingImages: boolean,
    setIsCompressingImages: React.Dispatch<React.SetStateAction<boolean>>
} | null;

const CreationContext = createContext<CreationContextType>(null);

export const useCreationContext = () => useContext(CreationContext);

export const CreationProvider: FC<CreationProviderProps> = ({ children }) => {
    const [uploadedImages, setUploadedImages] = useState<(File | GenerationUploadedImageItem)[]>([])
    const [isJustFinishedValidation, setIsJustFinishedValidation] = useState<boolean>(false)
    const [selectedUploadedImages, setSelectedUploadedImages] = useState<number[]>([])
    const [requirementsAccepted, setRequirementsAccepted] = useState<boolean>(false)
    const [doNotGoToNextStep, setDoNotGoToNextStep] = useState<string | null>(null)
    const [isCompressingImages, setIsCompressingImages] = useState<boolean>(false)
    const [validatedImageIndex, setValidatedImageIndex] = useState<number>(0)
    const [nonEditedTitle, setNonEditedTitle] = useState<string>('Untitled')
    const [trainingProgress, setTrainingProgress] = useState<number>(1)
    const [imagesValidating, setImagesValidating] = useState<number>(0)
    const [stylesAvailable, setStylesAvailable] = useState<number>(0)
    const [isValidating, setIsValidating] = useState<boolean>(false)
    const [isPreparingModel, setIsPreparingModel] = useState(false)
    const [title, setTitle] = useState<string>("Untitled")
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [id, setId] = useState<string | null>(null)
    const [step, setStep] = useState<number>(1);
    const [tempUserInformation, setTempUserInformation] = useState<TempUserInformationType>({
        gender: "",
        age: "",
        eye_color: "",
        hair_color: "",
        ethnicity: "",
        outfitStyles: ""
    })
    const [state, setState] = useState<CreationStateType>({
        name: "",
        gender: "",
        ethnicity: "",
        backgroundStyles: "",
        outfitStyles: "",
        age: "",
        eye_color: "",
        hair_color: ""
    })
    const subscription = useAppSelector(state => state.app.subscription)

    const contentRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [updateGeneration, { isLoading }] = useUpdateGenerationMutation()
    const [startGeneration, { isLoading: isGenerationStarting }] = useStartGenerationMutation()
    const [validateImages, { isLoading: isValidatingRequest, isSuccess }] = useValidateImageMutation()
    const {
        data: creationImages,
        isSuccess: isCreationImagesLoaded,
        isLoading: isCreationImagesLoading,
        isFetching: isCreationImagesFetching
    } = useGetImagesForGenerationQuery(
        id ? id : '',
        {
            skip: !id || id === '0',
            refetchOnMountOrArgChange: true
        }
    )

    const shouldFetchGenerationData = id && isCreationImagesLoaded && !isCreationImagesFetching && !isCreationImagesLoading;

    const {
        data: creationData,
        refetch,
        isLoading: isCreationDataLoading
    } = useGetGenerationIdQuery(
        id ? id : '',
        {
            skip: !shouldFetchGenerationData,
            refetchOnMountOrArgChange: true
        }
    )

    const cancelUserInformationEdit = async () => {
        if (!id) return

        await updateGeneration({
            id: id,
            data: {
                styles: `${tempUserInformation.outfitStyles},${state.backgroundStyles}`
            }
        })
        await updateGeneration({
            id: id, data: {
                gender: tempUserInformation.gender === 'male' ? 'man' : 'woman',
                age: tempUserInformation.age,
                eye_color: tempUserInformation.eye_color,
                hair_color: tempUserInformation.hair_color,
                ethnicity: tempUserInformation.ethnicity,
            }
        })

        setTempUserInformation({
            gender: "",
            age: "",
            eye_color: "",
            hair_color: "",
            ethnicity: "",
            outfitStyles: ""
        })
        setIsEdit(false)
        setStep(9)
    }

    const syncData = useCallback(async (data: CreationSyncContentType, refetchCreationData = false) => {
        const newData = {
            [data.field]: data.value
        }

        const res = await updateGeneration({ id: data.generation_id, data: newData })

        if (refetchCreationData) {
            refetch()
        }
        return res
    }, [updateGeneration, refetch])

    const generateImages = async () => {
        if (!id) return null

        startGeneration(id).then((res) => {
            if ('error' in res) {
                toast.error('An error occurred while generating images')
            } else {
                clearData()
                navigate('/')
            }
        })
    }

    const clearData = () => {
        setStep(1)
        setTitle('Untitled')
        setState({
            name: '',
            gender: '',
            ethnicity: '',
            backgroundStyles: '',
            outfitStyles: '',
            age: '',
            eye_color: '',
            hair_color: ''
        })
        setUploadedImages([])
        setSelectedUploadedImages([])
        setRequirementsAccepted(false)
        setDoNotGoToNextStep(null)
        setIsEdit(false)
        dispatch(appApi.util.invalidateTags(['GenerationUploadedImages']))
    }

    const setGenerationData = useCallback((generation: GenerationItem) => {
        const backgroundStyles = generation.styles?.split(',').filter((style) => {
            return KEY_TO_BACKGROUND[style as keyof typeof KEY_TO_BACKGROUND]
        })
        const outfitStyles = generation.styles?.split(',').filter((style) => {
            return !KEY_TO_BACKGROUND[style as keyof typeof KEY_TO_BACKGROUND]
        })
        const planName = subscription?.plan || subscription?.role

        setTitle(generation.title)
        setNonEditedTitle(generation.title)
        setRequirementsAccepted(generation.requirements_accepted)
        setStylesAvailable(
            planName === 'basic'
                ? 2
                : planName === 'standard'
                    ? 4
                    : planName === 'premium'
                        ? 6
                        : 2
        )
        setState({
            name: generation.name || '',
            gender:
                generation.gender && generation.gender === 'man'
                    ? 'male'
                    : generation.gender && generation.gender === 'woman'
                        ? 'female'
                        : '',
            ethnicity: generation.ethnicity || '',
            backgroundStyles: backgroundStyles?.length ? backgroundStyles.join(',') : '',
            outfitStyles: outfitStyles?.length ? outfitStyles.join(',') : '',
            age: generation.age || '',
            eye_color: generation.eye_color || '',
            hair_color: generation.hair_color || ''
        })

        if (doNotGoToNextStep === id && isCreationImagesLoaded && !isCreationImagesLoading && !isCreationDataLoading) return;

        if (subscription?.active_payments && generation.hair_color && generation.eye_color && generation.age && backgroundStyles?.length && outfitStyles?.length && generation.ethnicity && generation.gender && creationImages && creationImages.length >= 6 && creationImages.every((image) => image.is_valid) && generation?.requirements_accepted) {
            setStep(9)
        } else if (subscription?.active_payments && generation.hair_color && generation.eye_color && generation.age && backgroundStyles?.length && generation.ethnicity && generation.gender && creationImages && creationImages.length >= 6 && creationImages.every((image) => image.is_valid) && generation?.requirements_accepted) {
            setStep(8)
        } else if (subscription?.active_payments && generation.hair_color && generation.eye_color && generation.age && generation.ethnicity && generation.gender && creationImages && creationImages.length >= 6 && creationImages.every((image) => image.is_valid) && generation?.requirements_accepted) {
            setStep(7)
        } else if (generation.hair_color && generation.eye_color && generation.age && generation.ethnicity && generation.gender) {
            setStep(6)
        } else if (generation.hair_color && generation.eye_color && generation.age && generation.gender) {
            setStep(5)
        } else if (generation.eye_color && generation.age && generation.gender) {
            setStep(4)
        } else if (generation.age && generation.gender) {
            setStep(3)
        } else if (generation.gender) {
            setStep(2)
        } else {
            setStep(1)
        }

        setDoNotGoToNextStep(generation.id)
    }, [
        subscription,
        creationImages,
        doNotGoToNextStep,
        isCreationImagesLoaded,
        id,
        isCreationImagesLoading,
        isCreationDataLoading
    ])

    useEffect(() => {
        if (isSuccess) {
            setIsJustFinishedValidation(true)

            setTimeout(() => {
                setIsJustFinishedValidation(false)
            }, 4000)
        }
    }, [isSuccess])

    useEffect(() => {
        if (isValidatingRequest) {
            setIsValidating(true)
            setIsCompressingImages(false)
        } else {
            setIsValidating(false)
            setIsCompressingImages(false)
        }
    }, [isValidatingRequest])

    useEffect(() => {
        (async () => {
            const filteredImages = uploadedImages.filter((image): image is File => image instanceof File);
            const onlyImageTypeFiles = filteredImages.filter((image) => image.type.includes('image'))

            if (filteredImages.length !== onlyImageTypeFiles.length) {
                const savedImage = uploadedImages.filter((image) => !(image instanceof File))

                setUploadedImages([...savedImage, ...onlyImageTypeFiles])
                return;
            }

            if (filteredImages.length > 0 && id) {
                setIsCompressingImages(true)
                setValidatedImageIndex(0)
                setTrainingProgress(1)
                const formData = new FormData();

                for (const image of filteredImages) {
                    let newMinimalSideCompressionSide: null | number = null;
                    const minimalWidth = 512;

                    const { width, height } = await getHeightAndWidthFromDataUrl(URL.createObjectURL(image))

                    if (width < 1080 && height < 1080) {
                        formData.append("images", image);
                        continue;
                    }

                    const smallerSideSize = width > height ? height : width;
                    const biggerSideSize = width > height ? width : height;

                    const biggerSideOnePercent = biggerSideSize / 100;
                    const smallerSideOnePercent = smallerSideSize / 100;
                    const percentOfCompressionBiggerSide = 100 - (1080 / biggerSideOnePercent);

                    const compressedSmallerSide = smallerSideSize - (smallerSideSize / 100 * percentOfCompressionBiggerSide);

                    if (compressedSmallerSide < minimalWidth) {
                        const differenceBetweenMinWidthAndSmallerSide = minimalWidth - compressedSmallerSide;
                        const adjustPercents = differenceBetweenMinWidthAndSmallerSide / smallerSideOnePercent;

                        const newPercentOfCompressionBiggerSide = Math.round(percentOfCompressionBiggerSide - adjustPercents - 1);
                        newMinimalSideCompressionSide = Math.round(biggerSideSize - (biggerSideSize / 100 * newPercentOfCompressionBiggerSide));
                    }

                    const compressedFile = await imageCompression(image, {
                        ...options,
                        maxWidthOrHeight: newMinimalSideCompressionSide || 1080
                    });
                    const compressedImage = new File([compressedFile], compressedFile.name, { type: compressedFile.type });
                    formData.append("images", compressedImage);
                }

                validateImages({ formData, id })
                setImagesValidating(filteredImages.length)
            }
        })()
    }, [uploadedImages, validateImages, id])

    useEffect(() => {
        if (creationData && isCreationImagesLoaded && !isCreationDataLoading && !isCreationImagesLoading) {
            setGenerationData(creationData)
        }
    }, [creationData, setGenerationData, isCreationImagesLoaded, isCreationDataLoading, isCreationImagesLoading])

    useEffect(() => {
        if (creationImages && creationImages?.length > 0) {
            setUploadedImages([...creationImages])
        } else {
            setUploadedImages([])
        }
    }, [creationImages])

    const value: CreationContextType = {
        state, setState, step, setStep, title, setTitle,
        requirementsAccepted, setRequirementsAccepted, syncData,
        uploadedImages, setUploadedImages, selectedUploadedImages,
        setSelectedUploadedImages, generateImages, isLoading,
        setGenerationData, setId, id, isValidating, clearData,
        nonEditedTitle, stylesAvailable, isGenerationStarting,
        isEdit, setIsEdit, isPreparingModel, setIsPreparingModel,
        isJustFinishedValidation, setIsJustFinishedValidation,
        contentRef, doNotGoToNextStep, setDoNotGoToNextStep,
        tempUserInformation, setTempUserInformation,
        cancelUserInformationEdit, imagesValidating, setImagesValidating,
        validatedImageIndex, setValidatedImageIndex, trainingProgress,
        setTrainingProgress, isCreationImagesLoading, isCreationDataLoading,
        isCreationImagesLoaded, isCompressingImages, setIsCompressingImages
    }

    return (
        <CreationContext.Provider value={value}>
            {children}
        </CreationContext.Provider>
    );
}