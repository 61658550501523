import React, { useEffect } from 'react';
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { CreationProvider } from './context/CreationContext';
import { AlbumProvider } from './context/AlbumContext';
import { AuthProvider } from './context/AuthContext';
import { store } from './redux/store';

import App from './App';

import './index.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <CreationProvider>
          <AlbumProvider>
            <App />
          </AlbumProvider>
        </CreationProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);