import { FC } from "react"

import { checkEstimatedTimeLeft } from "../../utils/functions"
import { GenerationItem } from "../../utils/types"

import ProgressSpinner from "../../assets/progress-spinner.svg"
import CompleteCheck from '../../assets/complete-check.svg'

type AlbumItemStatusProps = {
    album: GenerationItem
}

const AlbumItemStatus: FC<AlbumItemStatusProps> = ({ album }) => {
    return (
        <>
            {album.completed && album.additional_generation !== 'pending' ? (
                <div
                    className="flex items-center gap-1 px-[10px] h-[24px] rounded-[8px] text-button bg-inputTransparentBackground text-[12px] leading-[18px] mobile:w-fit min-w-max whitespace-nowrap"
                >
                    <img src={CompleteCheck} alt="" />
                    Complete
                </div>
            ) : (
                <span className="text-end text-secondaryText text-[18px] leading-[22px]">
                    {album.is_draft ? (
                        <div className="h-[24px] rounded-[8px] px-2 bg-[#FF7A001A] flex items-center gap-2 text-[#FF7A00] text-[12px] leading-[18px] font-semibold w-fit whitespace-nowrap min-w-max">
                            <img width={14} src={ProgressSpinner} alt="" />
                            In Progress
                        </div>
                    ) : (
                        <div className="text-base text-secondaryText mobile:text-[12px] mobile:leading-[18px] mobile:whitespace-nowrap min-w-max whitespace-nowrap">
                            {checkEstimatedTimeLeft(album.eta)}
                        </div>
                    )}
                </span>
            )}
        </>
    )
}

export default AlbumItemStatus