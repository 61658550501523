import { MouseEvent, useRef } from "react"

import { useAlbumContext } from "../../context/AlbumContext"
import { useAppSelector } from "../../redux/hooks"

import HeartBlackFilled from '../../assets/heart-black-filled.svg'
import DownloadWhite from '../../assets/download-white.svg'
import DownloadBlack from '../../assets/download-black.svg'
import Heart from '../../assets/heart.svg'

const PhotoOptionsMobile = () => {
    const theme = useAppSelector((state) => state.app.theme)

    const albumCtx = useAlbumContext()

    const contentRef = useRef<HTMLDivElement>(null)

    const handleClickOutsideContent = (event: MouseEvent<HTMLDivElement>) => {
        if (contentRef.current && !contentRef.current.contains(event?.target as Node)) {
            albumCtx?.setOpenPhotoOptions(false)
        }
    }

    return (
        <div onClick={(e) => handleClickOutsideContent(e)} className="fixed bottom-0 left-0 w-full h-[100vh] hidden mobile:flex flex-col justify-end z-[14] bg-[#00000033]">
            <div ref={contentRef} className="w-full bg-background flex flex-col rounded-t-xl border-solid border-t-[1px] border-inputBorder upload-top-box-shadow">
                <div className="flex items-center justify-center flex-col p-1 pb-4 gap-[6px] border-solid border-b-[1px] border-inputBorder">
                    <div onClick={() => albumCtx?.setOpenPhotoOptions(false)} className="w-[52px] h-[4px] rounded-xl bg-inputBorder"></div>
                    <h5 className="font-[Sora] text-[19px] leading-[23px] font-bold text-text">
                        {albumCtx?.selectedImages && albumCtx?.selectedImages.length > 0 ? `${albumCtx?.selectedImages.length} Photo${albumCtx?.selectedImages.length > 1 ? 's' : ''} Selected` : 'Photo Options'}
                    </h5>
                </div>
                <div className="flex flex-col p-4 gap-2">
                    <button onClick={() => albumCtx?.handleDownloadSelected()} className="h-[44px] rounded-[10px] flex items-center gap-2 text-background bg-text justify-center hover:bg-secondaryText transition-colors text-base font-semibold">
                        {theme === 'light' ? (
                            <img width={20} src={DownloadWhite} alt="" />
                        ) : (
                            <img width={20} src={DownloadBlack} alt="" />
                        )}
                        Download {albumCtx?.selectedImages && albumCtx?.selectedImages.length > 0 ? 'Selected' : 'All'}
                    </button>
                    {albumCtx?.showFavorite ? (
                        <button onClick={() => albumCtx?.handleFavoriteSelected()} className="h-[44px] rounded-[10px] flex items-center justify-center gap-2 text-text bg-inputBackground hover:bg-inputBorder transition-colors text-base font-semibold">
                            <img
                                className={theme === 'light' ? '' : 'icon-to-white'}
                                src={Heart}
                                alt=""
                            />
                            Favorite {albumCtx?.selectedImages && albumCtx?.selectedImages.length > 0 ? 'Selected' : 'All'}
                        </button>
                    ) : (
                        <button onClick={() => albumCtx?.handleUnfavoriteSelected()} className="h-[44px] rounded-[10px] flex items-center justify-center gap-2 text-background bg-redButton hover:bg-redButtonHover transition-colors text-base font-semibold">
                            <img
                                className={theme === 'light' ? 'icon-to-white' : ''}
                                src={HeartBlackFilled}
                                alt=""
                            />
                            Unfavorite {albumCtx?.selectedImages && albumCtx?.selectedImages.length > 0 ? 'Selected' : 'All'}
                        </button>
                    )}
                    <button onClick={() => albumCtx?.setOpenPhotoOptions(false)} className="h-[44px] flex items-center justify-center text-text bg-background text-base font-semibold">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PhotoOptionsMobile