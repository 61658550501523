// Requirements Images
import CreationRequirementsNoRevealingClosing from '../assets/creation/requirements/no-revealing-closing.png'
import CreationRequirementsNoAccessories from '../assets/creation/requirements/no-accessories.png'
import CreationRequirementsNoGoofyFaces from '../assets/creation/requirements/no-goofy-faces.png'
import CreationRequirementsNoSideShots from '../assets/creation/requirements/no-side-shots.png'
import CreationRequirementsEyeContact from '../assets/creation/requirements/eye-contact.png'
import CreationRequirementsVariety from '../assets/creation/requirements/variety.png'
import CreationRequirementsRecent from '../assets/creation/requirements/recent.png'
import CreationRequirementsClear from '../assets/creation/requirements/clear.png'

// Generation Styles Example Images
import HoodiesSweatshirtsFemale from '../assets/examples/hoodies-sweatshirts-female.jpg'
import BusinessDressShirtsMale from '../assets/examples/business-dress-shirts-male.jpg'
import DenimChambrayShirtsMale from '../assets/examples/denim-chambray-shirts-male.jpg'
import SweatersCardigansFemale from '../assets/examples/sweaters-cardigans-female.jpg'
import AbandonedBuildsFemale from '../assets/examples/abandoned-buildings-female.jpg'
import AntiqueInteriorsFemale from '../assets/examples/antique-interiors-female.jpg'
import AbandonedBuildingsMale from '../assets/examples/abandoned-buildings-male.jpg'
import HoodiesSweatshirtsMale from '../assets/examples/hoodies-sweatshirts-male.jpg'
import CoWorkingSpacesFemale from '../assets/examples/co-working-spaces-female.jpg'
import SidewalksAlleysFemale from '../assets/examples/sidewalks-alleys-female.jpg'
import CollegeCampusesFemale from '../assets/examples/college-campuses-female.jpg'
import JoggersSweatpantsMale from '../assets/examples/joggers-sweatpants-male.jpg'
import BusinessBlousesFemale from '../assets/examples/business-blouses-female.jpg'
import PreppyPoloShirtsMale from '../assets/examples/preppy-polo-shirts-male.jpg'
import AntiqueInteriorsMale from '../assets/examples/antique-interiors-male.jpg'
import FlowersGardensFemale from '../assets/examples/flowers-gardens-female.jpg'
import FestivalsFairsFemale from '../assets/examples/festivals-fairs-female.jpg'
import QualityFabricsFemale from '../assets/examples/quality-fabrics-female.jpg'
import CoWorkingSpacesMale from '../assets/examples/co-working-spaces-male.jpg'
import HoodiesZipUpsFemale from '../assets/examples/hoodies-zip-ups-female.jpg'
import CasualDressesFemale from '../assets/examples/casual-dresses-female.jpg'
import GraffitiWallsFemale from '../assets/examples/graffiti-walls-female.jpg'
import SidewalksAlleysMale from '../assets/examples/sidewalks-alleys-male.jpg'
import NeutralColorsFemale from '../assets/examples/neutral-colors-female.jpg'
import CollegeCampusesMale from '../assets/examples/college-campuses-male.jpg'
import SimpleDesignsFemale from '../assets/examples/simple-designs-female.jpg'
import FlowersGardensMale from '../assets/examples/flowers-gardens-male.jpg'
import FestivalsFairsMale from '../assets/examples/festivals-fairs-male.jpg'
import ModernOfficeFemale from '../assets/examples/modern-office-female.jpg'
import DiningSpacesFemale from '../assets/examples/dining-spaces-female.jpg'
import QualityFabricsMale from '../assets/examples/quality-fabrics-male.jpg'
import HoodiesZipUpsMale from '../assets/examples/hoodies-zip-ups-male.jpg'
import LakesRiversFemale from '../assets/examples/lakes-rivers-female.jpg'
import GraffitiWallsMale from '../assets/examples/graffiti-walls-male.jpg'
import SandyShoresFemale from '../assets/examples/sandy-shores-female.jpg'
import SweatersVestsMale from '../assets/examples/sweaters-vests-male.jpg'
import CoffeeShopsFemale from '../assets/examples/coffee-shops-female.jpg'
import NeutralColorsMale from '../assets/examples/neutral-colors-male.jpg'
import SimpleDesignsMale from '../assets/examples/simple-designs-male.jpg'
import CapsBeaniesFemale from '../assets/examples/caps-beanies-female.jpg'
import GraphicTeesFemale from '../assets/examples/graphic-tees-female.jpg'
import BasicPiecesFemale from '../assets/examples/basic-pieces-female.jpg'
import AccessoriesFemale from '../assets/examples/accessories-female.jpg'
import ModernOfficeMale from '../assets/examples/modern-office-male.jpg'
import LivingRoomFemale from '../assets/examples/living-room-female.jpg'
import OceanViewsFemale from '../assets/examples/ocean-views-female.jpg'
import RedCarpetsFemale from '../assets/examples/red-carpets-female.jpg'
import DiningSpacesMale from '../assets/examples/dining-spaces-male.jpg'
import PoloShirtsFemale from '../assets/examples/polo-shirts-female.jpg'
import CitySpacesFemale from '../assets/examples/cityspaces-female.jpg'
import BoardroomsFemale from '../assets/examples/boardrooms-female.jpg'
import IndustrialFemale from '../assets/examples/industrial-female.jpg'
import BoardwalksFemale from '../assets/examples/boardwalks-female.jpg'
import WarehousesFemale from '../assets/examples/warehouses-female.jpg'
import ClassroomsFemale from '../assets/examples/classrooms-female.jpg'
import ActivewearFemale from '../assets/examples/activewear-female.jpg'
import LakesRiversMale from '../assets/examples/lakes-rivers-male.jpg'
import SandyShoresMale from '../assets/examples/sandy-shores-male.jpg'
import CoffeeShopsMale from '../assets/examples/coffee-shops-male.jpg'
import DressShirtsMale from '../assets/examples/dress-shirts-male.jpg'
import CapsBeaniesMale from '../assets/examples/caps-beanies-male.jpg'
import BasicPiecesMale from '../assets/examples/basic-pieces-male.jpg'
import BallroomsFemale from '../assets/examples/ballrooms-female.jpg'
import LibrariesFemale from '../assets/examples/libraries-female.jpg'
import OldBarnsFemale from '../assets/examples/old-barns-female.jpg'
import LivingRoomMale from '../assets/examples/living-room-male.jpg'
import OceanViewsMale from '../assets/examples/ocean-views-male.jpg'
import RedCarpetsMale from '../assets/examples/red-carpets-male.jpg'
import PoloShirtsMale from '../assets/examples/polo-shirts-male.jpg'
import CitySpacesMale from '../assets/examples/cityspaces-male.jpg'
import GreeneryFemale from '../assets/examples/greenery-female.jpg'
import BoardroomsMale from '../assets/examples/boardrooms-male.jpg'
import KitchensFemale from '../assets/examples/kitchens-female.jpg'
import BedroomsFemale from '../assets/examples/bedrooms-female.jpg'
import IndustrialMale from '../assets/examples/industrial-male.jpg'
import BoardwalksMale from '../assets/examples/boardwalks-male.jpg'
import WarehousesMale from '../assets/examples/warehouses-male.jpg'
import ClassroomsMale from '../assets/examples/classrooms-male.jpg'
import ActivewearMale from '../assets/examples/activewear-male.jpg'
import BallroomsMale from '../assets/examples/ballrooms-male.jpg'
import BlousesFemale from '../assets/examples/blouses-female.jpg'
import LibrariesMale from '../assets/examples/libraries-male.jpg'
import DressesFemale from '../assets/examples/dresses-female.jpg'
import BlazersFemale from '../assets/examples/blazers-female.jpg'
import OldBarnsMale from '../assets/examples/old-barns-male.jpg'
import KitchensMale from '../assets/examples/kitchens-male.jpg'
import GreeneryMale from '../assets/examples/greenery-male.jpg'
import BedroomsMale from '../assets/examples/bedrooms-male.jpg'
import TShirtsMale from '../assets/examples/t-shirts-male.jpg'
import BlackFemale from '../assets/examples/black-female.jpg'
import WhiteFemale from '../assets/examples/white-female.jpg'
import BlazersMale from '../assets/examples/blazers-male.jpg'
import JacketsMale from '../assets/examples/jackets-male.jpg'
import SuitsFemale from '../assets/examples/suits-female.jpg'
import BarsFemale from '../assets/examples/bars-female.jpg'
import GrayFemale from '../assets/examples/gray-female.jpg'
import TopsFemale from '../assets/examples/tops-female.jpg'
import WhiteMale from '../assets/examples/white-male.jpg'
import BlackMale from '../assets/examples/black-male.jpg'
import SuitsMale from '../assets/examples/suits_male.jpg'
import BarsMale from '../assets/examples/bars-male.jpg'
import GrayMale from '../assets/examples/gray-male.jpg'

export const PLANS = [
    {
        id: 1,
        name: 'Basic',
        description: 'Get 40 headshots with 2 unique backgrounds and outfits.',
        price: 39,
        stripe_price: process.env.REACT_APP_STRIPE_BASIC_PRICE_ID || '',
        tag: '',
    },
    {
        id: 2,
        name: 'Standard',
        description: 'Get 100 headshots with 4 unique backgrounds and outfits.',
        price: 47,
        stripe_price: process.env.REACT_APP_STRIPE_STANDARD_PRICE_ID || '',
        tag: 'Most Popular',
    },
    {
        id: 3,
        name: 'Premium',
        description: 'Get 200 headshots with 8 unique backgrounds and outfits.',
        price: 55,
        stripe_price: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID || '',
        tag: 'Best Value',
    },
]

export const ADDITIONAL_PLAN_INFO = {
    name: 'Additional',
    price: 19,
    stripe_price: process.env.REACT_APP_STRIPE_ADDITIONAL_PRICE_ID || '',
}

export const ETHNICITY = [
    "African",
    "Asian",
    "European",
    "Middle Eastern",
    "North and Central American",
    "South American",
    "Oceanian",
    "Caribbean",
    "South Asian"
]

export const BACKGROUND_IMAGES = {
    'Cityscapes': {
        male: CitySpacesMale,
        female: CitySpacesFemale
    },
    'Graffiti Walls': {
        male: GraffitiWallsMale,
        female: GraffitiWallsFemale
    },
    'Sidewalks and Alleys': {
        male: SidewalksAlleysMale,
        female: SidewalksAlleysFemale
    },
    'Greenery': {
        male: GreeneryMale,
        female: GreeneryFemale
    },
    'Flowers and Gardens': {
        male: FlowersGardensMale,
        female: FlowersGardensFemale
    },
    'Lakes and Rivers': {
        male: LakesRiversMale,
        female: LakesRiversFemale
    },
    'Modern Office': {
        male: ModernOfficeMale,
        female: ModernOfficeFemale
    },
    'Boardrooms': {
        male: BoardroomsMale,
        female: BoardroomsFemale
    },
    'Co-working Spaces': {
        male: CoWorkingSpacesMale,
        female: CoWorkingSpacesFemale
    },
    'Living Rooms': {
        male: LivingRoomMale,
        female: LivingRoomFemale
    },
    'Kitchens': {
        male: KitchensMale,
        female: KitchensFemale
    },
    'Bedrooms': {
        male: BedroomsMale,
        female: BedroomsFemale
    },
    'Old Barns': {
        male: OldBarnsMale,
        female: OldBarnsFemale
    },
    'Antique Interiors': {
        male: AntiqueInteriorsMale,
        female: AntiqueInteriorsFemale
    },
    'Industrial': {
        male: IndustrialMale,
        female: IndustrialFemale
    },
    'Sandy Shores': {
        male: SandyShoresMale,
        female: SandyShoresFemale
    },
    'Boardwalks': {
        male: BoardwalksMale,
        female: BoardwalksFemale
    },
    'Ocean Views': {
        male: OceanViewsMale,
        female: OceanViewsFemale
    },
    'Red Carpets': {
        male: RedCarpetsMale,
        female: RedCarpetsFemale
    },
    'Ballrooms': {
        male: BallroomsMale,
        female: BallroomsFemale
    },
    'Festivals/Fairs': {
        male: FestivalsFairsMale,
        female: FestivalsFairsFemale
    },
    'Abandoned Buildings': {
        male: AbandonedBuildingsMale,
        female: AbandonedBuildsFemale
    },
    'Warehouses': {
        male: WarehousesMale,
        female: WarehousesFemale
    },
    'Classrooms': {
        male: ClassroomsMale,
        female: ClassroomsFemale
    },
    'Libraries': {
        male: LibrariesMale,
        female: LibrariesFemale
    },
    'College Campuses': {
        male: CollegeCampusesMale,
        female: CollegeCampusesFemale
    },
    'Coffee Shops': {
        male: CoffeeShopsMale,
        female: CoffeeShopsFemale
    },
    'Dining Spaces': {
        male: DiningSpacesMale,
        female: DiningSpacesFemale
    },
    'Bars': {
        male: BarsMale,
        female: BarsFemale
    },
    'Black': {
        male: BlackMale,
        female: BlackFemale
    },
    'White': {
        male: WhiteMale,
        female: WhiteFemale
    },
    'Gray': {
        male: GrayMale,
        female: GrayFemale
    }
}

export const BACKGROUNDS = {
    'Urban/City Street': [
        'Cityscapes',
        'Graffiti Walls',
        'Sidewalks and Alleys',
    ],
    'Park/Nature Backgrounds': [
        'Greenery',
        'Flowers and Gardens',
        'Lakes and Rivers'
    ],
    'Office/Workspace': [
        'Modern Office',
        'Boardrooms',
        'Co-working Spaces'
    ],
    'Home/Indoor': [
        'Living Rooms',
        'Kitchens',
        'Bedrooms'
    ],
    'Vintage/Rustic': [
        'Old Barns',
        'Antique Interiors',
        'Industrial',
    ],
    'Beach/Seaside': [
        'Sandy Shores',
        'Boardwalks',
        'Ocean Views',
    ],
    'Event/Party': [
        'Red Carpets',
        'Ballrooms',
        'Festivals/Fairs',
    ],
    'Industrial/Urban Decay': [
        'Abandoned Buildings',
        'Warehouses',
    ],
    'School/Educational': [
        'Classrooms',
        'Libraries',
        'College Campuses',
    ],
    'Cafe/Restaurant': [
        'Coffee Shops',
        'Dining Spaces',
        'Bars',
    ],
    'Colors': [
        'Black',
        'White',
        'Gray'
    ]
}

export const KEY_TO_BACKGROUND = {
    'cityscapes': {
        type: 'Urban/City Street',
        style: 'Cityscapes'
    },
    'graffiti_walls': {
        type: 'Urban/City Street',
        style: 'Graffiti Walls'
    },
    'sidewalks_alleys': {
        type: 'Urban/City Street',
        style: 'Sidewalks and Alleys'
    },
    'greenery': {
        type: 'Park/Nature Backgrounds',
        style: 'Greenery'
    },
    'flowers_gardens': {
        type: 'Park/Nature Backgrounds',
        style: 'Flowers and Gardens'
    },
    'lakes_rivers': {
        type: 'Park/Nature Backgrounds',
        style: 'Lakes and Rivers'
    },
    'modern_office': {
        type: 'Office/Workspace',
        style: 'Modern Office'
    },
    'boardrooms': {
        type: 'Office/Workspace',
        style: 'Boardrooms'
    },
    'co_working_spaces': {
        type: 'Office/Workspace',
        style: 'Co-working Spaces'
    },
    'living_rooms': {
        type: 'Home/Indoor',
        style: 'Living Rooms'
    },
    'kitchens': {
        type: 'Home/Indoor',
        style: 'Kitchens'
    },
    'bedrooms': {
        type: 'Home/Indoor',
        style: 'Bedrooms'
    },
    'old_barns': {
        type: 'Vintage/Rustic',
        style: 'Old Barns'
    },
    'antique_interiors': {
        type: 'Vintage/Rustic',
        style: 'Antique Interiors'
    },
    'industrial': {
        type: 'Vintage/Rustic',
        style: 'Industrial'
    },
    'sandy_shores': {
        type: 'Beach/Seaside',
        style: 'Sandy Shores'
    },
    'boardwalks': {
        type: 'Beach/Seaside',
        style: 'Boardwalks'
    },
    'ocean_views': {
        type: 'Beach/Seaside',
        style: 'Ocean Views'
    },
    'red_carpets': {
        type: 'Event/Party',
        style: 'Red Carpets'
    },
    'ballrooms': {
        type: 'Event/Party',
        style: 'Ballrooms'
    },
    'festivals_fairs': {
        type: 'Event/Party',
        style: 'Festivals/Fairs'
    },
    'abandoned_buildings': {
        type: 'Industrial/Urban Decay',
        style: 'Abandoned Buildings'
    },
    'warehouses': {
        type: 'Industrial/Urban Decay',
        style: 'Warehouses'
    },
    'classrooms': {
        type: 'School/Educational',
        style: 'Classrooms'
    },
    'libraries': {
        type: 'School/Educational',
        style: 'Libraries'
    },
    'college_campuses': {
        type: 'School/Educational',
        style: 'College Campuses'
    },
    'coffee_shops': {
        type: 'Cafe/Restaurant',
        style: 'Coffee Shops'
    },
    'dining_spaces': {
        type: 'Cafe/Restaurant',
        style: 'Dining Spaces'
    },
    'bars': {
        type: 'Cafe/Restaurant',
        style: 'Bars'
    },
    'black': {
        type: 'Colors',
        style: 'Black'
    },
    'white': {
        type: 'Colors',
        style: 'White'
    },
    'gray': {
        type: 'Colors',
        style: 'Gray'
    }
}

export const BACKGROUND_TO_KEY = {
    'Urban/City Street': {
        'Cityscapes': 'cityscapes',
        'Graffiti Walls': 'graffiti_walls',
        'Sidewalks and Alleys': 'sidewalks_alleys',
    },
    'Park/Nature Backgrounds': {
        'Greenery': 'greenery',
        'Flowers and Gardens': 'flowers_gardens',
        'Lakes and Rivers': 'lakes_rivers'
    },
    'Office/Workspace': {
        'Modern Office': 'modern_office',
        'Boardrooms': 'boardrooms',
        'Co-working Spaces': 'co_working_spaces'
    },
    'Home/Indoor': {
        'Living Rooms': 'living_rooms',
        'Kitchens': 'kitchens',
        'Bedrooms': 'bedrooms'
    },
    'Vintage/Rustic': {
        'Old Barns': 'old_barns',
        'Antique Interiors': 'antique_interiors',
        'Industrial': 'industrial',
    },
    'Beach/Seaside': {
        'Sandy Shores': 'sandy_shores',
        'Boardwalks': 'boardwalks',
        'Ocean Views': 'ocean_views',
    },
    'Event/Party': {
        'Red Carpets': 'red_carpets',
        'Ballrooms': 'ballrooms',
        'Festivals/Fairs': 'festivals_fairs',
    },
    'Industrial/Urban Decay': {
        'Abandoned Buildings': 'abandoned_buildings',
        'Warehouses': 'warehouses',
    },
    'School/Educational': {
        'Classrooms': 'classrooms',
        'Libraries': 'libraries',
        'College Campuses': 'college_campuses',
    },
    'Cafe/Restaurant': {
        'Coffee Shops': 'coffee_shops',
        'Dining Spaces': 'dining_spaces',
        'Bars': 'bars',
    },
    'Colors': {
        'Black': 'black',
        'White': 'white',
        'Gray': 'gray'
    }
}

export const MAN_OUTFITS_IMAGES = {
    'Formal Wear': {
        'Suits': SuitsMale,
        'Dress Shirts': DressShirtsMale,
    },
    'Business Casual': {
        'Blazers': BlazersMale,
        'Dress Shirts': BusinessDressShirtsMale,
    },
    'Casual Wear': {
        'T-Shirts': TShirtsMale,
        'Polo Shirts': PoloShirtsMale,
    },
    'Streetwear': {
        'Hoodies/Sweatshirts': HoodiesSweatshirtsMale,
        'Joggers/Sweatpants': JoggersSweatpantsMale,
        'Caps/Beanies': CapsBeaniesMale,
    },
    'Sportswear/Athleisure': {
        'Activewear': ActivewearMale,
        'Hoodies/Zip-Ups': HoodiesZipUpsMale,
    },
    'Preppy': {
        'Polo Shirts': PreppyPoloShirtsMale,
        'Sweaters/Vests': SweatersVestsMale,
    },
    'Workwear': {
        'Denim/Chambray Shirts': DenimChambrayShirtsMale,
        'Jackets': JacketsMale,
    },
    'Minimalist': {
        'Neutral Colors': NeutralColorsMale,
        'Simple Designs': SimpleDesignsMale,
        'Quality Fabrics': QualityFabricsMale,
        'Basic Pieces': BasicPiecesMale,
    }
}

export const MAN_OUTFITS = {
    'Formal Wear': [
        'Suits',
        'Dress Shirts',
    ],
    'Business Casual': [
        'Blazers',
        'Dress Shirts',
    ],
    'Casual Wear': [
        'T-Shirts',
        'Polo Shirts',
    ],
    'Streetwear': [
        'Hoodies/Sweatshirts',
        'Joggers/Sweatpants',
        'Caps/Beanies',
    ],
    'Sportswear/Athleisure': [
        'Activewear',
        'Hoodies/Zip-Ups',
    ],
    'Preppy': [
        'Polo Shirts',
        'Sweaters/Vests',
    ],
    'Workwear': [
        'Denim/Chambray Shirts',
        'Jackets',
    ],
    'Minimalist': [
        'Neutral Colors',
        'Simple Designs',
        'Quality Fabrics',
        'Basic Pieces',
    ]
}

export const KEY_TO_MAN_OUTFITS = {
    'suits': {
        type: 'Formal Wear',
        style: 'Suits'
    },
    'formal_dress_shirts': {
        type: 'Formal Wear',
        style: 'Dress Shirts'
    },
    'blazers': {
        type: 'Business Casual',
        style: 'Blazers'
    },
    'dress_shirts': {
        type: 'Business Casual',
        style: 'Dress Shirts'
    },
    't_shirts': {
        type: 'Casual Wear',
        style: 'T-Shirts'
    },
    'casual_polo_shirts': {
        type: 'Casual Wear',
        style: 'Polo Shirts'
    },
    'hoodies_sweatshirts': {
        type: 'Streetwear',
        style: 'Hoodies/Sweatshirts'
    },
    'joggers_sweatpants': {
        type: 'Streetwear',
        style: 'Joggers/Sweatpants'
    },
    'caps_beanies': {
        type: 'Streetwear',
        style: 'Caps/Beanies'
    },
    'activewear': {
        type: 'Sportswear/Athleisure',
        style: 'Activewear'
    },
    'hoodies_zip_ups': {
        type: 'Sportswear/Athleisure',
        style: 'Hoodies/Zip-Ups'
    },
    'polo_shirts': {
        type: 'Preppy',
        style: 'Polo Shirts'
    },
    'sweaters_vests': {
        type: 'Preppy',
        style: 'Sweaters/Vests'
    },
    'denim_chambray_shirts': {
        type: 'Workwear',
        style: 'Denim/Chambray Shirts'
    },
    'jackets': {
        type: 'Workwear',
        style: 'Jackets'
    },
    'neutral_colors': {
        type: 'Minimalist',
        style: 'Neutral Colors'
    },
    'simple_designs': {
        type: 'Minimalist',
        style: 'Simple Designs'
    },
    'quality_fabrics': {
        type: 'Minimalist',
        style: 'Quality Fabrics'
    },
    'basic_pieces': {
        type: 'Minimalist',
        style: 'Basic Pieces'
    }
}

export const MAN_OUTFITS_TO_KEY = {
    'Formal Wear': {
        'Suits': 'suits',
        'Dress Shirts': 'formal_dress_shirts',
    },
    'Business Casual': {
        'Blazers': 'blazers',
        'Dress Shirts': 'dress_shirts',
    },
    'Casual Wear': {
        'T-Shirts': 't_shirts',
        'Polo Shirts': 'casual_polo_shirts',
    },
    'Streetwear': {
        'Hoodies/Sweatshirts': 'hoodies_sweatshirts',
        'Joggers/Sweatpants': 'joggers_sweatpants',
        'Caps/Beanies': 'caps_beanies',
    },
    'Sportswear/Athleisure': {
        'Activewear': 'activewear',
        'Hoodies/Zip-Ups': 'hoodies_zip_ups',
    },
    'Preppy': {
        'Polo Shirts': 'polo_shirts',
        'Sweaters/Vests': 'sweaters_vests',
    },
    'Workwear': {
        'Denim/Chambray Shirts': 'denim_chambray_shirts',
        'Jackets': 'jackets',
    },
    'Minimalist': {
        'Neutral Colors': 'neutral_colors',
        'Simple Designs': 'simple_designs',
        'Quality Fabrics': 'quality_fabrics',
        'Basic Pieces': 'basic_pieces',
    }
}

export const WOMAN_OUTFITS_IMAGES = {
    'Formal Wear': {
        'Dresses': DressesFemale,
        'Suits': SuitsFemale,
        'Blouses': BlousesFemale,
        'Accessories': AccessoriesFemale
    },
    'Business Casual': {
        'Blazers': BlazersFemale,
        'Blouses': BusinessBlousesFemale,
    },
    'Casual Wear': {
        'Tops': TopsFemale,
        'Casual Dresses': CasualDressesFemale,
    },
    'Streetwear': {
        'Hoodies/Sweatshirts': HoodiesSweatshirtsFemale,
        'Caps/Beanies': CapsBeaniesFemale,
        'Graphic Tees': GraphicTeesFemale,
    },
    'Athleisure': {
        'Activewear': ActivewearFemale,
        'Hoodies/Zip-Ups': HoodiesZipUpsFemale,
    },
    'Minimalist': {
        'Neutral Colors': NeutralColorsFemale,
        'Simple Designs': SimpleDesignsFemale,
        'Quality Fabrics': QualityFabricsFemale,
        'Basic Pieces': BasicPiecesFemale,
    },
    'Preppy': {
        'Polo Shirts': PoloShirtsFemale,
        'Sweaters/Cardigans': SweatersCardigansFemale,
    }
}

export const WOMAN_OUTFITS = {
    'Formal Wear': [
        'Dresses',
        'Suits',
        'Blouses',
        'Accessories'
    ],
    'Business Casual': [
        'Blazers',
        'Blouses',
    ],
    'Casual Wear': [
        'Tops',
        'Casual Dresses',
    ],
    'Streetwear': [
        'Hoodies/Sweatshirts',
        'Caps/Beanies',
        'Graphic Tees',
    ],
    'Athleisure': [
        'Activewear',
        'Hoodies/Zip-Ups',
    ],
    'Minimalist': [
        'Neutral Colors',
        'Simple Designs',
        'Quality Fabrics',
        'Basic Pieces',
    ],
    'Preppy': [
        'Polo Shirts',
        'Sweaters/Cardigans',
    ]
}

export const KEY_TO_WOMAN_OUTFITS = {
    'dresses': {
        type: 'Formal Wear',
        style: 'Dresses'
    },
    'suits': {
        type: 'Formal Wear',
        style: 'Suits'
    },
    'blouses': {
        type: 'Formal Wear',
        style: 'Blouses'
    },
    'accessories': {
        type: 'Formal Wear',
        style: 'Accessories'
    },
    'blazers': {
        type: 'Business Casual',
        style: 'Blazers'
    },
    'business_casual_blouses': {
        type: 'Business Casual',
        style: 'Blouses'
    },
    'tops': {
        type: 'Casual Wear',
        style: 'Tops'
    },
    'casual_dresses': {
        type: 'Casual Wear',
        style: 'Casual Dresses'
    },
    'hoodies_sweatshirts': {
        type: 'Streetwear',
        style: 'Hoodies/Sweatshirts'
    },
    'caps_beanies': {
        type: 'Streetwear',
        style: 'Caps/Beanies'
    },
    'graphic_tees': {
        type: 'Streetwear',
        style: 'Graphic Tees'
    },
    'activewear': {
        type: 'Athleisure',
        style: 'Activewear'
    },
    'hoodies_zip_ups': {
        type: 'Athleisure',
        style: 'Hoodies/Zip-Ups'
    },
    'neutral_colors': {
        type: 'Minimalist',
        style: 'Neutral Colors'
    },
    'simple_designs': {
        type: 'Minimalist',
        style: 'Simple Designs'
    },
    'quality_fabrics': {
        type: 'Minimalist',
        style: 'Quality Fabrics'
    },
    'basic_pieces': {
        type: 'Minimalist',
        style: 'Basic Pieces'
    },
    'polo_shirts': {
        type: 'Preppy',
        style: 'Polo Shirts'
    },
    'sweaters_cardigans': {
        type: 'Preppy',
        style: 'Sweaters/Cardigans'
    }
}

export const WOMAN_OUTFITS_TO_KEY = {
    'Formal Wear': {
        'Dresses': 'dresses',
        'Suits': 'suits',
        'Blouses': 'blouses',
        'Accessories': 'accessories'
    },
    'Business Casual': {
        'Blazers': 'blazers',
        'Blouses': 'business_casual_blouses',
    },
    'Casual Wear': {
        'Tops': 'tops',
        'Casual Dresses': 'casual_dresses',
    },
    'Streetwear': {
        'Hoodies/Sweatshirts': 'hoodies_sweatshirts',
        'Caps/Beanies': 'caps_beanies',
        'Graphic Tees': 'graphic_tees',
    },
    'Athleisure': {
        'Activewear': 'activewear',
        'Hoodies/Zip-Ups': 'hoodies_zip_ups',
    },
    'Minimalist': {
        'Neutral Colors': 'neutral_colors',
        'Simple Designs': 'simple_designs',
        'Quality Fabrics': 'quality_fabrics',
        'Basic Pieces': 'basic_pieces',
    },
    'Preppy': {
        'Polo Shirts': 'polo_shirts',
        'Sweaters/Cardigans': 'sweaters_cardigans',
    }
}

export const IMAGE_REQUIREMENTS_GOOD = [
    {
        id: 1,
        title: 'Variety',
        description: 'Variety of outfits and settings.',
        image: CreationRequirementsVariety,
    },
    {
        id: 2,
        title: 'Recent',
        description: 'Photos recently captured.',
        image: CreationRequirementsRecent,
    },
    {
        id: 3,
        title: 'Clear',
        description: 'Optimize lighting and position the camera moderately.',
        image: CreationRequirementsClear,
    },
    {
        id: 4,
        title: 'Eye Contact',
        description: 'Ensure your gaze is fixed directly at the camera.',
        image: CreationRequirementsEyeContact,
    }
]

export const IMAGE_REQUIREMENTS_BAD = [
    {
        id: 1,
        title: 'No Accessories',
        description: 'Hats, backpacks, headphones, etc.',
        image: CreationRequirementsNoAccessories,
    },
    {
        id: 2,
        title: 'No Revealing Clothing',
        description: 'Tank tops, shirtless, bikinis.',
        image: CreationRequirementsNoRevealingClosing,
    },
    {
        id: 3,
        title: 'No Goofy Faces',
        description: 'Duck faces, tongue out.',
        image: CreationRequirementsNoGoofyFaces,
    },
    {
        id: 4,
        title: 'No Side Shots',
        description: 'Make sure your face is fully turned towards the camera.',
        image: CreationRequirementsNoSideShots,
    }
]

export const LANDING_PLANS = [
    {
        id: 1,
        name: 'Basic',
        price: 29,
        headshots: 40,
        backgroundAndStyles: 2,
        tag: '',
    },
    {
        id: 2,
        name: 'Standard',
        price: 39,
        headshots: 100,
        backgroundAndStyles: 4,
        tag: 'Most Popular',
    },
    {
        id: 3,
        name: 'Premium',
        price: 59,
        headshots: 200,
        backgroundAndStyles: 8,
        tag: 'Best Value',
    },
]

export const AGE_OPTIONS = [
    '18-20',
    '21-24',
    '25-29',
    '30-40',
    '41-50',
    '51-65',
    '65+'
]

export const EYES_COLOR_OPTIONS = [
    'Amber',
    'Black',
    'Blue',
    'Brown',
    'Gray',
    'Green',
    'Hazel',
    'Other'
]

export const HAIR_COLOR_OPTIONS = [
    'Auburn',
    'Black',
    'Blonde',
    'Brown',
    'Gray',
    'Red',
    'White',
    'Other',
    'Bald'
]