import { useNavigate, useSearchParams } from "react-router-dom";
import {
    User,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signInWithPopup,
    updateProfile,
    signOut
} from "firebase/auth";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

import { auth, facebookProvider, provider } from "../firebase/config";
import { useGetUserSubscriptionQuery } from "../api/appApi";
import { setTheme, setUser } from "../redux/appSlice";
import { useAppSelector } from "../redux/hooks";
import { TSignUpData } from "../pages/SignUp";
import { TSignInData } from "../pages/SignIn";
import { PLANS } from "../utils/constants";

type AuthProviderProps = {
    children: React.ReactNode;
}

type AuthContextType = {
    googleAuth: () => Promise<User | null>,
    facebookAuth: () => void,
    emailSignUp: (data: TSignUpData) => Promise<User | null>,
    emailSignIn: (data: TSignInData) => Promise<User | null>,
    signOutUser: () => void
} | null;

const AuthContext = createContext<AuthContextType>(null);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [engineClickId, setEngineClickId] = useState('')
    const [fromEngine, setFromEngine] = useState('')
    const [searchParams] = useSearchParams()
    const subscription = useAppSelector((state) => state.app.subscription)
    const user = useAppSelector((state) => state.app.user)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useGetUserSubscriptionQuery({
        traffic_source: fromEngine,
        traffic_device: isMobile ? 'mobile' : 'desktop',
        click_id: engineClickId
    }, { skip: !user })

    const emailSignIn = async (data: TSignInData) => {
        try {
            const response = await signInWithEmailAndPassword(auth, data.email, data.password)

            // Navigate user to checkout page if they are coming from a landing page plan link
            if (searchParams.get('plan')) {
                const plan = PLANS.find((plan) => plan.name === searchParams.get('plan'))

                if (plan) {
                    navigate(`/checkout/${plan.stripe_price}/${plan.name}`)
                }
            }

            return response.user
        } catch (e) {
            return null
        }
    }

    const signOutUser = async () => {
        try {
            await signOut(auth)
        } catch (e) { }
    }

    const emailSignUp = async (data: TSignUpData) => {
        try {
            const response = await createUserWithEmailAndPassword(auth, data.email, data.password)

            if (response.user) {
                // Update user profile with first and last name
                await updateProfile(response.user, {
                    displayName: `${data.first_name} ${data.last_name}`
                })
                dispatch(setUser({
                    ...response.user,
                    displayName: `${data.first_name} ${data.last_name}`
                }))

                // Navigate user to checkout page if they are coming from a landing page plan links
                if (searchParams.get('plan')) {
                    const plan = PLANS.find((plan) => plan.name === searchParams.get('plan'))

                    if (plan) {
                        navigate(`/checkout/${plan.stripe_price}/${plan.name}`)
                    }
                }

                return response.user
            }

            return null
        } catch (e) {
            return null
        }
    }

    const googleAuth = async () => {
        try {
            const response = await signInWithPopup(auth, provider)

            // Navigate user to checkout page if they are coming from a landing page plan links
            if (searchParams.get('plan')) {
                const plan = PLANS.find((plan) => plan.name === searchParams.get('plan'))

                if (plan) {
                    navigate(`/checkout/${plan.stripe_price}/${plan.name}`)
                }
            }

            return response.user
        } catch (e) {
            return null
        }
    }

    const facebookAuth = async () => {
        signInWithPopup(auth, facebookProvider)
            .then((result) => {
                // Navigate user to checkout page if they are coming from a landing page plan links
                if (searchParams.get('plan')) {
                    const plan = PLANS.find((plan) => plan.name === searchParams.get('plan'))

                    if (plan) {
                        navigate(`/checkout/${plan.stripe_price}/${plan.name}`)
                    }
                }

                return result.user
            })
            .catch((error) => {
                console.log("Error", error)
            });
    }

    useEffect(() => {
        if (!subscription) {
            const theme = localStorage?.getItem('theme') as 'light' | 'dark' || 'light'

            dispatch(setTheme(theme))
        }
    }, [subscription, dispatch])

    useEffect(() => {
        const googleClickId = searchParams.get('gclid')
        const bingClickId = searchParams.get('msclkid')

        if (googleClickId) {
            setFromEngine('Google')
            setEngineClickId(googleClickId)
            localStorage.setItem('fromEngine', 'Google')
            localStorage.setItem('engineClickId', googleClickId)
        } else if (bingClickId) {
            setFromEngine('Bing')
            setEngineClickId(bingClickId)
            localStorage.setItem('fromEngine', 'Bing')
            localStorage.setItem('engineClickId', bingClickId)
        } else {
            const fromEngineStorage = localStorage.getItem('fromEngine')
            const engineClickIdStorage = localStorage.getItem('engineClickId')

            if (fromEngineStorage) {
                setFromEngine(fromEngineStorage)
            }

            if (engineClickIdStorage) {
                setEngineClickId(engineClickIdStorage)
            }
        }
    }, [searchParams])

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(setUser(user))
            } else {
                dispatch(setUser(null))
            }

            const tokenRefreshInterval = 500000;
            const tokenRefreshTimer = setInterval(async () => {
                try {
                    const currentUser = auth.currentUser

                    if (currentUser) {
                        const tokenResult = await currentUser.getIdTokenResult();

                        if (tokenResult) {
                            const tokenExpirationTime = new Date(tokenResult.expirationTime).getTime();
                            const currentTime = new Date().getTime();

                            const timeToExpiration = tokenExpirationTime - currentTime;

                            if (timeToExpiration < tokenRefreshInterval) {
                                try {
                                    const newToken = await currentUser.getIdToken(true);

                                    dispatch(
                                        setUser({ ...currentUser, accessToken: newToken })
                                    )
                                } catch (error) {
                                    console.log('Error refreshing ID token:', error);
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.log('Error refreshing token:', error);
                }
            }, 200000);

            return () => {
                clearInterval(tokenRefreshTimer)
            }
        });
    }, [dispatch])

    const value = {
        googleAuth,
        facebookAuth,
        emailSignUp,
        emailSignIn,
        signOutUser,
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}