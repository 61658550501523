import { useAlbumContext } from '../context/AlbumContext'

import EmptyFavoriteImage from '../assets/empty-favorites.svg'


const EmptyFavorites = () => {
    const albumCtx = useAlbumContext()

    return (
        <div className="flex flex-col w-full items-center justify-center h-full gap-10 p-6 overflow-y-auto mobile:gap-4 mobile:h-[calc(100%-100px)]">
            <img className="w-[280px] mobile:w-[180px]" src={EmptyFavoriteImage} alt="" />
            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] text-text text-center font-bold mobile:text-[23px] mobile:leading-[28px]">
                There are no photos favorited.
            </h5>
            <button onClick={() => albumCtx?.setShowAll(true)} className="h-[56px] rounded-xl px-6 bg-button hover:bg-buttonHover transition-colors text-[18px] leading-[22px] font-semibold text-white mobile:h-[44px] mobile:rounded-lg mobile:text-base mobile:w-[calc(100%-32px)] mobile:absolute mobile:left-4 mobile:bottom-[128px]">
                View All Photos
            </button>
        </div>
    )
}

export default EmptyFavorites