import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { useCreationContext } from "../context/CreationContext"
import { useAlbumContext } from "../context/AlbumContext"
import { useAppSelector } from "../redux/hooks"
import useViewport from "../hooks/useViewport"

import EditTitleMobile from "./EditTitleMobile"
import UserBlock from "./Common/UserBlock"

import NoTextLogo from "../assets/no-text-logo.svg"
import CreationCloud from "../assets/creation/cloud.svg"
import LogoBlack from "../assets/logo-black.svg"
import LogoWhite from "../assets/logo-white.svg"
import ArrowLeft from "../assets/arrow-left.svg"
import Menu from "../assets/landing/menu.svg"

type HeaderProps = {
    type?: 'default' | 'creation' | 'unauthorized' | 'album' | 'back' | 'landing',
    backTo?: string,
}

const Header: FC<HeaderProps> = ({ type = 'default', backTo = '/' }) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [showEditTitle, setShowEditTitle] = useState(false)
    const theme = useAppSelector(state => state.app.theme)

    let ignoreBlur = false;

    const { id } = useParams()
    const { isMobile } = useViewport()

    const albumCtx = useAlbumContext()
    const ctx = useCreationContext()

    const navigate = useNavigate()

    const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        ctx?.setTitle(e.target.value)
    }

    const handleGetStarted = () => {
        navigate('/continue-with')
    }

    const handleGoToLogin = () => {
        navigate('/sign-in')
    }

    const handleAlbumTitleKeyEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            setShowEditTitle(false)

            e.currentTarget.blur()
        } else if (e.key === 'Escape') {
            e.preventDefault()
            ignoreBlur = true
            albumCtx?.setTitle(albumCtx?.nonEditedTitle)

            setShowEditTitle(false)
            e.currentTarget.blur()
            setTimeout(() => ignoreBlur = false, 0)
        }
    }

    const handleCreationTitleKeyEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            setShowEditTitle(false)

            e.currentTarget.blur()
        } else if (e.key === 'Escape') {
            e.preventDefault()
            ignoreBlur = true
            ctx?.setTitle(ctx?.nonEditedTitle)

            setShowEditTitle(false)
            e.currentTarget.blur()
            setTimeout(() => ignoreBlur = false, 0)
        }
    }

    const handleClickOnTitle = () => {
        if (!isMobile) return

        setShowEditTitle(true)
    }

    const handleBlurTitle = (e: ChangeEvent<HTMLInputElement>) => {
        if (!id || isMobile) return

        ctx?.syncData({
            generation_id: id,
            field: 'title',
            value: e.target.value
        })
    }

    const handleSaveTitle = (title: string) => {
        if (!id) return

        setShowEditTitle(false)
        ctx?.syncData({
            generation_id: id,
            field: 'title',
            value: title
        })
        ctx?.setTitle(title)
        albumCtx?.setTitle(title)
    }

    useEffect(() => {
        if (isMobile && showMobileMenu) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [showMobileMenu, isMobile])

    if (type === 'default') {
        return (
            <div className="h-[68px] border-solid border-b-[1px] border-inputBorder flex items-center justify-between px-4 mobile:h-[56px]">
                <img
                    onClick={() => navigate('/')}
                    className="cursor-pointer"
                    width={isMobile ? 40 : 260}
                    src={isMobile ? NoTextLogo : theme === 'light' ? LogoBlack : LogoWhite}
                    alt=""
                />
                <UserBlock />
            </div>
        )
    } else if (type === 'creation') {
        return (
            <div className="h-[68px] border-solid border-b-[1px] border-inputBorder flex items-center justify-between pe-4 mobile:h-[56px] mobile:relative">
                <div className="flex items-center gap-2 h-full mobile:justify-between mobile:w-full">
                    <div
                        onClick={() => navigate('/')}
                        className="h-full w-[68px] flex items-center justify-center border-solid border-r-[1px] border-inputBorder cursor-pointer mobile:border-none mobile:w-fit mobile:justify-start mobile:px-4 hover:bg-inputBackground transition-colors mobile:hover:bg-transparent"
                    >
                        {isMobile ? (
                            <img width={40} src={NoTextLogo} alt="" />
                        ) : (
                            <img width={44} src={NoTextLogo} alt="" />
                        )}
                    </div>
                    <div className="w-fit relative h-full max-w-[200px] min-w-[80px] mobile:absolute mobile:left-1/2 top-1/2 mobile:translate-x-[-50%] translate-y-[-50%]">
                        <span className="opacity-0 w-fit text-[23px] font-semibold leading-[28px] px-[12px] tracking-[-0.02em] whitespace-pre max-w-[200px] min-w-[80px]">
                            {ctx?.title}
                        </span>
                        <input
                            onClick={handleClickOnTitle}
                            value={ctx?.title}
                            onChange={(e) => handleChangeTitle(e)}
                            onBlur={(e) => !ignoreBlur ? handleBlurTitle(e) : null}
                            readOnly={isMobile ? true : false}
                            onKeyDown={(e) => handleCreationTitleKeyEnter(e)}
                            className="text-text bg-transparent focus-visible:outline-none font-semibold text-[23px] leading-[28px] tracking-[-0.02em] max-w-[200px] whitespace-nowrap overflow-hidden min-w-[80px] py-[8px] px-[12px] rounded-lg input-focus-hover mobile:text-[19px] mobile:leading-[23px] mobile:text-center disable-mobile-hover-input absolute top-1/2 left-0 translate-y-[-50%] w-full"
                        />
                    </div>
                    <div
                        className="h-[24px] rounded-[8px] border-solid border-[1px] border-inputBorder flex items-center gap-1 justify-center px-2 font-[Inter] text-[12px] leading-[18px] text-text ms-[2px]"
                    >
                        <img src={CreationCloud} alt="" />
                        {ctx?.isLoading ? 'Saving' : 'Saved'}
                    </div>
                </div>
                <div className="mobile:hidden">
                    <UserBlock />
                </div>
                {ctx?.title !== undefined && showEditTitle && (
                    <EditTitleMobile
                        title={ctx?.title}
                        handleSaveTitle={handleSaveTitle}
                        setShowEditTitle={setShowEditTitle}
                    />
                )}
            </div>
        )
    } else if (type === 'unauthorized') {
        return (
            <div className="h-[68px] border-solid border-b-[1px] border-inputBorder flex items-center justify-between px-4 mobile:hidden">
                <img onClick={() => navigate('/')} className="cursor-pointer" width={260} src={theme === 'light' ? LogoBlack : LogoWhite} alt="" />
            </div>
        )
    } else if (type === 'album') {
        return (
            <div className="h-[68px] w-full flex items-center justify-between border-solid border-t-[1px] border-b-[1px] border-inputBorder pe-4 mobile:h-[56px] mobile:border-b-[0]">
                <div className="flex items-center h-full gap-2">
                    <div onClick={() => navigate('/')} className="w-[68px] h-full border-solid border-r-[1px] border-inputBorder flex items-center justify-center cursor-pointer hover:bg-inputBackground transition-colors mobile:w-[56px] mobile:border-r-[0]">
                        {isMobile ? (
                            <img className="w-[24px]" src={ArrowLeft} alt="" />
                        ) : (
                            <img className="w-[44px]" src={NoTextLogo} alt="" />
                        )}
                    </div>
                    <div className="w-fit relative h-full mobile:hidden">
                        <span className="opacity-0 w-fit text-[23px] font-semibold leading-[28px] px-[12px] tracking-[-0.02em] whitespace-pre">
                            {albumCtx?.title}
                        </span>
                        <input
                            value={albumCtx?.title}
                            onKeyDown={(e) => handleAlbumTitleKeyEnter(e)}
                            onChange={(e) => albumCtx?.setTitle(e.target.value)}
                            onBlur={() => !ignoreBlur ? albumCtx?.updateAlbumTitle() : null}
                            className="text-text bg-transparent focus-visible:outline-none font-semibold text-[23px] leading-[28px] tracking-[-0.02em] whitespace-nowrap overflow-hidden min-w-[80px] py-[8px] px-[12px] rounded-lg input-focus-hover mobile:text-[19px] mobile:leading-[23px] mobile:text-center mobile:hidden absolute top-1/2 left-0 translate-y-[-50%] w-full"
                        />
                    </div>
                </div>
                <input
                    onClick={handleClickOnTitle}
                    value={albumCtx?.title}
                    onChange={(e) => albumCtx?.setTitle(e.target.value)}
                    onBlur={albumCtx?.updateAlbumTitle}
                    size={albumCtx?.title?.length}
                    readOnly={isMobile ? true : false}
                    className="text-text bg-transparent focus-visible:outline-none font-semibold text-[23px] leading-[28px] tracking-[-0.02em] whitespace-nowrap overflow-hidden min-w-[80px] py-[8px] px-[12px] rounded-lg input-focus-hover mobile:text-[19px] mobile:leading-[23px] mobile:text-center w-fit mobile:flex hidden disable-mobile-hover-input mobile:max-w-[calc(100%-140px)]"
                />
                {isMobile ? (
                    <div
                        className="h-[24px] rounded-[8px] border-solid border-[1px] border-inputBorder flex items-center gap-1 justify-center px-2 font-[Inter] text-[12px] leading-[18px] text-text ms-[2px]"
                    >
                        <img src={CreationCloud} alt="" />
                        {ctx?.isLoading ? 'Saving' : 'Saved'}
                    </div>
                ) : (
                    <UserBlock />
                )}
                {albumCtx?.title !== undefined && showEditTitle && (
                    <EditTitleMobile
                        title={albumCtx?.title}
                        handleSaveTitle={handleSaveTitle}
                        setShowEditTitle={setShowEditTitle}
                    />
                )}
            </div>
        )
    } else if (type === 'back') {
        return (
            <div className="h-[68px] border-solid border-b-[1px] border-inputBorder flex items-center justify-between mobile:h-[56px] pe-4">
                {isMobile ? (
                    <>
                        <div onClick={() => navigate(backTo)} className="w-[68px] h-full border-solid border-r-[0px] border-inputBorder flex items-center justify-center cursor-pointer mobile:w-[56px]">
                            <img className="w-[24px]" src={ArrowLeft} alt="" />
                        </div>
                        <img
                            onClick={() => navigate(backTo)}
                            className="cursor-pointer"
                            width={50}
                            src={NoTextLogo}
                            alt=""
                        />
                    </>
                ) : (
                    <div className="flex items-center h-full gap-4">
                        <div onClick={() => navigate(backTo)} className="w-[68px] h-full border-solid border-r-[1px] border-inputBorder flex items-center justify-center cursor-pointer mobile:w-[56px]">
                            <img src={ArrowLeft} alt="" />
                        </div>
                        <img
                            onClick={() => navigate(backTo)}
                            className="cursor-pointer mobile:ps-4"
                            width={260}
                            src={theme === 'light' ? LogoBlack : LogoWhite}
                            alt=""
                        />
                    </div>
                )}
                <UserBlock nameHidden={isMobile ? true : false} />
            </div>
        )
    } else if (type === 'landing') {
        return (
            <div className={`px-4 h-[68px] flex items-center justify-between gap-4 sticky top-0 left-0 bg-background z-[3] mobile:h-[56px] ${showMobileMenu ? 'mobile:justify-end landing-header-expanded mobile:fixed mobile:w-full' : 'mobile:justify-start'}`}>
                <div className="hidden mobile:flex">
                    <img
                        className={`cursor-pointer ${theme === 'light' ? '' : 'icon-to-white'}`}
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                        src={Menu}
                        alt=""
                    />
                </div>
                <div className="flex items-center mobile:absolute mobile:top-1/2 mobile:left-1/2 mobile:translate-x-[-50%] mobile:translate-y-[-50%] mobile:w-max">
                    <img
                        src={theme === 'light' ? LogoBlack : LogoWhite}
                        onClick={() => navigate('/')}
                        width={isMobile ? 222 : 260}
                        className="cursor-pointer"
                        alt=""
                    />
                </div>
                {((isMobile && showMobileMenu) || !isMobile) && (
                    <div className="flex items-center gap-6 mobile:fixed mobile:h-[100dvh] mobile:flex-col mobile:top-0 mobile:left-0 mobile:w-[calc(100%-64px)] mobile:bg-background mobile:px-4 mobile:py-8 mobile:justify-between">
                        <div className="flex items-center gap-2 mobile:flex-col mobile:pt-[56px] mobile:items-start mobile:w-full mobile:gap-6">
                            <Link className="text-base font-semibold text-text px-2 h-[40px] rounded-xl hover:bg-inputBackground transition-colors flex items-center justify-center mobile:text-[23px] mobile:leading-[28px] mobile:tracking-[-0.02em] mobile:font-bold" to='https://justheadshots.ai'>
                                Home
                            </Link>
                            <Link className="text-base font-semibold text-text px-2 h-[40px] rounded-xl hover:bg-inputBackground transition-colors flex items-center justify-center mobile:text-[23px] mobile:leading-[28px] mobile:tracking-[-0.02em] mobile:font-bold" to='/terms-and-conditions'>
                                Terms & Conditions
                            </Link>
                            <Link className="text-base font-semibold text-text px-2 h-[40px] rounded-xl hover:bg-inputBackground transition-colors flex items-center justify-center mobile:text-[23px] mobile:leading-[28px] mobile:tracking-[-0.02em] mobile:font-bold" to='/privacy-policy'>
                                Privacy Policy
                            </Link>
                        </div>
                        <div className="flex items-center gap-2 mobile:flex-col-reverse mobile:w-full">
                            <button onClick={handleGetStarted} className="h-[44px] rounded-[10px] bg-text text-background hover:bg-secondaryText transition-colors px-4 text-base font-semibold mobile:h-[56px] mobile:rounded-xl mobile:px-6 mobile:text-[18px] mobile:leading-[22px] mobile:w-full">
                                Get Started
                            </button>
                            <button onClick={handleGoToLogin} className="h-[44px] rounded-[10px] bg-inputBackground hover:bg-inputBorder transition-colors px-4 text-base font-semibold text-text mobile:h-[56px] mobile:rounded-xl mobile:px-6 mobile:text-[18px] mobile:leading-[22px] mobile:w-full">
                                Sign In
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    } else {
        return null
    }
}

export default Header