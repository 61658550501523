import { useNavigate } from "react-router-dom"
import { useMemo } from "react"

import { useCreationContext } from "../../context/CreationContext"
import { GenerationUploadedImageItem } from "../../utils/types"
import { useAppSelector } from "../../redux/hooks"
import useViewport from "../../hooks/useViewport"

import BackArrow from '../../assets/back-arrow.svg'

const CreationFooter = () => {
    const subscription = useAppSelector((state) => state.app.subscription)
    const theme = useAppSelector((state) => state.app.theme)

    const ctx = useCreationContext()

    const { isMobile } = useViewport()

    const navigate = useNavigate()

    const continueDisabled = useMemo(() => {
        const contextState = ctx?.state

        if (ctx?.isGenerationStarting) {
            return true
        }

        if (contextState) {
            switch (ctx?.step) {
                case 1: {
                    return contextState.gender ? false : true
                }
                case 2: {
                    return contextState.age ? false : true
                }
                case 3: {
                    return contextState.eye_color ? false : true
                }
                case 4: {
                    return contextState.hair_color ? false : true
                }
                case 5: {
                    return contextState.ethnicity ? false : true
                }
                case 6: {
                    const filteredImages = ctx?.uploadedImages.filter((image): image is GenerationUploadedImageItem => image instanceof Object)

                    if (filteredImages.every((image) => image.is_valid) && !ctx?.isValidating && !ctx?.isCompressingImages && filteredImages.length >= 6 && ctx?.requirementsAccepted) {
                        return false
                    } else {
                        return true
                    }
                }
                case 7: {
                    return contextState.backgroundStyles.length ? false : true
                }
                case 8: {
                    return contextState.outfitStyles.length ? false : true
                }
                case 9: {
                    return false
                }
                default: {
                    return true
                }
            }
        } else {
            return true
        }
    }, [ctx])

    const handleNext = () => {
        if (ctx?.isEdit && ctx?.step <= 5) {
            ctx?.setIsEdit(false)
            ctx?.setStep(9)
        } else if (ctx?.isEdit && (ctx?.step === 6 || ctx?.step === 8)) {
            ctx?.setStep(9)
            ctx?.setIsEdit(false)
        } else {
            if (ctx?.step === 9) {
                ctx?.generateImages()
            } else if (ctx?.step === 6 && !subscription?.active_payments) {
                navigate(`/plans?continue_generation=${ctx?.id}`)
            } else {
                ctx?.setStep(ctx?.step + 1)
            }
        }
    }

    const handleBack = () => {
        if (!ctx?.step) return

        if (ctx?.isEdit && ctx?.step <= 5) {
            ctx?.cancelUserInformationEdit()
        } else if ((ctx?.step === 6 || ctx?.step === 7) && ctx?.isEdit) {
            ctx?.setIsEdit(false)
            ctx?.setStep(9)
        } else {
            if (ctx?.step === 1) {
                navigate('/')
            } else {
                ctx?.setStep(ctx?.step - 1)
            }
        }
    }

    return (
        <div className="w-full h-[88px] shadow-border-top flex flex-col z-[5] bg-background mobile:h-[76px] mobile:sticky mobile:left-0 mobile:bottom-0 mobile:z-[11] justify-center">
            <div className="flex items-center justify-end gap-2 pr-4 mobile:pl-4">
                {isMobile ? (
                    <button onClick={handleBack} className="w-[44px] h-[44px] rounded-[10px] bg-inputBackground flex items-center justify-center min-w-[44px]">
                        <img className={theme === 'dark' ? 'icon-to-white' : ''} src={BackArrow} alt="" />
                    </button>
                ) : (
                    <button
                        onClick={handleBack}
                        className="rounded-lg px-6 h-[56px] font-semibold text-text text-base mobile:h-[44px] bg-inputBackground hover:bg-inputBorder transition-colors"
                    >
                        {(ctx?.step && (ctx?.step <= 5 || ctx?.step === 6 || ctx?.step === 7)) && ctx?.isEdit ? 'Cancel' : 'Back'}
                    </button>
                )}
                <button
                    onClick={handleNext}
                    disabled={continueDisabled}
                    className="bg-button text-white text-base font-semibold rounded-lg px-6 h-[56px] disabled:opacity-20 mobile:h-[44px] mobile:w-full hover:bg-buttonHover transition-colors"
                >
                    {
                        ctx?.step === 9
                            ? 'Generate'
                            : (ctx?.isEdit && (ctx?.step <= 5 || ctx?.step === 6 || ctx?.step === 8))
                                ? 'Save'
                                : 'Continue'
                    }
                </button>
            </div>
        </div >
    )
}

export default CreationFooter