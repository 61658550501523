import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useState } from "react"

import { useAuthContext } from "../context/AuthContext"
import { useAppSelector } from "../redux/hooks"

import Header from "../components/Header"

import AgreementCheck from "../assets/creation/check-square.svg"
import AuthPreview from "../assets/auth-preview.png"
import NoTextLogo from "../assets/no-text-logo.svg"
import EyeClose from "../assets/eye-close.svg"
import Back from "../assets/back.svg"
import Eye from "../assets/eye.svg"

export type TSignUpData = {
    email: string,
    first_name: string,
    last_name: string,
    password: string,
}

const SignUp = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [agree, setAgree] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const theme = useAppSelector((state) => state.app.theme)

    const navigate = useNavigate()

    const auth = useAuthContext()

    const {
        register,
        handleSubmit,
    } = useForm<TSignUpData>()

    const handleSignUp = async (data: TSignUpData) => {
        if (!agree) return;

        const user = await auth?.emailSignUp(data)

        if (user) {
            setIsError(false)
        } else {
            setIsError(true)
        }
    }

    return (
        <div className="w-full min-h-[100vh] mobile:min-h-[100dvh]">
            <Header type="unauthorized" />
            <div className="grid grid-cols-[1fr,1.1fr] h-[calc(100vh-68px)] mobile:h-[100dvh] mobile:grid-cols-[1fr] mobile:w-[100vw]">
                <div className="flex flex-col items-center mobile:justify-between gap-[64px] mobile:gap-4 p-6 py-20 mobile:p-4 h-full overflow-y-auto custom-scroll">
                    <div className="mobile:flex w-full justify-start hidden">
                        <div onClick={() => navigate('/')} className="w-[44px] h-[44px] rounded-[10px] border-solid border-[1px] border-inputBorder flex items-center justify-center">
                            <img src={Back} alt="" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-[64px] mobile:gap-4">
                        <div className="flex flex-col items-center gap-6">
                            <img
                                className={`mobile:hidden`}
                                width={124}
                                src={NoTextLogo}
                                alt=""
                            />
                            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-.02em] font-bold text-text mobile:text-[28px] mobile:leading-[35px] mobile:mb-3">
                                Get Started
                            </h5>
                        </div>
                        <form className="max-w-[480px] w-full flex flex-col gap-4" onSubmit={handleSubmit(handleSignUp)}>
                            <div className="flex items-center gap-4">
                                <div className="flex flex-col gap-2 w-full mobile:w-[calc(50vw-25px)]">
                                    <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter first name"
                                        className="h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px]"
                                        {...register("first_name", { required: "Name is required" })}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full mobile:w-[calc(50vw-25px)]">
                                    <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter last name"
                                        className="h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:px-3 mobile:h-[44px]"
                                        {...register("last_name", { required: "First name is required" })}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter email address"
                                    className={`h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] ${isError ? 'border-redButton' : ''}`}
                                    {...register("email", {
                                        required: "Enter a valid email address",
                                        onChange: () => isError && setIsError(false),
                                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                    })}
                                />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <label className="text-base text-text mobile:text-[14px] mobile:leading-[17px]">
                                    Password
                                </label>
                                <div className="relative w-full">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Create password (8 characters minimum)"
                                        className={`h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base w-full mobile:text-[14px] mobile:leading-[17px] mobile:h-[44px] ${isError ? 'border-redButton' : ''}`}
                                        {...register("password", {
                                            required: "Enter a valid password",
                                            onChange: () => isError && setIsError(false),
                                            minLength: 8,
                                        })}
                                    />
                                    {
                                        showPassword ? (
                                            <img
                                                onClick={() => setShowPassword(!showPassword)}
                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                src={Eye}
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                onClick={() => setShowPassword(!showPassword)}
                                                className={`${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'} absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer mobile:w-[20px]`}
                                                src={EyeClose}
                                                alt=""
                                            />
                                        )}
                                </div>
                            </div>
                            <div className="flex gap-2 items-start w-full mt-2">
                                {agree ? (
                                    <img
                                        className="cursor-pointer w-[24px] h-[24px] mobile:w-[16px] mobile:h-[16px]"
                                        onClick={() => setAgree(!agree)}
                                        src={AgreementCheck}
                                        alt=""
                                    />
                                ) : (
                                    <div
                                        onClick={() => setAgree(!agree)}
                                        className="w-[24px] min-w-[24px] h-[24px] mobile:w-[16px] mobile:h-[16px] mobile:min-w-[16px] rounded-[4px] border-solid border-[1px] border-text cursor-pointer"
                                    ></div>
                                )}
                                <p className="text-base font-normal text-text mobile:text-[14px] mobile:leading-[17px]">
                                    I agree with JustHeadshots.ai <Link className="text-button hover:text-buttonHover transition-colors" to='/terms-and-conditions'>Terms of Service</Link> and <Link className="text-button hover:text-buttonHover transition-colors" to='/privacy-policy'>Privacy Policy.</Link>
                                </p>
                            </div>
                            <button type="submit" className="h-[56px] w-full rounded-lg bg-button text-white mt-2 text-[18px] leading-[22px] font-semibold font-[Inter] mobile:h-[44px] mobile:text-base hover:bg-buttonHover transition-colors mobile:rounded-[10px]">
                                Create Account
                            </button>
                            <p className="max-w-[340px] text-center text-[12px] leading-[18px] text-secondaryText mx-auto mt-2">
                                This site is protected by reCAPTCHA and the
                                Google Privacy Policy and Terms of Service apply.
                            </p>
                        </form>
                    </div>
                    <div className="font-semibold text-base text-text mobile:text-[14px] mobile:leading-[17px] mobile:mt-4">
                        Already have any account?{" "}
                        <Link to={`/sign-in${searchParams.get('plan') ? `?plan=${searchParams.get('plan')}` : ''}`} className="text-button cursor-pointer hover:text-buttonHover transition-colors">
                            Sign in
                        </Link>
                    </div>
                </div>
                <div className="mobile:hidden flex items-center justify-center p-6 h-[calc(100vh-68px)]">
                    <img className="h-full" src={AuthPreview} alt="" />
                </div>
            </div>
        </div>
    )
}

export default SignUp