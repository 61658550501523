import { useParams } from "react-router-dom"

import { useCreationContext } from "../../context/CreationContext"
import { useAppSelector } from "../../redux/hooks"
import { ETHNICITY } from "../../utils/constants"

import LoadingStep from "./LoadingStep"

import CreationForward from '../../assets/creation/forward.svg'
import CreationBack from '../../assets/creation/back.svg'

const EthnicityStep = () => {
    const theme = useAppSelector(state => state.app.theme)

    const ctx = useCreationContext()

    const { id } = useParams()

    const handleNavigationNext = () => {
        if (ctx?.isEdit) {
            ctx?.setStep(9)
        } else {
            ctx?.setIsPreparingModel(true)

            setTimeout(() => {
                ctx?.setStep(6)
                ctx?.setIsPreparingModel(false)
            }, 5000)
        }
    }

    const handleSelectEthnicity = (ethnicity: string) => {
        ctx?.setState({ ...ctx.state, ethnicity })

        if (!ctx?.isEdit) {
            ctx?.setIsPreparingModel(true)
        }

        if (!id) return

        if (ctx?.isEdit) {
            ctx?.syncData({
                generation_id: id,
                field: 'ethnicity',
                value: ethnicity
            })
        } else {
            setTimeout(() => {
                ctx?.syncData({
                    generation_id: id,
                    field: 'ethnicity',
                    value: ethnicity
                })
                ctx?.setStep(6)
                ctx?.setIsPreparingModel(false)
            }, 5000)
        }
    }

    return (
        <>
            {ctx?.isPreparingModel ? (
                <LoadingStep />
            ) : (
                <div className="flex flex-col items-start gap-6 max-w-[480px] h-full w-full mobile:gap-4">
                    <div className="flex items-center justify-center w-full relative">
                        <div onClick={() => ctx?.setStep(4)} className="w-[36px] h-[36px] rounded-lg bg-inputBackground absolute left-0 flex items-center justify-center hover:bg-inputBorder transition-colors cursor-pointer">
                            <img className={`${theme === 'dark' ? 'icon-to-white' : ''}`} src={CreationBack} alt="" />
                        </div>
                        <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] text-text font-bold mobile:text-[19px] mobile:leading-[23px]">
                            What is your ethnicity?
                        </h5>
                        {ctx?.state?.ethnicity && (
                            <div onClick={handleNavigationNext} className="w-[36px] h-[36px] rounded-lg bg-inputBackground absolute right-0 flex items-center justify-center hover:bg-inputBorder transition-colors cursor-pointer">
                                <img className={`${theme === 'dark' ? 'icon-to-white' : ''}`} src={CreationForward} alt="" />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col rounded-lg h-fit border-solid border-[1px] border-inputBorder w-full">
                        {ETHNICITY.map((ethnicity, index) => (
                            <div
                                key={index}
                                onClick={() => handleSelectEthnicity(ethnicity)}
                                className={`w-full p-4 border-solid border-b-[1px] border-inputBorder flex items-center justify-center gap-4 font-semibold text-text text-[18px] leading-[22px] cursor-pointer last:border-none first:rounded-t-lg last:rounded-b-lg mobile:p-3 mobile:text-base ${ctx?.state.ethnicity === ethnicity ? 'bg-inputTransparentBackground outline outline-2 outline-button outline-offset-[-2px] hover:bg-[#0085FF33]' : 'hover:bg-inputBackground'}`}
                            >
                                {ethnicity}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default EthnicityStep