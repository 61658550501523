import { useCreationContext } from "../../context/CreationContext"

import ImageRequirements from "./ImageRequirements"
import UploadedImages from "./UploadedImages"
import UploadBlock from "./UploadBlock"

const UploadImagesStep = () => {
    const ctx = useCreationContext()

    return (
        <div className="grid grid-cols-[1fr,360px] w-full mobile:grid-cols-[1fr]">
            {ctx?.uploadedImages && ctx.uploadedImages.length > 0 ? (
                <UploadedImages />
            ) : (
                <UploadBlock />
            )}
            <ImageRequirements />
        </div>
    )
}

export default UploadImagesStep