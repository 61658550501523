export async function uet_report_conversion(revenue_value: number) {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "purchase", {
        "revenue_value": revenue_value,
        "currency": "USD"
    });
}

export async function gtag_report_conversion(revenue_value: number, transaction_id: string) {
    window.gtag('event', 'conversion', {
        'send_to': 'AW-16674622860/81Y7CMG-qMwZEIybio8-',
        'value': revenue_value,
        'currency': 'USD',
        'transaction_id': transaction_id
    });
}