import { FC, MouseEvent, useEffect, useRef, useState } from "react"

type EditTitleMobileProps = {
    title: string,
    handleSaveTitle: (title: string) => void,
    setShowEditTitle: (show: boolean) => void,
}

const EditTitleMobile: FC<EditTitleMobileProps> = ({ title, handleSaveTitle, setShowEditTitle }) => {
    const [tempTitle, setTempTitle] = useState(title)

    const contentRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleClickOutsideContent = (event: MouseEvent<HTMLDivElement>) => {
        if (contentRef.current && !contentRef.current.contains(event?.target as Node)) {
            setShowEditTitle(false)
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    return (
        <div onClick={(e) => handleClickOutsideContent(e)} className="fixed bottom-0 left-0 w-full h-[100vh] hidden mobile:flex flex-col justify-end z-[16] bg-[#00000033]">
            <div ref={contentRef} className="w-full bg-background flex flex-col rounded-t-xl border-solid border-t-[1px] border-inputBorder upload-top-box-shadow">
                <div className="flex items-center justify-center flex-col p-1 pb-4 gap-[6px] border-solid border-b-[1px] border-inputBorder">
                    <div onClick={() => setShowEditTitle(false)} className="w-[52px] h-[4px] rounded-xl bg-inputBorder"></div>
                    <h5 className="font-[Sora] text-[19px] leading-[23px] font-bold text-text">
                        Rename Album
                    </h5>
                </div>
                <div className="flex flex-col p-4 gap-4">
                    <input
                        ref={inputRef}
                        className="h-[56px] rounded-lg border-solid border-[1px] border-inputBorder px-4 placeholder:text-placeholder text-text text-base"
                        placeholder="Album Title"
                        value={tempTitle}
                        onChange={(e) => setTempTitle(e.target.value)}
                        type="text"
                    />
                    <div className="flex items-center w-full gap-2">
                        <button onClick={() => setShowEditTitle(false)} className="w-1/2 h-[44px] bg-inputBackground hover:bg-inputBorder transition-colors rounded-[10px] text-text text-base font-semibold">
                            Discard
                        </button>
                        <button onClick={() => handleSaveTitle(tempTitle)} className="w-1/2 h-[44px] bg-text hover:bg-secondaryText transition-colors rounded-[10px] text-background text-base font-semibold">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditTitleMobile