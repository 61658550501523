import { FC } from "react"

import useViewport from "../../hooks/useViewport"

import TermsAndConditionsBackground from "../../assets/landing/terms-and-conditions-background.png"
import TermsAndConditionsMobile from "../../assets/landing/terms-and-conditions-mobile.svg"
import PrivacyPolicyBackground from "../../assets/landing/privacy-policy-background.png"
import PrivacyPolicyMobile from "../../assets/landing/privacy-policy-mobile.svg"

type AgreementBannerProps = {
    type: "terms" | "privacy"
}

const AgreementBanner: FC<AgreementBannerProps> = ({ type }) => {
    const { isMobile } = useViewport()

    return (
        <div className="w-full bg-lightBlueBackground h-[400px] px-[64px] flex items-center justify-between gap-4 mobile:h-[315px] mobile:px-4 mobile:flex-col-reverse mobile:w-full mobile:py-16 mobile:justify-center mobile:gap-8">
            <h3 className="font-[Sora] text-[60px] leading-[60px] tracking-[-0.04em] text-text font-bold max-w-[450px] mobile:text-center mobile:text-[38px] mobile:leading-[48px]">
                {type === "terms" ? "Terms & Conditions" : "Privacy Policy"}
            </h3>
            {isMobile ? (
                <img
                    src={type === 'terms' ? TermsAndConditionsMobile : PrivacyPolicyMobile}
                    alt="" />

            ) : (
                <img
                    src={type === 'terms' ? TermsAndConditionsBackground : PrivacyPolicyBackground}
                    className="h-full"
                    alt=""
                />
            )}
        </div>
    )
}

export default AgreementBanner