import { useNavigate } from 'react-router-dom'

const EmptyState = () => {
    const navigate = useNavigate()

    const handleCreateGeneration = () => {
        navigate('/creation/0')
    }

    return (
        <div className="empty-state-dashboard-background-image w-full h-full flex flex-col max-w-[960px] items-center rounded-lg gap-6 justify-center">
            <h5 className="font-[Sora] text-[48px] leading-[60px] tracking-[-0.04em] text-center max-w-[480px] text-text font-bold mobile:text-[28px] mobile:leading-[35px] mobile:tracking-[-0.02em] mobile:max-w-[320px]">
                Create beautiful headshots in just minutes!
            </h5>
            <button
                onClick={handleCreateGeneration}
                className='flex items-center gap-2 bg-button rounded-xl text-white font-semibold text-[18px] leading-[22px] h-[64px] px-6 hover:bg-buttonHover transition-colors mobile:h-[56px]'
            >
                Create Your Headshots
            </button>
        </div>
    )
}

export default EmptyState