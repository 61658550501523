import { FC, useState } from "react"

import { useAppSelector } from "../redux/hooks";
import { GenerationItem } from "../utils/types";
import useViewport from "../hooks/useViewport";

import AccountDeletionAlbumItem from "../components/AccountDeletionAlbumItem";
import Modal from "./Modal"

import CreationCheckSquare from "../assets/creation/check-square.svg"
import CreationTrashBlack from "../assets/creation/trash-black.svg"

type GenerationDeleteUserModalProps = {
    onClose: () => void;
    deleteAction: (withDownload: boolean) => void;
    generation: GenerationItem;
}

const GenerationDeleteUserModal: FC<GenerationDeleteUserModalProps> = ({ onClose, deleteAction, generation }) => {
    const [downloadInvoices, setDownloadInvoices] = useState(true)
    const theme = useAppSelector((state) => state.app.theme)

    const { isMobile } = useViewport()

    return (
        <Modal
            customBodyClasses="max-w-[490px] bg-background flex flex-col gap-6 items-center p-10 mobile:px-4 mobile:py-6"
            onClose={onClose}
        >
            <div className="flex flex-col gap-3 items-center">
                <img
                    className={`w-[44px] ${theme === 'light' ? 'icon-to-placeholder-color-light' : 'icon-to-placeholder-color-dark'}`}
                    src={CreationTrashBlack}
                    alt=""
                />
                <p className="font-[Sora] text-[23px] leading-[28px] tracking-[-0.02em] font-bold text-center text-text mobile:text-[19px] mobile:leading-[23px]">
                    Are you sure you want to permanently delete your account?
                </p>
                <p className="text-base text-text text-center">
                    You currently have an album that generating photos. By deleting your account, you will no longer have access to any of your albums.
                </p>
            </div>
            <AccountDeletionAlbumItem album={generation} />
            <div className="flex items-center gap-2 w-full">
                <button onClick={onClose} className="w-1/2 bg-inputBackground hover:bg-inputBorder transition-colors h-[44px] rounded-lg text-base font-semibold text-text">
                    Keep Account
                </button>
                <button onClick={() => deleteAction(downloadInvoices)} className="w-1/2 bg-redButton hover:bg-redButtonHover transition-colors h-[44px] rounded-lg font-semibold text-base text-background flex items-center gap-2 justify-center">
                    <img className={`${theme === 'light' ? 'icon-to-white' : ''}`} src={CreationTrashBlack} alt="" />
                    {isMobile ? 'Delete' : 'Delete Account'}
                </button>
            </div>
            <div className="h-[1px] w-full bg-inputBorder"></div>
            <div className="flex items-center gap-4 w-full justify-between text-base text-text mobile:text-[12px] mobile:leading-[18px]">
                Download all invoices upon deleting my account
                {downloadInvoices ? (
                    <img
                        onClick={() => setDownloadInvoices(false)}
                        className="w-[24px] cursor-pointer select-none"
                        src={CreationCheckSquare}
                        alt=""
                    />
                ) : (
                    <div
                        className="cursor-pointer w-[24px] h-[24px] rounded-[4px] border-solid border-[1px] border-inputBorder"
                        onClick={() => setDownloadInvoices(true)}
                    ></div>
                )}
            </div>
        </Modal>
    )
}

export default GenerationDeleteUserModal