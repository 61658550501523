import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import TargetBox from "../DragAndDrop/TargetBox"

const UploadBlock = () => {
    return (
        <div className="h-[calc(100vh-158px)] flex flex-col items-start gap-6 p-12 overflow-y-auto custom-scroll mobile:p-0 mobile:h-[calc(100dvh-258px)]">
            <h5 className="font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold mobile:text-[23px] mobile:leading-[28px] mobile:hidden text-text">
                Upload Photos
            </h5>
            <DndProvider backend={HTML5Backend}>
                <TargetBox />
            </DndProvider>
        </div>
    )
}

export default UploadBlock